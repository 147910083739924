import { render, staticRenderFns } from "./get-farms-as-admin-sort-by-form-control.vue?vue&type=template&id=42fc1e1c&"
import script from "./get-farms-as-admin-sort-by-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./get-farms-as-admin-sort-by-form-control.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports