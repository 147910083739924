import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { CSV } from '@/types';
import { GetCustomBookingsForHorseAsManager, GetCreditNotesForHorseAsManager, GetConfigurationAsManager, CreateCustomBookingAsManager, CreateCustomBookingsAsManager, UpdateDefaultPaymentMethodForCustomBookingsAsManager, AssignCustomBookingServiceAsManager, AssignCustomBookingServiceToPersonsAsManager, CreateCreditNoteAsManager, CreateCreditNotesAsManager, UpdateDefaultPaymentMethodForCreditNotesAsManager, UpdateDefaultInvoiceCreationTypeOfCreditNotesAsManager, UpdateDefaultInvoiceCreationTypeOfCustomBookingsAsManager, CreateCustomBookingServiceAsManager, UpdateNameOfCustomBookingServiceAsManager, UpdatePriceOfCustomBookingServiceAsManager, UpdateIdentificationForAccountingOfCustomBookingServiceAsManager, ArchiveCustomBookingServiceAsManager, UpdateCustomBookingFilterConfigurationAsManager, GetCustomBookingsAsManager, GetCreditNotesAsManager, GetSettlementsForMonthAsManager, GetSettlementsForMonthAsCSVAsManager, GetCustomBookingServicesAsManager, GetPersonsAsManager, GetCustomBookingFilterConfigurationAsManager, SettlementGenerator, GetSettlementForMonth, UpdatePaymentReceiptForPersonAndMonthAsManager, GetPersonsWithPaymentReceiptsAsManager } from './types';

// -- Queries

export function getCustomBookingsForHorseAsManager(
  query: GetCustomBookingsForHorseAsManager.GetCustomBookingsForHorseAsManagerQuery
): Promise<GetCustomBookingsForHorseAsManager.Response> {
  const url = `${apiUrl}/api/settlement/get-custom-bookings-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCreditNotesForHorseAsManager(
  query: GetCreditNotesForHorseAsManager.GetCreditNotesForHorseAsManagerQuery
): Promise<GetCreditNotesForHorseAsManager.Response> {
  const url = `${apiUrl}/api/settlement/get-credit-notes-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getConfigurationAsManager(
  query: GetConfigurationAsManager.GetConfigurationAsManagerQuery
): Promise<GetConfigurationAsManager.Configuration> {
  const url = `${apiUrl}/api/settlement/get-configuration-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCustomBookingsAsManager(
  query: GetCustomBookingsAsManager.GetCustomBookingsAsManagerQuery
): Promise<GetCustomBookingsAsManager.CustomBooking[]> {
  const url = `${apiUrl}/api/settlement/get-custom-bookings-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCreditNotesAsManager(
  query: GetCreditNotesAsManager.GetCreditNotesAsManagerQuery
): Promise<GetCreditNotesAsManager.CreditNote[]> {
  const url = `${apiUrl}/api/settlement/get-credit-notes-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSettlementsForMonthAsManager(
  query: GetSettlementsForMonthAsManager.GetSettlementsForMonthAsManagerQuery
): Promise<GetSettlementsForMonthAsManager.SettlementsForMonth> {
  const url = `${apiUrl}/api/settlement/get-settlements-for-month-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSettlementsForMonthAsCSVAsManager(
  query: GetSettlementsForMonthAsCSVAsManager.GetSettlementsForMonthAsCSVAsManagerQuery
): Promise<CSV> {
  const url = `${apiUrl}/api/settlement/get-settlements-for-month-as-csv-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPersonsAsManager(query: GetPersonsAsManager.GetPersonsAsManagerQuery): Promise<GetPersonsAsManager.Person[]> {
  const url = `${apiUrl}/api/settlement/get-persons-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCustomBookingServicesAsManager(
  query: GetCustomBookingServicesAsManager.GetCustomBookingServicesAsManagerQuery
): Promise<GetCustomBookingServicesAsManager.CustomBookingService[]> {
  const url = `${apiUrl}/api/settlement/get-custom-booking-services-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCustomBookingFilterConfigurationAsManager(
  query: GetCustomBookingFilterConfigurationAsManager.GetCustomBookingFilterConfigurationAsManagerQuery
): Promise<GetCustomBookingFilterConfigurationAsManager.CustomBookingFilterConfiguration> {
  const url = `${apiUrl}/api/settlement/get-custom-booking-filter-configuration-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSettlementForMonth(query: GetSettlementForMonth.GetSettlementForMonthQuery): Promise<SettlementGenerator.Settlement> {
  const url = `${apiUrl}/api/settlement/get-settlement-for-month-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPersonsWithPaymentReceiptsAsManager(
  query: GetPersonsWithPaymentReceiptsAsManager.GetPersonsWithPaymentReceiptsAsManagerQuery
): Promise<GetPersonsWithPaymentReceiptsAsManager.PersonWithPaymentReceipts[]> {
  const url = `${apiUrl}/api/settlement/get-persons-with-payment-receipts-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createCustomBookingAsManager(command: CreateCustomBookingAsManager.CreateCustomBookingAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/settlement/create-custom-booking-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createCustomBookingsAsManager(command: CreateCustomBookingsAsManager.CreateCustomBookingsAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/settlement/create-custom-bookings-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateDefaultPaymentMethodForCustomBookingsAsManager(
  command: UpdateDefaultPaymentMethodForCustomBookingsAsManager.UpdateDefaultPaymentMethodForCustomBookingsAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-default-payment-method-for-custom-bookings-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateDefaultInvoiceCreationTypeOfCustomBookingsAsManager(
  command: UpdateDefaultInvoiceCreationTypeOfCustomBookingsAsManager.UpdateDefaultInvoiceCreationTypeOfCustomBookingsAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-default-invoice-creation-type-of-custom-bookings-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createCreditNoteAsManager(command: CreateCreditNoteAsManager.CreateCreditNoteAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/settlement/create-credit-note-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createCreditNotesAsManager(command: CreateCreditNotesAsManager.CreateCreditNotesAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/settlement/create-credit-notes-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateDefaultPaymentMethodForCreditNotesAsManager(
  command: UpdateDefaultPaymentMethodForCreditNotesAsManager.UpdateDefaultPaymentMethodForCreditNotesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-default-payment-method-for-credit-notes-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateDefaultInvoiceCreationTypeOfCreditNotesAsManager(
  command: UpdateDefaultInvoiceCreationTypeOfCreditNotesAsManager.UpdateDefaultInvoiceCreationTypeOfCreditNotesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-default-invoice-creation-type-of-credit-notes-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createCustomBookingServiceAsManager(
  command: CreateCustomBookingServiceAsManager.CreateCustomBookingServiceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/create-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateNameOfCustomBookingServiceAsManager(
  command: UpdateNameOfCustomBookingServiceAsManager.UpdateNameOfCustomBookingServiceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-name-of-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updatePriceOfCustomBookingServiceAsManager(
  command: UpdatePriceOfCustomBookingServiceAsManager.UpdatePriceOfCustomBookingServiceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-price-of-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateIdentificationForAccountingOfCustomBookingServiceAsManager(
  // eslint-disable-next-line max-len
  command: UpdateIdentificationForAccountingOfCustomBookingServiceAsManager.UpdateIdentificationForAccountingOfCustomBookingServiceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-identification-for-accounting-of-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function archiveCustomBookingServiceAsManager(
  command: ArchiveCustomBookingServiceAsManager.ArchiveCustomBookingServiceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/archive-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function assignCustomBookingServiceAsManager(
  command: AssignCustomBookingServiceAsManager.AssignCustomBookingServiceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/assign-custom-booking-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function assignCustomBookingServiceToPersonsAsManager(
  command: AssignCustomBookingServiceToPersonsAsManager.AssignCustomBookingServiceToPersonsAsManagerWorkflow
): Promise<void> {
  const url = `${apiUrl}/api/settlement/assign-custom-booking-service-to-persons-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateCustomBookingFilterConfigurationAsManager(
  command: UpdateCustomBookingFilterConfigurationAsManager.UpdateCustomBookingFilterConfigurationAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-custom-booking-filter-configuration-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updatePaymentReceiptForPersonAndMonthAsManager(
  command: UpdatePaymentReceiptForPersonAndMonthAsManager.UpdatePaymentReceiptForPersonAndMonthAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/settlement/update-payment-receipt-for-person-and-month-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
