import { UserProperty } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { GetGlobalUsersAsAdmin } from '@/feature/user-management';
import { GetUserForImpersonationAsAdminQuery, UpdateEnabledPermissionsOfUserAsAdminWorkflow, UpdateUserPasswordAsAdminCommand, UpdateUserRoleAsAdminWorkflow } from './types';

// -- Queries

export function getUserForImpersonationAsAdmin(query: GetUserForImpersonationAsAdminQuery & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/get-user-for-impersonation-as-admin-query`;
  return performApiRequest({ url, method: 'POST', data: query });
}

export function getFarms(query: UserProperty): Promise<GetGlobalUsersAsAdmin.Farm[]> {
  const url = `${apiUrl}/api/user-management/get-farms-as-admin-query`;
  return performApiRequest<GetGlobalUsersAsAdmin.Farm[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateUserPassword(command: UpdateUserPasswordAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-user-password-as-admin-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateUserRole(command: UpdateUserRoleAsAdminWorkflow & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-user-role-as-admin-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateEnabledPermissionsOfUser(command: UpdateEnabledPermissionsOfUserAsAdminWorkflow & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-enabled-permissions-of-user-as-admin-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
