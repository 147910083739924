import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetFarmAnalyticsAsManager } from './types';

// -- Queries

export function getFarmAnalyticsAsManager(
  query: GetFarmAnalyticsAsManager.GetFarmAnalyticsAsManagerQuery
): Promise<GetFarmAnalyticsAsManager.AnalyticsData> {
  const url = `${apiUrl}/api/analytics/get-farm-analytics-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
