import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FeedingTabs from './components/feeding-tabs.vue';
import Configuration from './components/configuration.vue';
import FeedingOverview from './components/feeding-overview.vue';

const defaultMeta = {
  title: 'Fütterung',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresFeature: Feature.FEEDING,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.FEEDING_READ,
};

export const feedingManagementRoutes: RouteConfig[] = [
  {
    path: 'fuetterung',
    component: FeedingTabs,
    children: [
      {
        path: '',
        redirect: { name: 'manage-feeding-feeding-plan' },
      },
      {
        name: 'manage-feeding-feeding-plan',
        path: 'fuetterungsplan',
        component: FeedingOverview,
        meta: defaultMeta,
      },
      {
        name: 'manage-feeding-configuration',
        path: 'konfiguration',
        component: Configuration,
        meta: defaultMeta,
      },
    ],
    meta: defaultMeta,
  },
];
