import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetFeedingPlan, GetFeedingPlanForHorseAsManager, CreateFeedTypeAsManager, DisableFeedTypeAsManager, UpdateNameOfFeedTypeAsManager, UpdateOfferOfFeedTypeAsManager, HideFeedTypeOnBoxSignAsManager, ShowFeedTypeOnBoxSignAsManager, GetFeedTypesAsManager, GetFeedingOverviewAsManager, GetLatestFeedingSettingUpdatesAsManager } from './types';

// -- Queries

export function getFeedingPlan(
  query: GetFeedingPlan.GetFeedingPlanQuery
): Promise<GetFeedingPlan.FeedingPlan> {
  const url = `${apiUrl}/api/feeding/get-feeding-plan-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFeedingPlanForHorseAsManager(
  query: GetFeedingPlanForHorseAsManager.GetFeedingPlanForHorseAsManagerQuery
): Promise<GetFeedingPlanForHorseAsManager.FeedingPlan> {
  const url = `${apiUrl}/api/feeding/get-feeding-plan-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFeedTypesAsManager(query: GetFeedTypesAsManager.GetFeedTypesAsManagerQuery): Promise<GetFeedTypesAsManager.FeedType[]> {
  const url = `${apiUrl}/api/feeding/get-feed-types-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFeedingOverviewAsManager(
  query: GetFeedingOverviewAsManager.GetFeedingOverviewAsManagerQuery
): Promise<GetFeedingOverviewAsManager.HorseWithFeeding[]> {
  const url = `${apiUrl}/api/feeding/get-feeding-overview-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getLatestFeedingSettingUpdatesAsManager(
  query: GetLatestFeedingSettingUpdatesAsManager.GetLatestFeedingSettingUpdatesAsManagerQuery
): Promise<GetLatestFeedingSettingUpdatesAsManager.HorseForLatestFeedingUpdate[]> {
  const url = `${apiUrl}/api/feeding/get-latest-feeding-setting-updates-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createFeedTypeAsManager(command: CreateFeedTypeAsManager.CreateFeedTypeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/feeding/create-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function disableFeedTypeAsManager(command: DisableFeedTypeAsManager.DisableFeedTypeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/feeding/disable-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateNameOfFeedTypeAsManager(command: UpdateNameOfFeedTypeAsManager.UpdateNameOfFeedTypeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/feeding/update-name-of-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateOfferOfFeedTypeAsManager(
  command: UpdateOfferOfFeedTypeAsManager.UpdateOfferOfFeedTypeAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feeding/update-offer-of-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function hideFeedTypeOnBoxSignAsManager(
  command: HideFeedTypeOnBoxSignAsManager.HideFeedTypeOnBoxSignAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feeding/hide-feed-type-on-box-sign-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function showFeedTypeOnBoxSignAsManager(
  command: ShowFeedTypeOnBoxSignAsManager.ShowFeedTypeOnBoxSignAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feeding/show-feed-type-on-box-sign-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
