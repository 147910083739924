import { defineStore, storeToRefs } from 'pinia';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { ActionStatus, FileResponse } from '@/application/types';
import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { HorseId, Month } from '@/types';
import { moment } from '@/helpers';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { CreateHorse, getAvailableHorses, GetAvailableHorses, GetBoxSign, getBoxSign, getCustomFields, GetCustomFields, getHorse, GetHorse, getHorseShareInvite, GetHorseShareInvite, getHorseShareInvitesAsInvitedUser, GetHorseShareInvitesAsInvitedUser, getHorseShareInvitesForHorse, GetHorseShareInvitesForHorse, getHorseSharesForHorse, GetHorseSharesForHorse, InviteUserAsHorseShare, inviteUserAsHorseShare, revokeHorseShare, RevokeHorseShareInvite, revokeHorseShareInvite, updateBirthInformationOfHorse, UpdateBirthInformationOfHorse, UpdateBreedOfHorse, updateBreedOfHorse, updateColorOfHorse, UpdateColorOfHorse, updateCustomFieldsOfHorse, UpdateCustomFieldsOfHorse, updateEquineNumberOfHorse, UpdateEquineNumberOfHorse, updateFarrierOfHorse, UpdateFarrierOfHorse, updateGenderOfHorse, UpdateGenderOfHorse, UpdateHorseImage, updateHorseImage, updateLineageOfHorse, UpdateLineageOfHorse, updateStockSizeOfHorse, UpdateStockSizeOfHorse, UpdateVeterinarianOfHorse, updateVeterinarianOfHorse, RevokeHorseShare, DeleteHorse, AcceptHorseShareInvite, RejectHorseShareInvite, UpdateHorseName, deleteHorse, acceptHorseShareInvite, rejectHorseShareInvite, createHorse, updateHorseName } from '@/feature/horse-management';
import { updateEmergencyContactOfUser, UpdateEmergencyContactOfUser } from '@/feature/profile';
import { getFeedingPlan, GetFeedingPlan } from '@/feature/feeding';
import { getSettlementForMonth, GetSettlementForMonth, SettlementGenerator } from '@/feature/settlement';
import { createPenExemption, CreatePenExemption, deletePenExemption, DeletePenExemption, getPenExemptionsForHorse, GetPenExemptionsForHorse } from '@/feature/pen';
import { CreateFarmServiceBookingCommand, CancelFarmServiceBookingCommand, CreateFeedingSettingsCommand, CreateVaccinationCommand, DefineInvoiceAddressCommand, DeleteVaccinationCommand, DisableInvoiceEmailDispatchCommand, EnableInvoiceEmailDispatchCommand, GetGerblhofFeedingForHorseQuery, GetInvoicePDFQuery, GetVaccinationCertificateForHorseQuery, Invoice, InvoiceConfiguration, RemoveConcentratedFeedingUpdateCommand, RemoveFeedingUpdateCommand, RemoveInvoiceAddressCommand, UpdateConcentratedFeedingCommand, UpdateFeedingCommand, UpdateFeedingSettingsCommand, UpdateInvoiceAddressCommand, UpdateVaccinationNoticeCommand, VaccinationCertificate, CreateHorseTrainingSessionCommand, TrainingDiaryEntry, GetTrainingDiaryQuery, CreateHorseTrainingSessionForFacilityReservationCommand, DeleteHorseTrainingSessionCommand, UpdateHorseTrainingSessionCommand, CreateHorseWeightEntryCommand, HorseWeight, GetWeightForHorseQuery, DeleteHorseWeightEntryCommand, HorseDrugs, GetDrugsForHorseQuery, CreateHorseDrugEntryCommand, DeleteHorseDrugEntryCommand, HorseFarrierAppointments, GetFarrierAppointmentsForHorseQuery, CreateHorseFarrierAppointmentCommand, DeleteHorseFarrierAppointmentCommand, CreateHorseWormingTreatmentCommand, DeleteHorseWormingTreatmentCommand, GetWormingTreatmentsForHorseQuery, HorseWormingTreatments, HorseVeterinarianAppointments, GetVeterinarianAppointmentsForHorseQuery, CreateHorseVeterinarianAppointmentCommand, DeleteHorseVeterinarianAppointmentCommand, UpdatePhoneNumberCommand, FarmService, GetFarmServicesForHorseQuery, GerblhofFeeding, GerblhofFeedingOrConcentratedFeedingUpdate } from './types';
import { createFarmServiceBooking, cancelFarmServiceBooking, createFeedingSettings, createHorseTrainingSession, createHorseTrainingSessionForFacilityReservation, createVaccination, defineInvoiceAddress, deleteHorseTrainingSession, deleteVaccination, disableInvoiceEmailDispatch, enableInvoiceEmailDispatch, getGerblhofFeedingForHorse, getInvoiceConfiguration, getInvoicePDF, getInvoices, getTrainingDiary, getVaccinationCertificateForHorse, removeConcentratedFeedingUpdate, removeFeedingUpdate, removeInvoiceAddress, updateConcentratedFeeding, updateFeeding, updateFeedingSettings, updateHorseTrainingSession, updateInvoiceAddress, updateVaccinationNotice, createHorseWeightEntry, getWeightForHorse, deleteHorseWeightEntry, getDrugsForHorse, createHorseDrugEntry, deleteHorseDrugEntry, getFarrierAppointmentsForHorse, createHorseFarrierAppointment, deleteHorseFarrierAppointment, createHorseWormingTreatment, deleteHorseWormingTreatment, getWormingTreatmentsForHorse, getVeterinarianAppointmentsForHorse, createHorseVeterinarianAppointment, deleteHorseVeterinarianAppointment, updatePhoneNumber, getFarmServicesForHorse } from './service';

interface MyStableState {
  selectedMonthForSettlement: Month | null;
  settlementForMonth: APIResource<SettlementGenerator.Settlement>;

  gerblhofFeeding: GerblhofFeeding | null;

  currentHorseId: HorseId | null;
  currentHorse: APIResource<GetHorse.Horse>;
  availableHorses: APIResource<GetAvailableHorses.AvailableHorses>;

  horseShares: APIResource<GetHorseSharesForHorse.HorseShare[]>;
  horseShareInvites: APIResource<GetHorseShareInvitesForHorse.HorseShareInvite[]>;

  vaccinationCertificate: VaccinationCertificate | null;

  invoices: Invoice[];
  invoiceConfiguration: InvoiceConfiguration | null;
  horseShareInvite: APIResource<GetHorseShareInvite.HorseShareInviteAsInvitedUser>;
  horseShareInvitesAsInvitedUser: APIResource<GetHorseShareInvitesAsInvitedUser.HorseShareInviteAsInvitedUser[]>;

  feedingPlan: APIResource<GetFeedingPlan.FeedingPlan>;
  isDiffToPreviousShown: boolean;

  trainingDiary: TrainingDiaryEntry[] | null;
  numberOfWeeksForTrainingDiary: number;

  penExemptions: APIResource<GetPenExemptionsForHorse.PenExemptions>;

  horseWeight: HorseWeight | null;
  horseDrugs: HorseDrugs | null;
  horseFarrierAppointments: HorseFarrierAppointments | null;
  horseVeterinarianAppointments: HorseVeterinarianAppointments | null;
  horseWormingTreatments: HorseWormingTreatments | null;

  farmServicesForHorse: FarmService[] | null;
  customFields: APIResource<GetCustomFields.CustomField[]>;

  getGerblhofFeedingForHorseStatus: ActionStatus;
  getInvoiceConfigurationStatus: ActionStatus;
  getInvoicesStatus: ActionStatus;
  getInvoicePDFStatus: ActionStatus;
  getVaccinationCertificateForHorseStatus: ActionStatus;
  deleteHorseStatus: ActionStatus;
  inviteUserAsHorseShareStatus: ActionStatus;
  revokeHorseShareInviteStatus: ActionStatus;
  revokeHorseShareStatus: ActionStatus;
  createFarmServiceBookingStatus: ActionStatus;
  cancelFarmServiceBookingStatus: ActionStatus;
  createPenExemptionStatus: ActionStatus;
  deletePenExemptionStatus: ActionStatus;
  updateFeedingStatus: ActionStatus;
  updateConcentratedFeedingStatus: ActionStatus;
  removeFeedingUpdateStatus: ActionStatus;
  removeConcentratedFeedingUpdateStatus: ActionStatus;
  createVaccinationStatus: ActionStatus;
  defineInvoiceAddressStatus: ActionStatus;
  updateInvoiceAddressStatus: ActionStatus;
  removeInvoiceAddressStatus: ActionStatus;
  enableInvoiceEmailDispatchStatus: ActionStatus;
  disableInvoiceEmailDispatchStatus: ActionStatus;
  acceptHorseShareInviteStatus: ActionStatus;
  rejectHorseShareInviteStatus: ActionStatus;
  createHorseStatus: ActionStatus;
  updateHorseNameStatus: ActionStatus;
  updateVaccinationNoticeStatus: ActionStatus;
  deleteVaccinationStatus: ActionStatus;
  createFeedingSettingsStatus: ActionStatus;
  updateFeedingSettingsStatus: ActionStatus;
  createHorseTrainingSessionStatus: ActionStatus;
  createHorseTrainingSessionForFacilityReservationStatus: ActionStatus;
  getTrainingDiaryStatus: ActionStatus;
  updateHorseTrainingSessionStatus: ActionStatus;
  deleteHorseTrainingSessionStatus: ActionStatus;
  getBoxSignStatus: ActionStatus;
  updateHorseImageStatus: ActionStatus;
  createHorseWeightEntryStatus: ActionStatus;
  getWeightForHorseStatus: ActionStatus;
  deleteHorseWeightEntryStatus: ActionStatus;
  getDrugsForHorseStatus: ActionStatus;
  createHorseDrugEntryStatus: ActionStatus;
  deleteHorseDrugEntryStatus: ActionStatus;
  getFarrierAppointmentsForHorseStatus: ActionStatus;
  createHorseFarrierAppointmentStatus: ActionStatus;
  deleteHorseFarrierAppointmentStatus: ActionStatus;
  getWormingTreatmentsForHorseStatus: ActionStatus;
  createHorseWormingTreatmentStatus: ActionStatus;
  deleteHorseWormingTreatmentStatus: ActionStatus;
  getVeterinarianAppointmentsForHorseStatus: ActionStatus;
  createHorseVeterinarianAppointmentStatus: ActionStatus;
  deleteHorseVeterinarianAppointmentStatus: ActionStatus;
  updatePhoneNumberStatus: ActionStatus;
  getFarmServicesForHorseStatus: ActionStatus;
  updateVeterinarianOfHorseStatus: ActionStatus;
  updateFarrierOfHorseStatus: ActionStatus;
  updateEmergencyContactOfUserStatus: ActionStatus;
  updateEquineNumberOfHorseStatus: ActionStatus;
  updateBreedOfHorseStatus: ActionStatus;
  updateColorOfHorseStatus: ActionStatus;
  updateGenderOfHorseStatus: ActionStatus;
  updateStockSizeOfHorseStatus: ActionStatus;
  updateBirthInformationOfHorseStatus: ActionStatus;
  updateLineageOfHorseStatus: ActionStatus;
  updateCustomFieldsOfHorseStatus: ActionStatus;
}

function initialState(): MyStableState {
  return {
    selectedMonthForSettlement: null,
    settlementForMonth: initialAPIResource(),

    gerblhofFeeding: null,

    currentHorseId: null,
    currentHorse: initialAPIResource(),
    availableHorses: initialAPIResource(),

    horseShares: initialAPIResource(),
    horseShareInvites: initialAPIResource(),

    vaccinationCertificate: null,

    invoices: [],
    invoiceConfiguration: null,
    horseShareInvite: initialAPIResource(),
    horseShareInvitesAsInvitedUser: initialAPIResource(),

    feedingPlan: initialAPIResource(),
    isDiffToPreviousShown: false,

    trainingDiary: null,
    numberOfWeeksForTrainingDiary: 1,

    penExemptions: initialAPIResource(),

    horseWeight: null,
    horseDrugs: null,
    horseFarrierAppointments: null,
    horseVeterinarianAppointments: null,
    horseWormingTreatments: null,

    farmServicesForHorse: null,
    customFields: initialAPIResource(),

    getGerblhofFeedingForHorseStatus: ActionStatus.None,
    getInvoiceConfigurationStatus: ActionStatus.None,
    getInvoicesStatus: ActionStatus.None,
    getInvoicePDFStatus: ActionStatus.None,
    getVaccinationCertificateForHorseStatus: ActionStatus.None,
    deleteHorseStatus: ActionStatus.None,
    inviteUserAsHorseShareStatus: ActionStatus.None,
    revokeHorseShareInviteStatus: ActionStatus.None,
    revokeHorseShareStatus: ActionStatus.None,
    createFarmServiceBookingStatus: ActionStatus.None,
    cancelFarmServiceBookingStatus: ActionStatus.None,
    createPenExemptionStatus: ActionStatus.None,
    deletePenExemptionStatus: ActionStatus.None,
    updateFeedingStatus: ActionStatus.None,
    updateConcentratedFeedingStatus: ActionStatus.None,
    removeFeedingUpdateStatus: ActionStatus.None,
    removeConcentratedFeedingUpdateStatus: ActionStatus.None,
    createVaccinationStatus: ActionStatus.None,
    defineInvoiceAddressStatus: ActionStatus.None,
    updateInvoiceAddressStatus: ActionStatus.None,
    removeInvoiceAddressStatus: ActionStatus.None,
    enableInvoiceEmailDispatchStatus: ActionStatus.None,
    disableInvoiceEmailDispatchStatus: ActionStatus.None,
    acceptHorseShareInviteStatus: ActionStatus.None,
    rejectHorseShareInviteStatus: ActionStatus.None,
    createHorseStatus: ActionStatus.None,
    updateHorseNameStatus: ActionStatus.None,
    updateVaccinationNoticeStatus: ActionStatus.None,
    deleteVaccinationStatus: ActionStatus.None,
    createFeedingSettingsStatus: ActionStatus.None,
    updateFeedingSettingsStatus: ActionStatus.None,
    createHorseTrainingSessionStatus: ActionStatus.None,
    createHorseTrainingSessionForFacilityReservationStatus: ActionStatus.None,
    getTrainingDiaryStatus: ActionStatus.None,
    updateHorseTrainingSessionStatus: ActionStatus.None,
    deleteHorseTrainingSessionStatus: ActionStatus.None,
    getBoxSignStatus: ActionStatus.None,
    updateHorseImageStatus: ActionStatus.None,
    createHorseWeightEntryStatus: ActionStatus.None,
    getWeightForHorseStatus: ActionStatus.None,
    deleteHorseWeightEntryStatus: ActionStatus.None,
    getDrugsForHorseStatus: ActionStatus.None,
    createHorseDrugEntryStatus: ActionStatus.None,
    deleteHorseDrugEntryStatus: ActionStatus.None,
    getFarrierAppointmentsForHorseStatus: ActionStatus.None,
    createHorseFarrierAppointmentStatus: ActionStatus.None,
    deleteHorseFarrierAppointmentStatus: ActionStatus.None,
    getWormingTreatmentsForHorseStatus: ActionStatus.None,
    createHorseWormingTreatmentStatus: ActionStatus.None,
    deleteHorseWormingTreatmentStatus: ActionStatus.None,
    getVeterinarianAppointmentsForHorseStatus: ActionStatus.None,
    createHorseVeterinarianAppointmentStatus: ActionStatus.None,
    deleteHorseVeterinarianAppointmentStatus: ActionStatus.None,
    updatePhoneNumberStatus: ActionStatus.None,
    getFarmServicesForHorseStatus: ActionStatus.None,
    updateVeterinarianOfHorseStatus: ActionStatus.None,
    updateFarrierOfHorseStatus: ActionStatus.None,
    updateEmergencyContactOfUserStatus: ActionStatus.None,
    updateEquineNumberOfHorseStatus: ActionStatus.None,
    updateBreedOfHorseStatus: ActionStatus.None,
    updateColorOfHorseStatus: ActionStatus.None,
    updateGenderOfHorseStatus: ActionStatus.None,
    updateStockSizeOfHorseStatus: ActionStatus.None,
    updateBirthInformationOfHorseStatus: ActionStatus.None,
    updateLineageOfHorseStatus: ActionStatus.None,
    updateCustomFieldsOfHorseStatus: ActionStatus.None,
  };
}

export const useMyStableStore = defineStore('myStable', {
  state: (): MyStableState => initialState(),
  getters: {
    isHorseOwner: (state: MyStableState): boolean =>
      (state.availableHorses.value?.own ?? []).length > 0,

    ownHorses: (state: MyStableState): GetAvailableHorses.AvailableHorse[] =>
      state.availableHorses.value?.own ?? [],

    sharedHorses: (state: MyStableState): GetAvailableHorses.AvailableHorse[] =>
      state.availableHorses.value?.shared ?? [],

    ownAndSharedHorses: (state: MyStableState): GetAvailableHorses.AvailableHorse[] =>
      [
        ...state.availableHorses.value?.own ?? [],
        ...state.availableHorses.value?.shared ?? [],
      ],

    currentAvailableHorse: (state: MyStableState): GetAvailableHorses.AvailableHorse | null => [
      ...state.availableHorses.value?.own ?? [],
      ...state.availableHorses.value?.shared ?? [],
    ].find((horse) => horse.horseId === state.currentHorseId) ?? null,

    hasCurrentHorseSetThatIsNotAvailableAnymore: (state: MyStableState): boolean => {
      if (state.currentHorseId === null) {
        return false;
      }

      const horses = [
        ...state.availableHorses.value?.own ?? [],
        ...state.availableHorses.value?.shared ?? [],
      ];

      return !horses.find((horse) => horse.horseId === state.currentHorseId);
    },

    isCurrentHorseOwn: (state: MyStableState): boolean => {
      if (state.currentHorseId === null
        || state.availableHorses.value === null
      ) {
        return false;
      }

      return !!state.availableHorses.value.own
        .find((horse) => horse.horseId === state.currentHorseId);
    },

    availableHorsesAsIdentifications: (state: MyStableState): GetAvailableHorses.AvailableHorse[] => [
      ...state.availableHorses.value?.own ?? [],
      ...state.availableHorses.value?.shared ?? [],
    ],

    gerblhofFeedingAndConcentratedFeedingUpdates: (state: MyStableState): GerblhofFeedingOrConcentratedFeedingUpdate[] => {
      if (state.gerblhofFeeding === null) {
        return [];
      }

      const feedingUpdates = state.gerblhofFeeding.feedingUpdates
        .map((feedingUpdate) => ({
          type: 'feeding',
          details: feedingUpdate,
        }));

      const concentratedFeedingUpdates = state.gerblhofFeeding.concentratedFeedingUpdates
        .map((concentratedFeeding) => ({
          type: 'concentrated-feeding',
          details: concentratedFeeding,
        }));

      return [...feedingUpdates, ...concentratedFeedingUpdates]
        .sort((updateA, updateB) => {
          const diff = moment(updateA.details.startingAt.date).unix() - moment(updateB.details.startingAt.date).unix();

          if (diff === 0) {
            return updateA.type === 'feeding' ? -1 : 1;
          }

          return diff;
        });
    },

    isDeleteHorseProcessing: (state: MyStableState): boolean =>
      state.deleteHorseStatus === ActionStatus.InProgress,
    isInviteUserAsHorseShareProcessing: (state: MyStableState): boolean =>
      state.inviteUserAsHorseShareStatus === ActionStatus.InProgress,
    isRevokeHorseShareInviteProcessing: (state: MyStableState): boolean =>
      state.revokeHorseShareInviteStatus === ActionStatus.InProgress,
    isRevokeHorseShareProcessing: (state: MyStableState): boolean =>
      state.revokeHorseShareStatus === ActionStatus.InProgress,
    isCreateFarmServiceBookingProcessing: (state: MyStableState): boolean =>
      state.createFarmServiceBookingStatus === ActionStatus.InProgress,
    isCancelFarmServiceBookingProcessing: (state: MyStableState): boolean =>
      state.cancelFarmServiceBookingStatus === ActionStatus.InProgress,
    isCreatePenExemptionProcessing: (state: MyStableState): boolean =>
      state.createPenExemptionStatus === ActionStatus.InProgress,
    isDeletePenExemptionProcessing: (state: MyStableState): boolean =>
      state.deletePenExemptionStatus === ActionStatus.InProgress,
    isUpdateFeedingProcessing: (state: MyStableState): boolean =>
      state.updateFeedingStatus === ActionStatus.InProgress,
    isUpdateConcentratedFeedingProcessing: (state: MyStableState): boolean =>
      state.updateConcentratedFeedingStatus === ActionStatus.InProgress,
    isRemoveFeedingUpdateProcessing: (state: MyStableState): boolean =>
      state.removeFeedingUpdateStatus === ActionStatus.InProgress,
    isRemoveConcentratedFeedingUpdateProcessing: (state: MyStableState): boolean =>
      state.removeConcentratedFeedingUpdateStatus === ActionStatus.InProgress,
    isGetGerblhofFeedingForHorseProcessing: (state: MyStableState): boolean =>
      state.getGerblhofFeedingForHorseStatus === ActionStatus.InProgress,
    isCreateVaccinationProcessing: (state: MyStableState): boolean =>
      state.createVaccinationStatus === ActionStatus.InProgress,
    isGetInvoiceAddressConfigurationProcessing: (state: MyStableState): boolean =>
      state.getInvoiceConfigurationStatus === ActionStatus.InProgress,
    isDefineInvoiceAddressProcessing: (state: MyStableState): boolean =>
      state.defineInvoiceAddressStatus === ActionStatus.InProgress,
    isUpdateInvoiceAddressProcessing: (state: MyStableState): boolean =>
      state.updateInvoiceAddressStatus === ActionStatus.InProgress,
    isRemoveInvoiceAddressProcessing: (state: MyStableState): boolean =>
      state.removeInvoiceAddressStatus === ActionStatus.InProgress,
    isEnableInvoiceEmailDispatchProcessing: (state: MyStableState): boolean =>
      state.enableInvoiceEmailDispatchStatus === ActionStatus.InProgress,
    isDisableInvoiceEmailDispatchProcessing: (state: MyStableState): boolean =>
      state.disableInvoiceEmailDispatchStatus === ActionStatus.InProgress,
    isAcceptHorseShareInviteProcessing: (state: MyStableState): boolean =>
      state.acceptHorseShareInviteStatus === ActionStatus.InProgress,
    isRejectHorseShareInviteProcessing: (state: MyStableState): boolean =>
      state.rejectHorseShareInviteStatus === ActionStatus.InProgress,
    isGetInvoicesProcessing: (state: MyStableState): boolean =>
      state.getInvoicesStatus === ActionStatus.InProgress,
    isGetInvoicePDFProcessing: (state: MyStableState): boolean =>
      state.getInvoicePDFStatus === ActionStatus.InProgress,
    isCreateHorseProcessing: (state: MyStableState): boolean =>
      state.createHorseStatus === ActionStatus.InProgress,
    isGetVaccinationCertificateForHorseProcessing: (state: MyStableState): boolean =>
      state.getVaccinationCertificateForHorseStatus === ActionStatus.InProgress,
    isUpdateVaccinationNoticeProcessing: (state: MyStableState): boolean =>
      state.updateVaccinationNoticeStatus === ActionStatus.InProgress,
    isDeleteVaccinationProcessing: (state: MyStableState): boolean =>
      state.deleteVaccinationStatus === ActionStatus.InProgress,
    isCreateFeedingSettingsProcessing: (state: MyStableState): boolean =>
      state.createFeedingSettingsStatus === ActionStatus.InProgress,
    isUpdateFeedingSettingsProcessing: (state: MyStableState): boolean =>
      state.updateFeedingSettingsStatus === ActionStatus.InProgress,
    isCreateHorseTrainingSessionProcessing: (state: MyStableState): boolean =>
      state.createHorseTrainingSessionStatus === ActionStatus.InProgress,
    isCreateHorseTrainingSessionForFacilityReservationProcessing: (state: MyStableState): boolean =>
      state.createHorseTrainingSessionForFacilityReservationStatus === ActionStatus.InProgress,
    isGetTrainingDiaryProcessing: (state: MyStableState): boolean =>
      state.getTrainingDiaryStatus === ActionStatus.InProgress,
    isUpdateHorseTrainingSessionProcessing: (state: MyStableState): boolean =>
      state.updateHorseTrainingSessionStatus === ActionStatus.InProgress,
    isDeleteHorseTrainingSessionProcessing: (state: MyStableState): boolean =>
      state.deleteHorseTrainingSessionStatus === ActionStatus.InProgress,
    isGetBoxSignProcessing: (state: MyStableState): boolean =>
      state.getBoxSignStatus === ActionStatus.InProgress,
    isCreateHorseWeightEntryProcessing: (state: MyStableState): boolean =>
      state.createHorseWeightEntryStatus === ActionStatus.InProgress,
    isGetWeightForHorseProcessing: (state: MyStableState): boolean =>
      state.getWeightForHorseStatus === ActionStatus.InProgress,
    isDeleteHorseWeightEntryProcessing: (state: MyStableState): boolean =>
      state.deleteHorseWeightEntryStatus === ActionStatus.InProgress,
    isGetDrugsForHorseProcessing: (state: MyStableState): boolean =>
      state.getDrugsForHorseStatus === ActionStatus.InProgress,
    isCreateHorseDrugEntryProcessing: (state: MyStableState): boolean =>
      state.createHorseDrugEntryStatus === ActionStatus.InProgress,
    isDeleteHorseDrugEntryProcessing: (state: MyStableState): boolean =>
      state.deleteHorseDrugEntryStatus === ActionStatus.InProgress,
    isGetFarrierAppointmentsForHorseProcessing: (state: MyStableState): boolean =>
      state.getFarrierAppointmentsForHorseStatus === ActionStatus.InProgress,
    isCreateHorseFarrierAppointmentProcessing: (state: MyStableState): boolean =>
      state.createHorseFarrierAppointmentStatus === ActionStatus.InProgress,
    isDeleteHorseFarrierAppointmentProcessing: (state: MyStableState): boolean =>
      state.deleteHorseFarrierAppointmentStatus === ActionStatus.InProgress,
    isGetWormingTreatmentsForHorseProcessing: (state: MyStableState): boolean =>
      state.getWormingTreatmentsForHorseStatus === ActionStatus.InProgress,
    isCreateHorseWormingTreatmentProcessing: (state: MyStableState): boolean =>
      state.createHorseWormingTreatmentStatus === ActionStatus.InProgress,
    isDeleteHorseWormingTreatmentProcessing: (state: MyStableState): boolean =>
      state.deleteHorseWormingTreatmentStatus === ActionStatus.InProgress,
    isGetVeterinarianAppointmentsForHorseProcessing: (state: MyStableState): boolean =>
      state.getVeterinarianAppointmentsForHorseStatus === ActionStatus.InProgress,
    isCreateHorseVeterinarianAppointmentProcessing: (state: MyStableState): boolean =>
      state.createHorseVeterinarianAppointmentStatus === ActionStatus.InProgress,
    isDeleteHorseVeterinarianAppointmentProcessing: (state: MyStableState): boolean =>
      state.deleteHorseVeterinarianAppointmentStatus === ActionStatus.InProgress,
    isUpdatePhoneNumberProcessing: (state: MyStableState): boolean =>
      state.updatePhoneNumberStatus === ActionStatus.InProgress,
    isGetFarmServicesForHorseProcessing: (state: MyStableState): boolean =>
      state.getFarmServicesForHorseStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentHorse(horseId: HorseId): Promise<void> {
      this.currentHorseId = horseId;

      return this.getHorse();
    },

    resetCurrentHorse(): Promise<void> {
      this.currentHorseId = null;
      this.currentHorse = initialAPIResource();

      return Promise.resolve();
    },

    resetFeeding(): void {
      this.feedingPlan = initialAPIResource();
      this.isDiffToPreviousShown = false;

      this.createFeedingSettingsStatus = ActionStatus.None;
      this.updateFeedingSettingsStatus = ActionStatus.None;
    },

    increaseNumberOfWeeksForTrainingDiary(): Promise<void> {
      this.numberOfWeeksForTrainingDiary++;

      return this.getTrainingDiary();
    },

    resetTrainingDiary(): void {
      this.trainingDiary = null;
      this.numberOfWeeksForTrainingDiary = 1;
    },

    toggleDiffToPrevious(): void {
      this.isDiffToPreviousShown = !this.isDiffToPreviousShown;
    },

    updateSelectedMonthForSettlement(month: Month): Promise<void> {
      this.selectedMonthForSettlement = month;

      return this.getSettlementForMonth();
    },

    resetSettlementForMonth(): void {
      this.settlementForMonth.value = null;
    },

    // -- Queries

    getAvailableHorses(): Promise<void> {
      const { availableHorses } = storeToRefs(this);
      return wrapAction(
        availableHorses,
        () => getAvailableHorses({})
      );
    },

    getHorse(): Promise<void> {
      const query: GetHorse.GetHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { currentHorse } = storeToRefs(this);
      return wrapAction(
        currentHorse,
        () => getHorse(query)
      );
    },

    getHorseSharesForHorse(): Promise<void> {
      const query: GetHorseSharesForHorse.GetHorseSharesForHorseQuery = { horseId: this.currentHorseId! };
      const { horseShares } = storeToRefs(this);
      return wrapAction(
        horseShares,
        () => getHorseSharesForHorse(attachFarmAndUserProperties(query))
      );
    },

    getHorseShareInvitesForHorse(): Promise<void> {
      const query: GetHorseShareInvitesForHorse.GetHorseShareInvitesForHorseQuery = { horseId: this.currentHorseId! };
      const { horseShareInvites } = storeToRefs(this);
      return wrapAction(
        horseShareInvites,
        () => getHorseShareInvitesForHorse(attachFarmAndUserProperties(query))
      );
    },

    getVaccinationCertificateForHorse(): Promise<void> {
      const query: GetVaccinationCertificateForHorseQuery = { horseId: this.currentHorseId! };
      const { getVaccinationCertificateForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getVaccinationCertificateForHorseStatus,
        () => getVaccinationCertificateForHorse(attachFarmAndUserProperties(query))
          .then((vaccinationCertificate) => {
            this.vaccinationCertificate = vaccinationCertificate;
          })
      );
    },

    getFeedingPlan(): Promise<void> {
      const query: GetFeedingPlan.GetFeedingPlanQuery = {
        horseId: this.currentHorseId!,
      };
      const { feedingPlan } = storeToRefs(this);
      return wrapAction(
        feedingPlan,
        () => getFeedingPlan(attachFarmAndUserProperties(query))
      );
    },

    getGerblhofFeedingForHorse(): Promise<void> {
      const query: GetGerblhofFeedingForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getGerblhofFeedingForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGerblhofFeedingForHorseStatus,
        () => getGerblhofFeedingForHorse(attachFarmAndUserProperties(query))
          .then((gerblhofFeeding) => {
            this.gerblhofFeeding = gerblhofFeeding;
          })
      );
    },

    getSettlementForMonth(): Promise<void> {
      const query: GetSettlementForMonth.GetSettlementForMonthQuery = {
        month: this.selectedMonthForSettlement!,
      };
      const { settlementForMonth } = storeToRefs(this);
      return wrapAction(
        settlementForMonth,
        () => getSettlementForMonth(query)
      );
    },

    getInvoiceConfiguration(): Promise<void> {
      const { getInvoiceConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoiceConfigurationStatus,
        () => getInvoiceConfiguration(attachFarmAndUserProperties({}))
          .then((invoiceConfiguration) => {
            this.invoiceConfiguration = invoiceConfiguration;
          })
      );
    },

    getHorseShareInvite(query: GetHorseShareInvite.GetHorseShareInviteQuery): Promise<void> {
      const { horseShareInvite } = storeToRefs(this);
      return wrapAction(
        horseShareInvite,
        () => getHorseShareInvite(attachFarmAndUserProperties(query))
      );
    },

    getInvoices(): Promise<void> {
      const { getInvoicesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicesStatus,
        () => getInvoices(attachFarmAndUserProperties({}))
          .then((invoices) => {
            this.invoices = invoices;
          })
      );
    },

    getInvoicePDF(query: GetInvoicePDFQuery): Promise<FileResponse> {
      const { getInvoicePDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicePDFStatus,
        () => getInvoicePDF(attachFarmAndUserProperties(query))
      );
    },

    getHorseShareInvitesAsInvitedUser(): Promise<void> {
      const { horseShareInvitesAsInvitedUser } = storeToRefs(this);
      return wrapAction(
        horseShareInvitesAsInvitedUser,
        () => getHorseShareInvitesAsInvitedUser(attachFarmAndUserProperties({}))
      );
    },

    getTrainingDiary(): Promise<void> {
      const query: GetTrainingDiaryQuery = {
        horseId: this.currentHorseId!,
        numberOfWeeks: this.numberOfWeeksForTrainingDiary,
      };
      const { getTrainingDiaryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTrainingDiaryStatus,
        () => getTrainingDiary(attachFarmAndUserProperties(query))
          .then((trainingDiary) => {
            this.trainingDiary = trainingDiary;
          })
      );
    },

    getBoxSign(): Promise<FileResponse> {
      const query: GetBoxSign.GetBoxSignQuery = {
        horseId: this.currentHorseId!,
      };
      const { getBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignStatus,
        () => getBoxSign(attachFarmAndUserProperties(query))
      );
    },

    getPenExemptionsForHorse(): Promise<void> {
      const query: GetPenExemptionsForHorse.GetPenExemptionsForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { penExemptions } = storeToRefs(this);
      return wrapAction(
        penExemptions,
        () => getPenExemptionsForHorse(query)
      );
    },

    getWeightForHorse(): Promise<void> {
      const query: GetWeightForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getWeightForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getWeightForHorseStatus,
        () => getWeightForHorse(attachFarmAndUserProperties(query))
          .then((horseWeight) => {
            this.horseWeight = horseWeight;
          })
      );
    },

    getDrugsForHorse(): Promise<void> {
      const query: GetDrugsForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getDrugsForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDrugsForHorseStatus,
        () => getDrugsForHorse(attachFarmAndUserProperties(query))
          .then((horseDrugs) => {
            this.horseDrugs = horseDrugs;
          })
      );
    },

    getFarrierAppointmentsForHorse(): Promise<void> {
      const query: GetFarrierAppointmentsForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getFarrierAppointmentsForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarrierAppointmentsForHorseStatus,
        () => getFarrierAppointmentsForHorse(attachFarmAndUserProperties(query))
          .then((horseFarrierAppointments) => {
            this.horseFarrierAppointments = horseFarrierAppointments;
          })
      );
    },

    getVeterinarianAppointmentsForHorse(): Promise<void> {
      const query: GetVeterinarianAppointmentsForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getVeterinarianAppointmentsForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getVeterinarianAppointmentsForHorseStatus,
        () => getVeterinarianAppointmentsForHorse(attachFarmAndUserProperties(query))
          .then((horseVeterinarianAppointments) => {
            this.horseVeterinarianAppointments = horseVeterinarianAppointments;
          })
      );
    },

    getWormingTreatmentsForHorse(): Promise<void> {
      const query: GetWormingTreatmentsForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getWormingTreatmentsForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getWormingTreatmentsForHorseStatus,
        () => getWormingTreatmentsForHorse(attachFarmAndUserProperties(query))
          .then((horseWormingTreatments) => {
            this.horseWormingTreatments = horseWormingTreatments;
          })
      );
    },

    getFarmServicesForHorse(): Promise<void> {
      const query: GetFarmServicesForHorseQuery = {
        horseId: this.currentHorseId!,
      };
      const { getFarmServicesForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServicesForHorseStatus,
        () => getFarmServicesForHorse(attachFarmAndUserProperties(query))
          .then((farmServicesForHorse) => {
            this.farmServicesForHorse = farmServicesForHorse;
          })
      );
    },

    getCustomFields(): Promise<void> {
      const query: GetCustomFields.GetCustomFieldsQuery = {};
      const { customFields } = storeToRefs(this);
      return wrapAction(
        customFields,
        () => getCustomFields(query)
      );
    },

    // -- Commands

    inviteUserAsHorseShare(command: InviteUserAsHorseShare.InviteUserAsHorseShareCommand): Promise<void> {
      const { inviteUserAsHorseShareStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUserAsHorseShareStatus,
        () => inviteUserAsHorseShare(attachFarmAndUserProperties(command))
          .then(() => this.getHorseShareInvitesForHorse())
      );
    },

    revokeHorseShareInvite(command: RevokeHorseShareInvite.RevokeHorseShareInviteCommand): Promise<void> {
      const { revokeHorseShareInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        revokeHorseShareInviteStatus,
        () => revokeHorseShareInvite(attachFarmAndUserProperties(command))
          .then(() => this.getHorseShareInvitesForHorse())
      );
    },

    revokeHorseShare(command: RevokeHorseShare.RevokeHorseShareCommand): Promise<void> {
      const { revokeHorseShareStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        revokeHorseShareStatus,
        () => revokeHorseShare(attachFarmAndUserProperties(command))
          .then(() => this.getHorseSharesForHorse())
      );
    },

    deleteHorse(command: DeleteHorse.DeleteHorseWorkflow): Promise<void> {
      const { deleteHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseStatus,
        () => deleteHorse(attachFarmAndUserProperties(command))
          .then(() => this.getAvailableHorses())
          .then(() => {
            this.currentHorseId = null;
          })
      );
    },

    createFarmServiceBooking(command: CreateFarmServiceBookingCommand): Promise<void> {
      const { createFarmServiceBookingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFarmServiceBookingStatus,
        () => createFarmServiceBooking(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServicesForHorse())
      );
    },

    cancelFarmServiceBooking(command: CancelFarmServiceBookingCommand): Promise<void> {
      const { cancelFarmServiceBookingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelFarmServiceBookingStatus,
        () => cancelFarmServiceBooking(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServicesForHorse())
      );
    },

    createPenExemption(command: CreatePenExemption.CreatePenExemptionCommand): Promise<void> {
      const { createPenExemptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPenExemptionStatus,
        () => createPenExemption(command)
          .then(() => this.getPenExemptionsForHorse())
      );
    },

    deletePenExemption(command: DeletePenExemption.DeletePenExemptionCommand): Promise<void> {
      const { deletePenExemptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deletePenExemptionStatus,
        () => deletePenExemption(command)
          .then(() => this.getPenExemptionsForHorse())
      );
    },

    updateFeeding(command: UpdateFeedingCommand): Promise<void> {
      const { updateFeedingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFeedingStatus,
        () => updateFeeding(attachFarmAndUserProperties(command))
          .then(() => this.getGerblhofFeedingForHorse())
      );
    },

    removeFeedingUpdate(command: RemoveFeedingUpdateCommand): Promise<void> {
      const { removeFeedingUpdateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeFeedingUpdateStatus,
        () => removeFeedingUpdate(attachFarmAndUserProperties(command))
          .then(() => this.getGerblhofFeedingForHorse())
      );
    },

    updateConcentratedFeeding(command: UpdateConcentratedFeedingCommand): Promise<void> {
      const { updateConcentratedFeedingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateConcentratedFeedingStatus,
        () => updateConcentratedFeeding(attachFarmAndUserProperties(command))
          .then(() => this.getGerblhofFeedingForHorse())
      );
    },

    removeConcentratedFeedingUpdate(command: RemoveConcentratedFeedingUpdateCommand): Promise<void> {
      const { removeConcentratedFeedingUpdateStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeConcentratedFeedingUpdateStatus,
        () => removeConcentratedFeedingUpdate(attachFarmAndUserProperties(command))
          .then(() => this.getGerblhofFeedingForHorse())
      );
    },

    createVaccination(command: CreateVaccinationCommand): Promise<void> {
      const { createVaccinationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createVaccinationStatus,
        () => createVaccination(attachFarmAndUserProperties(command))
          .then(() => this.getVaccinationCertificateForHorse())
      );
    },

    updateVaccinationNotice(command: UpdateVaccinationNoticeCommand): Promise<void> {
      const { updateVaccinationNoticeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateVaccinationNoticeStatus,
        () => updateVaccinationNotice(attachFarmAndUserProperties(command))
          .then(() => this.getVaccinationCertificateForHorse())
      );
    },

    deleteVaccination(command: DeleteVaccinationCommand): Promise<void> {
      const { deleteVaccinationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteVaccinationStatus,
        () => deleteVaccination(attachFarmAndUserProperties(command))
          .then(() => this.getVaccinationCertificateForHorse())
      );
    },

    defineInvoiceAddress(command: DefineInvoiceAddressCommand): Promise<void> {
      const { defineInvoiceAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineInvoiceAddressStatus,
        () => defineInvoiceAddress(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    updateInvoiceAddress(command: UpdateInvoiceAddressCommand): Promise<void> {
      const { updateInvoiceAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvoiceAddressStatus,
        () => updateInvoiceAddress(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    removeInvoiceAddress(command: RemoveInvoiceAddressCommand): Promise<void> {
      const { removeInvoiceAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeInvoiceAddressStatus,
        () => removeInvoiceAddress(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    enableInvoiceEmailDispatch(command: EnableInvoiceEmailDispatchCommand): Promise<void> {
      const { enableInvoiceEmailDispatchStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableInvoiceEmailDispatchStatus,
        () => enableInvoiceEmailDispatch(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    disableInvoiceEmailDispatch(command: DisableInvoiceEmailDispatchCommand): Promise<void> {
      const { disableInvoiceEmailDispatchStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableInvoiceEmailDispatchStatus,
        () => disableInvoiceEmailDispatch(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    acceptHorseShareInvite(command: AcceptHorseShareInvite.AcceptHorseShareInviteCommand): Promise<void> {
      const { acceptHorseShareInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acceptHorseShareInviteStatus,
        () => acceptHorseShareInvite(attachFarmAndUserProperties(command))
          .then(() => this.getAvailableHorses())
          .then(() => this.getHorseShareInvitesAsInvitedUser())
      );
    },

    rejectHorseShareInvite(command: RejectHorseShareInvite.RejectHorseShareInviteCommand): Promise<void> {
      const { rejectHorseShareInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        rejectHorseShareInviteStatus,
        () => rejectHorseShareInvite(attachFarmAndUserProperties(command))
          .then(() => this.getHorseShareInvitesAsInvitedUser())
      );
    },

    createHorse(command: CreateHorse.CreateHorseWorkflow): Promise<void> {
      const { createHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseStatus,
        () => createHorse(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getAvailableHorses())
      );
    },

    updateHorseName(command: UpdateHorseName.UpdateHorseNameWorkflow): Promise<void> {
      const { updateHorseNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHorseNameStatus,
        () => updateHorseName(attachFarmAndUserProperties(command))
          .then(() => this.getAvailableHorses())
          .then(() => this.getHorse())
      );
    },

    createFeedingSettings(command: CreateFeedingSettingsCommand): Promise<void> {
      const { createFeedingSettingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFeedingSettingsStatus,
        () => createFeedingSettings(attachFarmAndUserProperties(command))
          .then(() => this.getFeedingPlan())
      );
    },

    updateFeedingSettings(command: UpdateFeedingSettingsCommand): Promise<void> {
      const { updateFeedingSettingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFeedingSettingsStatus,
        () => updateFeedingSettings(attachFarmAndUserProperties(command))
          .then(() => this.getFeedingPlan())
      );
    },

    createHorseTrainingSession(command: CreateHorseTrainingSessionCommand): Promise<void> {
      const { createHorseTrainingSessionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseTrainingSessionStatus,
        () => createHorseTrainingSession(attachFarmAndUserProperties(command))
          .then(() => this.getTrainingDiary())
      );
    },

    createHorseTrainingSessionForFacilityReservation(command: CreateHorseTrainingSessionForFacilityReservationCommand): Promise<void> {
      const { createHorseTrainingSessionForFacilityReservationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseTrainingSessionForFacilityReservationStatus,
        () => createHorseTrainingSessionForFacilityReservation(attachFarmAndUserProperties(command))
          .then(() => this.getTrainingDiary())
      );
    },

    updateHorseTrainingSession(command: UpdateHorseTrainingSessionCommand): Promise<void> {
      const { updateHorseTrainingSessionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHorseTrainingSessionStatus,
        () => updateHorseTrainingSession(attachFarmAndUserProperties(command))
          .then(() => this.getTrainingDiary())
      );
    },

    deleteHorseTrainingSession(command: DeleteHorseTrainingSessionCommand): Promise<void> {
      const { deleteHorseTrainingSessionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseTrainingSessionStatus,
        () => deleteHorseTrainingSession(attachFarmAndUserProperties(command))
          .then(() => this.getTrainingDiary())
      );
    },

    updateHorseImage(command: UpdateHorseImage.UpdateHorseImageCommand): Promise<void> {
      const { updateHorseImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHorseImageStatus,
        () => updateHorseImage(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getAvailableHorses())
          .then(() => this.getHorse())
      );
    },

    createHorseWeightEntry(command: CreateHorseWeightEntryCommand): Promise<void> {
      const { createHorseWeightEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWeightEntryStatus,
        () => createHorseWeightEntry(attachFarmAndUserProperties(command))
          .then(() => this.getWeightForHorse())
      );
    },

    deleteHorseWeightEntry(command: DeleteHorseWeightEntryCommand): Promise<void> {
      const { deleteHorseWeightEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseWeightEntryStatus,
        () => deleteHorseWeightEntry(attachFarmAndUserProperties(command))
          .then(() => this.getWeightForHorse())
      );
    },

    createHorseDrugEntry(command: CreateHorseDrugEntryCommand): Promise<void> {
      const { createHorseDrugEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseDrugEntryStatus,
        () => createHorseDrugEntry(attachFarmAndUserProperties(command))
          .then(() => this.getDrugsForHorse())
      );
    },

    deleteHorseDrugEntry(command: DeleteHorseDrugEntryCommand): Promise<void> {
      const { deleteHorseDrugEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseDrugEntryStatus,
        () => deleteHorseDrugEntry(attachFarmAndUserProperties(command))
          .then(() => this.getDrugsForHorse())
      );
    },

    createHorseFarrierAppointment(command: CreateHorseFarrierAppointmentCommand): Promise<void> {
      const { createHorseFarrierAppointmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseFarrierAppointmentStatus,
        () => createHorseFarrierAppointment(attachFarmAndUserProperties(command))
          .then(() => this.getFarrierAppointmentsForHorse())
      );
    },

    deleteHorseFarrierAppointment(command: DeleteHorseFarrierAppointmentCommand): Promise<void> {
      const { deleteHorseFarrierAppointmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseFarrierAppointmentStatus,
        () => deleteHorseFarrierAppointment(attachFarmAndUserProperties(command))
          .then(() => this.getFarrierAppointmentsForHorse())
      );
    },

    createHorseWormingTreatment(command: CreateHorseWormingTreatmentCommand): Promise<void> {
      const { createHorseWormingTreatmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWormingTreatmentStatus,
        () => createHorseWormingTreatment(attachFarmAndUserProperties(command))
          .then(() => this.getWormingTreatmentsForHorse())
      );
    },

    deleteHorseWormingTreatment(command: DeleteHorseWormingTreatmentCommand): Promise<void> {
      const { deleteHorseWormingTreatmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseWormingTreatmentStatus,
        () => deleteHorseWormingTreatment(attachFarmAndUserProperties(command))
          .then(() => this.getWormingTreatmentsForHorse())
      );
    },

    createHorseVeterinarianAppointment(command: CreateHorseVeterinarianAppointmentCommand): Promise<void> {
      const { createHorseVeterinarianAppointmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseVeterinarianAppointmentStatus,
        () => createHorseVeterinarianAppointment(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getVeterinarianAppointmentsForHorse())
      );
    },

    deleteHorseVeterinarianAppointment(command: DeleteHorseVeterinarianAppointmentCommand): Promise<void> {
      const { deleteHorseVeterinarianAppointmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseVeterinarianAppointmentStatus,
        () => deleteHorseVeterinarianAppointment(attachFarmAndUserProperties(command))
          .then(() => this.getVeterinarianAppointmentsForHorse())
      );
    },

    updatePhoneNumber(command: UpdatePhoneNumberCommand): Promise<void> {
      const { updatePhoneNumberStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePhoneNumberStatus,
        () => updatePhoneNumber(attachFarmAndUserProperties(command))
      );
    },

    updateVeterinarianOfHorse(command: UpdateVeterinarianOfHorse.UpdateVeterinarianOfHorseCommand): Promise<void> {
      const { updateVeterinarianOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateVeterinarianOfHorseStatus,
        () => updateVeterinarianOfHorse(attachFarmAndUserProperties(command))
          .then(() => this.getHorse())
      );
    },

    updateFarrierOfHorse(command: UpdateFarrierOfHorse.UpdateFarrierOfHorseCommand): Promise<void> {
      const { updateFarrierOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarrierOfHorseStatus,
        () => updateFarrierOfHorse(attachFarmAndUserProperties(command))
          .then(() => this.getHorse())
      );
    },

    updateEmergencyContactOfUser(command: UpdateEmergencyContactOfUser.UpdateEmergencyContactOfUserCommand): Promise<void> {
      const { updateEmergencyContactOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEmergencyContactOfUserStatus,
        () => updateEmergencyContactOfUser(attachFarmAndUserProperties(command))
          .then(() => this.getHorse())
      );
    },

    updateEquineNumberOfHorse(command: UpdateEquineNumberOfHorse.UpdateEquineNumberOfHorseCommand): Promise<void> {
      const { updateEquineNumberOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEquineNumberOfHorseStatus,
        () => updateEquineNumberOfHorse(attachFarmAndUserProperties(command))
          .then(() => this.getHorse())
      );
    },

    updateBreedOfHorse(command: UpdateBreedOfHorse.UpdateBreedOfHorseCommand): Promise<void> {
      const { updateBreedOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBreedOfHorseStatus,
        () => updateBreedOfHorse(command)
          .then(() => this.getHorse())
      );
    },

    updateColorOfHorse(command: UpdateColorOfHorse.UpdateColorOfHorseCommand): Promise<void> {
      const { updateColorOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateColorOfHorseStatus,
        () => updateColorOfHorse(command)
          .then(() => this.getHorse())
      );
    },

    updateGenderOfHorse(command: UpdateGenderOfHorse.UpdateGenderOfHorseCommand): Promise<void> {
      const { updateGenderOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGenderOfHorseStatus,
        () => updateGenderOfHorse(command)
          .then(() => this.getHorse())
      );
    },

    updateStockSizeOfHorse(command: UpdateStockSizeOfHorse.UpdateStockSizeOfHorseCommand): Promise<void> {
      const { updateStockSizeOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateStockSizeOfHorseStatus,
        () => updateStockSizeOfHorse(command)
          .then(() => this.getHorse())
      );
    },

    updateBirthInformationOfHorse(
      command: UpdateBirthInformationOfHorse.UpdateBirthInformationOfHorseCommand
    ): Promise<void> {
      const { updateBirthInformationOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBirthInformationOfHorseStatus,
        () => updateBirthInformationOfHorse(command)
          .then(() => this.getHorse())
      );
    },

    updateLineageOfHorse(command: UpdateLineageOfHorse.UpdateLineageOfHorseCommand): Promise<void> {
      const { updateLineageOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateLineageOfHorseStatus,
        () => updateLineageOfHorse(command)
          .then(() => this.getHorse())
      );
    },

    updateCustomFieldsOfHorse(command: UpdateCustomFieldsOfHorse.UpdateCustomFieldsOfHorseCommand): Promise<void> {
      const { updateCustomFieldsOfHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCustomFieldsOfHorseStatus,
        () => updateCustomFieldsOfHorse(command)
          .then(() => this.getHorse())
      );
    },

  },
});
