import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import LaborServicesTabs from './components/labor-services-tabs.vue';
import LaborServiceConfiguration from './components/labor-service-configuration.vue';
import LaborServiceSummary from './components/labor-service-summary.vue';
import LaborServiceTimeEntries from './components/labor-service-time-entries.vue';

const defaultMeta = {
  title: 'Arbeitsdienst',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.LABOR_SERVICE,
  requiresPermission: FarmManagerPermission.LABOR_SERVICE_READ,
};

export const laborServicesManagementRoutes: RouteConfig[] = [
  {
    path: 'arbeitsdienst',
    component: LaborServicesTabs,
    children: [
      {
        // Warning - Used in push notifications
        name: 'labor-services-management-time-entries',
        path: '',
        component: LaborServiceTimeEntries,
        meta: defaultMeta,
      },
      {
        name: 'labor-services-management-labor-service-summary',
        path: 'zusammenfassung',
        component: LaborServiceSummary,
        meta: defaultMeta,
      },
      {
        name: 'labor-services-management-configuration',
        path: 'konfiguration',
        component: LaborServiceConfiguration,
        meta: defaultMeta,
      },
    ],
    meta: defaultMeta,
  },
];
