import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { FarmServiceId, HorseId, Month, UserId, Date, PersonId } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { GetFarmServiceOverviewFilterDataAsManager, GetFarmServicesAsManager, GetFarmServiceUsagesForDayAsManager, GetFarmServiceUsagesForMonthAsManager, UpdateHiddenFarmServicesAsManager, CreateFarmServiceAsManager, UpdateFarmServicePresentationAsManager, UpdateFarmServicePriceAsManager, UpdateFarmServicePaymentMethodAsManager, UpdateFarmServiceBookingStartAsManager, UpdateFarmServiceRestrictionsAsManager, EnableFarmServiceAsManager, DisableFarmServiceAsManager, ArchiveFarmServiceAsManager, updateHiddenFarmServicesAsManager, createFarmServiceAsManager, updateFarmServicePresentationAsManager, updateFarmServicePriceAsManager, updateFarmServicePaymentMethodAsManager, updateFarmServiceBookingStartAsManager, updateFarmServiceRestrictionsAsManager, enableFarmServiceAsManager, disableFarmServiceAsManager, archiveFarmServiceAsManager, getFarmServiceOverviewFilterDataAsManager, getFarmServiceUsagesForDayAsManager, getFarmServiceUsagesForMonthAsManager, getFarmServicesAsManager } from '@/feature/farm-services';

interface FarmServiceState {
  farmServiceOverviewFilterData: APIResource<GetFarmServiceOverviewFilterDataAsManager.FarmServiceOverviewFilterData>;

  selectedDate: Date | null;
  selectedMonth: Month | null;
  selectedPerson: UserId | null;
  selectedHorse: HorseId | null;
  selectedFarmService: FarmServiceId | null;

  farmServiceUsagesForDay: APIResource<GetFarmServiceUsagesForDayAsManager.FarmServiceUsages>;
  farmServiceUsagesForMonth: APIResource<GetFarmServiceUsagesForMonthAsManager.FarmServiceUsages>;
  farmServices: APIResource<GetFarmServicesAsManager.FarmService[]>;
  searchForFarmServices: string | null;

  currentFarmService: GetFarmServicesAsManager.FarmService | null;
  idOfCurrentFarmService: FarmServiceId | null;

  updateHiddenFarmServicesStatus: ActionStatus;
  createFarmServiceStatus: ActionStatus;
  updateFarmServicePresentationStatus: ActionStatus;
  updateFarmServicePriceStatus: ActionStatus;
  updateFarmServicePaymentMethodStatus: ActionStatus;
  updateFarmServiceBookingStartStatus: ActionStatus;
  updateFarmServiceRestrictionsStatus: ActionStatus;
  enableFarmServiceStatus: ActionStatus;
  disableFarmServiceStatus: ActionStatus;
  archiveFarmServiceStatus: ActionStatus;
}

function initialState(): FarmServiceState {
  return {
    farmServiceOverviewFilterData: initialAPIResource(),

    selectedDate: null,
    selectedMonth: null,
    selectedPerson: null,
    selectedHorse: null,
    selectedFarmService: null,
    farmServiceUsagesForDay: initialAPIResource(),
    farmServiceUsagesForMonth: initialAPIResource(),
    farmServices: initialAPIResource(),
    searchForFarmServices: null,

    currentFarmService: null,
    idOfCurrentFarmService: null,

    updateHiddenFarmServicesStatus: ActionStatus.None,
    createFarmServiceStatus: ActionStatus.None,
    updateFarmServicePresentationStatus: ActionStatus.None,
    updateFarmServicePriceStatus: ActionStatus.None,
    updateFarmServicePaymentMethodStatus: ActionStatus.None,
    updateFarmServiceBookingStartStatus: ActionStatus.None,
    updateFarmServiceRestrictionsStatus: ActionStatus.None,
    enableFarmServiceStatus: ActionStatus.None,
    disableFarmServiceStatus: ActionStatus.None,
    archiveFarmServiceStatus: ActionStatus.None,
  };
}

export const useFarmServiceStore = defineStore('farmService', {
  state: (): FarmServiceState => initialState(),
  actions: {

    // -- State management

    updateSelectedDate(selectedDate: Date): Promise<void> {
      this.selectedDate = selectedDate;

      return this.getFarmServiceUsagesForDayAsManager();
    },

    updateSelectedMonth(selectedMonth: Month): Promise<void> {
      this.selectedMonth = selectedMonth;

      return this.getFarmServiceUsagesForMonthAsManager();
    },

    updateSelectedPerson(personId: PersonId | null): Promise<void> {
      this.selectedPerson = personId;

      return Promise.resolve();
    },

    updateSelectedHorse(horseId: HorseId | null): Promise<void> {
      this.selectedHorse = horseId;

      return Promise.resolve();
    },

    updateSelectedFarmService(farmServiceId: FarmServiceId | null): Promise<void> {
      this.selectedFarmService = farmServiceId;

      return Promise.resolve();
    },

    async updateCurrentFarmService(farmServiceId: FarmServiceId): Promise<void> {
      this.idOfCurrentFarmService = farmServiceId;
      if (this.farmServices.value === null) {
        return this.getFarmServicesAsManager()
          .then(() => {
            this.currentFarmService = this.farmServices.value!.find((farmService) => farmService.farmServiceId === farmServiceId)!;
          });
      } else {
        this.currentFarmService = this.farmServices.value!.find((farmService) => farmService.farmServiceId === farmServiceId)!;
        return Promise.resolve();
      }
    },

    resetCurrentFarmService(): Promise<void> {
      this.idOfCurrentFarmService = null;

      return Promise.resolve();
    },

    updateSearchForFarmServices(searchForFarmServices: string | null): Promise<void> {
      this.searchForFarmServices = searchForFarmServices;

      return this.getFarmServicesAsManager();
    },

    // -- Queries

    getFarmServiceOverviewFilterDataAsManager(): Promise<void> {
      const query: GetFarmServiceOverviewFilterDataAsManager.GetFarmServiceOverviewFilterDataAsManagerQuery = {};
      const { farmServiceOverviewFilterData } = storeToRefs(this);
      return wrapAction(
        farmServiceOverviewFilterData,
        () => getFarmServiceOverviewFilterDataAsManager(query)
      );
    },

    getFarmServiceUsagesForDayAsManager(): Promise<void> {
      const query: GetFarmServiceUsagesForDayAsManager.GetFarmServiceUsagesForDayAsManagerQuery = {
        date: this.selectedDate!,
        filteredByPerson: this.selectedPerson,
        filteredByHorse: this.selectedHorse,
        filteredByFarmService: this.selectedFarmService,
      };
      const { farmServiceUsagesForDay } = storeToRefs(this);
      return wrapAction(
        farmServiceUsagesForDay,
        () => getFarmServiceUsagesForDayAsManager(query)
      );
    },

    getFarmServiceUsagesForMonthAsManager(): Promise<void> {
      const query: GetFarmServiceUsagesForMonthAsManager.GetFarmServiceUsagesForMonthAsManagerQuery = {
        month: this.selectedMonth!,
        filteredByPerson: this.selectedPerson,
        filteredByHorse: this.selectedHorse,
        filteredByFarmService: this.selectedFarmService,
      };
      const { farmServiceUsagesForMonth } = storeToRefs(this);
      return wrapAction(
        farmServiceUsagesForMonth,
        () => getFarmServiceUsagesForMonthAsManager(query)
      );
    },

    getFarmServicesAsManager(): Promise<void> {
      const query: GetFarmServicesAsManager.GetFarmServicesAsManagerQuery = {
        search: this.searchForFarmServices,
      };
      const { farmServices } = storeToRefs(this);
      return wrapAction(
        farmServices,
        () => getFarmServicesAsManager(query)
      ).then(() => {
        if (this.idOfCurrentFarmService !== null) {
          this.currentFarmService = this.farmServices.value!
            .find((farmService) => farmService.farmServiceId === this.idOfCurrentFarmService)!;
        }
      });
    },

    // -- Commands

    updateHiddenFarmServicesAsManager(command: UpdateHiddenFarmServicesAsManager.UpdateHiddenFarmServicesAsManagerCommand): Promise<void> {
      const { updateHiddenFarmServicesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHiddenFarmServicesStatus,
        () => updateHiddenFarmServicesAsManager(command)
          .then(() => this.getFarmServiceOverviewFilterDataAsManager())
          .then(async () => {
            const actions = [];
            if (this.selectedDate !== null) {
              actions.push(this.getFarmServiceUsagesForDayAsManager());
            }
            if (this.selectedMonth !== null) {
              actions.push(this.getFarmServiceUsagesForMonthAsManager());
            }

            return Promise.all(actions).then(() => Promise.resolve());
          })
      );
    },

    createFarmServiceAsManager(command: CreateFarmServiceAsManager.CreateFarmServiceAsManagerCommand): Promise<void> {
      const { createFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFarmServiceStatus,
        () => createFarmServiceAsManager(command)
          .then(() => this.getFarmServicesAsManager())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateFarmServicePresentationAsManager(
      command: UpdateFarmServicePresentationAsManager.UpdateFarmServicePresentationAsManagerCommand
    ): Promise<void> {
      const { updateFarmServicePresentationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePresentationStatus,
        () => updateFarmServicePresentationAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    updateFarmServicePriceAsManager(command: UpdateFarmServicePriceAsManager.UpdateFarmServicePriceAsManagerCommand): Promise<void> {
      const { updateFarmServicePriceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePriceStatus,
        () => updateFarmServicePriceAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    updateFarmServicePaymentMethodAsManager(
      command: UpdateFarmServicePaymentMethodAsManager.UpdateFarmServicePaymentMethodAsManagerCommand
    ): Promise<void> {
      const { updateFarmServicePaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePaymentMethodStatus,
        () => updateFarmServicePaymentMethodAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    updateFarmServiceBookingStartAsManager(
      command: UpdateFarmServiceBookingStartAsManager.UpdateFarmServiceBookingStartAsManagerCommand
    ): Promise<void> {
      const { updateFarmServiceBookingStartStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServiceBookingStartStatus,
        () => updateFarmServiceBookingStartAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    updateFarmServiceRestrictionsAsManager(
      command: UpdateFarmServiceRestrictionsAsManager.UpdateFarmServiceRestrictionsAsManagerCommand
    ): Promise<void> {
      const { updateFarmServiceRestrictionsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServiceRestrictionsStatus,
        () => updateFarmServiceRestrictionsAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    enableFarmServiceAsManager(command: EnableFarmServiceAsManager.EnableFarmServiceAsManagerCommand): Promise<void> {
      const { enableFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableFarmServiceStatus,
        () => enableFarmServiceAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    disableFarmServiceAsManager(command: DisableFarmServiceAsManager.DisableFarmServiceAsManagerCommand): Promise<void> {
      const { disableFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableFarmServiceStatus,
        () => disableFarmServiceAsManager(command)
          .then(() => this.getFarmServicesAsManager())
      );
    },

    archiveFarmServiceAsManager(command: ArchiveFarmServiceAsManager.ArchiveFarmServiceAsManagerCommand): Promise<void> {
      const { archiveFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archiveFarmServiceStatus,
        () => archiveFarmServiceAsManager(command)
          .then(() => this.getFarmServicesAsManager())
          .then(() => useAuthenticationStore().getAuthentication()) // Farm services might become hidden for riders
      );
    },

  },
});
