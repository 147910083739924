import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { SurveyId } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { createSurveyAsManager, CreateSurveyAsManager, deleteSurveyAnswerAsManager, DeleteSurveyAnswerAsManager, deleteSurveyAsManager, DeleteSurveyAsManager, getSurveyDetailsAsManager, GetSurveyDetailsAsManager, getSurveyForDuplicationAsManager, GetSurveyForDuplicationAsManager, getSurveysAsManager, GetSurveysAsManager, GetUserGroupsAsManager, getUserGroupsAsManager } from '@/feature/surveys';

interface SurveyManagementState {
  currentSurveyId: SurveyId | null;
  currentSurvey: APIResource<GetSurveyDetailsAsManager.Survey>;
  surveyForDuplication: APIResource<GetSurveyForDuplicationAsManager.Survey>;
  surveys: APIResource<GetSurveysAsManager.Survey[]>;
  userGroups: APIResource<GetUserGroupsAsManager.UserGroup[]>;

  createSurveyAsManagerStatus: ActionStatus;
  deleteSurveyAsManagerStatus: ActionStatus;
  deleteSurveyAnswerAsManagerStatus: ActionStatus;
}

function initialState(): SurveyManagementState {
  return {
    currentSurveyId: null,
    currentSurvey: initialAPIResource(),
    surveyForDuplication: initialAPIResource(),
    surveys: initialAPIResource(),
    userGroups: initialAPIResource(),

    createSurveyAsManagerStatus: ActionStatus.None,
    deleteSurveyAsManagerStatus: ActionStatus.None,
    deleteSurveyAnswerAsManagerStatus: ActionStatus.None,
  };
}

export const useSurveyManagementStore = defineStore('surveyManagement', {
  state: (): SurveyManagementState => initialState(),
  actions: {

    // -- State management

    updateCurrentSurvey(surveyId: SurveyId): Promise<void> {
      this.currentSurveyId = surveyId;

      return this.getSurveyDetailsAsManager();
    },

    // -- Queries

    getSurveysAsManager(): Promise<void> {
      const { surveys } = storeToRefs(this);
      return wrapAction(
        surveys,
        () => getSurveysAsManager({})
      );
    },

    getSurveyDetailsAsManager(): Promise<void> {
      const { currentSurvey } = storeToRefs(this);
      const query: GetSurveyDetailsAsManager.GetSurveyDetailsAsManagerQuery = {
        surveyId: this.currentSurveyId!,
      };
      return wrapAction(
        currentSurvey,
        () => getSurveyDetailsAsManager(query)
      );
    },

    getSurveyForDuplicationAsManager(query: GetSurveyForDuplicationAsManager.GetSurveyForDuplicationAsManagerQuery): Promise<void> {
      const { surveyForDuplication } = storeToRefs(this);
      return wrapAction(
        surveyForDuplication,
        () => getSurveyForDuplicationAsManager(query)
      );
    },

    getUserGroupsAsManager(): Promise<void> {
      const { userGroups } = storeToRefs(this);
      return wrapAction(
        userGroups,
        () => getUserGroupsAsManager({})
      );
    },

    // -- Commands

    createSurveyAsManager(command: CreateSurveyAsManager.CreateSurveyAsManagerCommand): Promise<void> {
      const { createSurveyAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSurveyAsManagerStatus,
        () => createSurveyAsManager(command)
          .then(() => this.getSurveysAsManager())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    deleteSurveyAsManager(command: DeleteSurveyAsManager.DeleteSurveyAsManagerCommand): Promise<void> {
      const { deleteSurveyAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSurveyAsManagerStatus,
        () => deleteSurveyAsManager(command)
          .then(() => this.getSurveysAsManager())
      );
    },

    deleteSurveyAnswerAsManager(command: DeleteSurveyAnswerAsManager.DeleteSurveyAnswerAsManagerCommand): Promise<void> {
      const { deleteSurveyAnswerAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSurveyAnswerAsManagerStatus,
        () => deleteSurveyAnswerAsManager(command)
          .then(() => this.getSurveyDetailsAsManager())
      );
    },

  },
});
