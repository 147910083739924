import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { attachUserProperty } from '@/helpers/default-parameter-helper';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { GetAllAdminNotificationsAsAdmin, CreateAdminNotificationAsAdmin, UpdateAdminNotificationAsAdmin, DeleteAdminNotificationAsAdmin, getAllAdminNotificationsAsAdmin, createAdminNotificationAsAdmin, updateAdminNotificationAsAdmin, deleteAdminNotificationAsAdmin } from '@/feature/admin-notifications';

interface ManageAdminNotificationsState {
  adminNotifications: APIResource<GetAllAdminNotificationsAsAdmin.AdminNotification[]>;

  createAdminNotificationStatus: ActionStatus;
  updateAdminNotificationStatus: ActionStatus;
  deleteAdminNotificationStatus: ActionStatus;
}

function initialState(): ManageAdminNotificationsState {
  return {
    adminNotifications: initialAPIResource(),

    createAdminNotificationStatus: ActionStatus.None,
    updateAdminNotificationStatus: ActionStatus.None,
    deleteAdminNotificationStatus: ActionStatus.None,
  };
}

export const useAdminNotificationManagementStore = defineStore('manageAdminNotifications', {
  state: (): ManageAdminNotificationsState => initialState(),
  actions: {

    // -- Queries

    getAllAdminNotifications(): Promise<void> {
      const { adminNotifications } = storeToRefs(this);
      return wrapAction(
        adminNotifications,
        () => getAllAdminNotificationsAsAdmin({})
      );
    },

    // -- Commands

    createAdminNotification(command: CreateAdminNotificationAsAdmin.CreateAdminNotificationAsAdminCommand): Promise<void> {
      const { createAdminNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createAdminNotificationStatus,
        () => createAdminNotificationAsAdmin(attachUserProperty(command))
          .then(() => this.getAllAdminNotifications())
      );
    },

    updateAdminNotification(command: UpdateAdminNotificationAsAdmin.UpdateAdminNotificationAsAdminCommand): Promise<void> {
      const { updateAdminNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAdminNotificationStatus,
        () => updateAdminNotificationAsAdmin(attachUserProperty(command))
          .then(() => this.getAllAdminNotifications())
      );
    },

    deleteAdminNotification(command: DeleteAdminNotificationAsAdmin.DeleteAdminNotificationAsAdminCommand): Promise<void> {
      const { deleteAdminNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteAdminNotificationStatus,
        () => deleteAdminNotificationAsAdmin(attachUserProperty(command))
          .then(() => this.getAllAdminNotifications())
      );
    },

  },
});
