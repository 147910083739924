import { defineStore, storeToRefs } from 'pinia';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { createPenStatusEntryAsManager, CreatePenStatusEntryAsManager, GetCurrentPenStatusEntry, getCurrentPenStatusEntry, updatePenStatusEntryAsManager, UpdatePenStatusEntryAsManager } from '@/feature/pen';

interface ControlPanelState {
  currentPenStatus: APIResource<GetCurrentPenStatusEntry.PenStatusResponse>;

  createPenStatusEntryAsManagerStatus: ActionStatus;
  updatePenStatusEntryAsManagerStatus: ActionStatus;
}

function initialState(): ControlPanelState {
  return {
    currentPenStatus: initialAPIResource(),

    createPenStatusEntryAsManagerStatus: ActionStatus.None,
    updatePenStatusEntryAsManagerStatus: ActionStatus.None,
  };
}

export const useControlPanelStore = defineStore('controlPanel', {
  state: (): ControlPanelState => initialState(),
  actions: {

    // -- Queries

    getCurrentPenStatusEntry(): Promise<void> {
      const query: GetCurrentPenStatusEntry.GetCurrentPenStatusEntryQuery = {};
      const { currentPenStatus } = storeToRefs(this);
      return wrapAction(
        currentPenStatus,
        () => getCurrentPenStatusEntry(query)
      );
    },

    // -- Commands

    createPenStatusEntryAsManager(command: CreatePenStatusEntryAsManager.CreatePenStatusEntryAsManagerCommand): Promise<void> {
      const { createPenStatusEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPenStatusEntryAsManagerStatus,
        () => createPenStatusEntryAsManager(command)
          .then(() => this.getCurrentPenStatusEntry())
      );
    },

    updatePenStatusEntryAsManager(command: UpdatePenStatusEntryAsManager.UpdatePenStatusEntryAsManagerCommand): Promise<void> {
      const { updatePenStatusEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePenStatusEntryAsManagerStatus,
        () => updatePenStatusEntryAsManager(command)
          .then(() => this.getCurrentPenStatusEntry())
      );
    },

  },
});
