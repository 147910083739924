import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import EventTabs from './components/event-tabs.vue';
import EventList from './components/event-list.vue';
import EventConfiguration from './components/event-configuration.vue';

export const eventManagementRoutes: RouteConfig[] = [
  {
    path: 'veranstaltungen',
    component: EventTabs,
    children: [
      {
        name: 'manage-events',
        path: '',
        component: EventList,
        meta: {
          title: 'Veranstaltungen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.EVENTS_READ,
        },
      },
      {
        name: 'event-configuration',
        path: 'konfiguration',
        component: EventConfiguration,
        meta: {
          title: 'Veranstaltungen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.EVENTS_READ,
        },
      },
    ],
    meta: {
      title: 'Veranstaltungen',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresFeature: Feature.EVENTS,
      requiresPermission: FarmManagerPermission.EVENTS_READ,
    },
  },
];
