import { FarmAndUserProperties, FarmAndUserPropertiesForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { DefineInvoiceAddressCommand, DisableInvoiceEmailDispatchCommand, EnableInvoiceEmailDispatchCommand, GetInvoicePDFQuery, Invoice, InvoiceConfiguration, RemoveInvoiceAddressCommand, UpdateInvoiceAddressCommand, CreateFarmServiceBookingCommand, CancelFarmServiceBookingCommand, CreateVaccinationCommand, GetGerblhofFeedingForHorseQuery, RemoveConcentratedFeedingUpdateCommand, RemoveFeedingUpdateCommand, UpdateConcentratedFeedingCommand, UpdateFeedingCommand, GetVaccinationCertificateForHorseQuery, VaccinationCertificate, UpdateVaccinationNoticeCommand, CreateFeedingSettingsCommand, UpdateFeedingSettingsCommand, DeleteVaccinationCommand, CreateHorseTrainingSessionCommand, GetTrainingDiaryQuery, TrainingDiaryEntry, CreateHorseTrainingSessionForFacilityReservationCommand, DeleteHorseTrainingSessionCommand, UpdateHorseTrainingSessionCommand, CreateHorseWeightEntryCommand, GetWeightForHorseQuery, HorseWeight, DeleteHorseWeightEntryCommand, HorseDrugs, GetDrugsForHorseQuery, CreateHorseDrugEntryCommand, DeleteHorseDrugEntryCommand, GetFarrierAppointmentsForHorseQuery, HorseFarrierAppointments, CreateHorseFarrierAppointmentCommand, DeleteHorseFarrierAppointmentCommand, CreateHorseWormingTreatmentCommand, DeleteHorseWormingTreatmentCommand, GetWormingTreatmentsForHorseQuery, HorseWormingTreatments, GetVeterinarianAppointmentsForHorseQuery, HorseVeterinarianAppointments, CreateHorseVeterinarianAppointmentCommand, DeleteHorseVeterinarianAppointmentCommand, UpdatePhoneNumberCommand, FarmService, GetFarmServicesForHorseQuery, GerblhofFeeding } from './types';

// -- Queries

export function getInvoiceConfiguration(query: FarmAndUserProperties): Promise<InvoiceConfiguration> {
  const url = `${apiUrl}/api/invoicing/get-invoice-configuration-query`;
  return performApiRequest<InvoiceConfiguration>({ url, method: 'POST', data: query });
}

export function getInvoices(query: FarmAndUserProperties): Promise<Invoice[]> {
  const url = `${apiUrl}/api/invoicing/get-invoices-query`;
  return performApiRequest<Invoice[]>({ url, method: 'POST', data: query });
}

export function getInvoicePDF(query: GetInvoicePDFQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/invoicing/get-invoice-pdf-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getGerblhofFeedingForHorse(query: GetGerblhofFeedingForHorseQuery & FarmAndUserProperties): Promise<GerblhofFeeding> {
  const url = `${apiUrl}/api/ledger/get-gerblhof-feeding-for-horse-query`;
  return performApiRequest<GerblhofFeeding>({ url, method: 'POST', data: query });
}

export function getVaccinationCertificateForHorse(
  query: GetVaccinationCertificateForHorseQuery & FarmAndUserProperties
): Promise<VaccinationCertificate> {
  const url = `${apiUrl}/api/medical-records/get-vaccination-certificate-for-horse-query`;
  return performApiRequest<VaccinationCertificate>({ url, method: 'POST', data: query });
}

export function getTrainingDiary(query: GetTrainingDiaryQuery & FarmAndUserProperties): Promise<TrainingDiaryEntry[]> {
  const url = `${apiUrl}/api/training-diary/get-training-diary-query`;
  return performApiRequest<TrainingDiaryEntry[]>({ url, method: 'POST', data: query });
}

export function getWeightForHorse(query: GetWeightForHorseQuery & FarmAndUserProperties): Promise<HorseWeight> {
  const url = `${apiUrl}/api/medical-records/get-weight-for-horse-query`;
  return performApiRequest<HorseWeight>({ url, method: 'POST', data: query });
}

export function getDrugsForHorse(query: GetDrugsForHorseQuery & FarmAndUserProperties): Promise<HorseDrugs> {
  const url = `${apiUrl}/api/medical-records/get-drugs-for-horse-query`;
  return performApiRequest<HorseDrugs>({ url, method: 'POST', data: query });
}

export function getFarrierAppointmentsForHorse(
  query: GetFarrierAppointmentsForHorseQuery & FarmAndUserProperties
): Promise<HorseFarrierAppointments> {
  const url = `${apiUrl}/api/medical-records/get-farrier-appointments-for-horse-query`;
  return performApiRequest<HorseFarrierAppointments>({ url, method: 'POST', data: query });
}

export function getVeterinarianAppointmentsForHorse(
  query: GetVeterinarianAppointmentsForHorseQuery & FarmAndUserProperties
): Promise<HorseVeterinarianAppointments> {
  const url = `${apiUrl}/api/medical-records/get-veterinarian-appointments-for-horse-query`;
  return performApiRequest<HorseVeterinarianAppointments>({ url, method: 'POST', data: query });
}

export function getWormingTreatmentsForHorse(
  query: GetWormingTreatmentsForHorseQuery & FarmAndUserProperties
): Promise<HorseWormingTreatments> {
  const url = `${apiUrl}/api/medical-records/get-worming-treatments-for-horse-query`;
  return performApiRequest<HorseWormingTreatments>({ url, method: 'POST', data: query });
}

export function getFarmServicesForHorse(query: GetFarmServicesForHorseQuery & FarmAndUserProperties): Promise<FarmService[]> {
  const url = `${apiUrl}/api/farm-services/get-farm-services-for-horse-query`;
  return performApiRequest<FarmService[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function defineInvoiceAddress(command: DefineInvoiceAddressCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/define-invoice-address-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateInvoiceAddress(command: UpdateInvoiceAddressCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/update-invoice-address-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeInvoiceAddress(command: RemoveInvoiceAddressCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/remove-invoice-address-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function enableInvoiceEmailDispatch(command: EnableInvoiceEmailDispatchCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/enable-invoice-email-dispatch-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function disableInvoiceEmailDispatch(command: DisableInvoiceEmailDispatchCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/invoicing/disable-invoice-email-dispatch-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createFarmServiceBooking(command: CreateFarmServiceBookingCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/create-farm-service-booking-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function cancelFarmServiceBooking(command: CancelFarmServiceBookingCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-services/cancel-farm-service-booking-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateFeeding(command: UpdateFeedingCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-feeding-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function removeFeedingUpdate(command: RemoveFeedingUpdateCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/remove-feeding-update-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateConcentratedFeeding(command: UpdateConcentratedFeedingCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-concentrated-feeding-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function removeConcentratedFeedingUpdate(command: RemoveConcentratedFeedingUpdateCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/remove-concentrated-feeding-update-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createVaccination(command: CreateVaccinationCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-vaccination-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateVaccinationNotice(command: UpdateVaccinationNoticeCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/update-vaccination-notice-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteVaccination(command: DeleteVaccinationCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-vaccination-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createFeedingSettings(command: CreateFeedingSettingsCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/create-feeding-settings-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateFeedingSettings(command: UpdateFeedingSettingsCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/update-feeding-settings-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseTrainingSession(command: CreateHorseTrainingSessionCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/training-diary/create-horse-training-session-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseTrainingSessionForFacilityReservation(
  command: CreateHorseTrainingSessionForFacilityReservationCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/training-diary/create-horse-training-session-for-facility-reservation-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateHorseTrainingSession(command: UpdateHorseTrainingSessionCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/training-diary/update-horse-training-session-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseTrainingSession(command: DeleteHorseTrainingSessionCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/training-diary/delete-horse-training-session-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseWeightEntry(command: CreateHorseWeightEntryCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-weight-entry-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseWeightEntry(command: DeleteHorseWeightEntryCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-weight-entry-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseDrugEntry(command: CreateHorseDrugEntryCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-drug-entry-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseDrugEntry(command: DeleteHorseDrugEntryCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-drug-entry-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseFarrierAppointment(command: CreateHorseFarrierAppointmentCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-farrier-appointment-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseFarrierAppointment(command: DeleteHorseFarrierAppointmentCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-farrier-appointment-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseWormingTreatment(command: CreateHorseWormingTreatmentCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-worming-treatment-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseWormingTreatment(command: DeleteHorseWormingTreatmentCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-worming-treatment-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseVeterinarianAppointment(
  command: CreateHorseVeterinarianAppointmentCommand & FarmAndUserPropertiesForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-veterinarian-appointment-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function deleteHorseVeterinarianAppointment(
  command: DeleteHorseVeterinarianAppointmentCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-veterinarian-appointment-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePhoneNumber(command: UpdatePhoneNumberCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/profile/update-phone-number-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
