import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { CreateTimeEntry, createTimeEntry, GetSummaryForUser, getSummaryForUser } from '@/feature/labor-service';

interface LaborServiceState {
  currentSummaryYear: number | null;
  summary: APIResource<GetSummaryForUser.Summary>;

  createTimeEntryStatus: ActionStatus;
}

function initialState(): LaborServiceState {
  return {
    currentSummaryYear: null,
    summary: initialAPIResource(),

    createTimeEntryStatus: ActionStatus.None,
  };
}

export const useLaborServiceStore = defineStore('laborService', {
  state: (): LaborServiceState => initialState(),
  actions: {

    // -- State management

    updateSummaryYear(summaryYear: number): Promise<void> {
      this.currentSummaryYear = summaryYear;

      return this.getSummaryForUser();
    },

    // -- Queries

    getSummaryForUser(): Promise<void> {
      const query: GetSummaryForUser.GetSummaryForUserQuery = {
        summaryYear: this.currentSummaryYear!,
      };
      const { summary } = storeToRefs(this);
      return wrapAction(
        summary,
        () => getSummaryForUser(query)
      );
    },

    // -- Commands

    createTimeEntry(command: CreateTimeEntry.CreateTimeEntryCommand): Promise<void> {
      const { createTimeEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTimeEntryStatus,
        () => createTimeEntry(command)
          .then(() => this.getSummaryForUser())
      );
    },

  },
});
