import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import RidingLessonTabs from './components/riding-lesson-tabs.vue';
import RidingLessonList from './components/riding-lesson-list.vue';
import RidingLessonConfiguration from './components/riding-lesson-configuration.vue';
import HorseUtilization from './components/horse-utilization.vue';
import Plan from './components/plan.vue';

const defaultMeta = {
  title: 'Reitunterricht',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresFeature: Feature.RIDING_LESSONS,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.RIDING_LESSONS_READ,
};

export const ridingLessonManagementRoutes: RouteConfig[] = [
  {
    path: 'reitunterricht',
    component: RidingLessonTabs,
    children: [
      {
        name: 'riding-lesson-management-riding-lesson-tabs',
        path: '',
        redirect: { name: 'riding-lesson-management-riding-lesson-list' },
      },
      {
        name: 'riding-lesson-management-riding-lesson-list',
        path: 'unterricht-planen',
        component: RidingLessonList,
        meta: defaultMeta,
      },
      {
        name: 'riding-lesson-management-plan',
        path: 'plan',
        component: Plan,
        meta: defaultMeta,
      },
      {
        name: 'riding-lesson-management-horse-utilization',
        path: 'auslastung',
        component: HorseUtilization,
        meta: defaultMeta,
      },
      {
        name: 'riding-lesson-management-riding-lesson-configuration',
        path: 'konfiguration',
        component: RidingLessonConfiguration,
        meta: defaultMeta,
      },
    ],
    meta: defaultMeta,
  },
];
