import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetActivePenExemptionForHorseAsManager, CreatePenStatusEntryAsManager, UpdatePenStatusEntryAsManager, DeletePenStatusEntryAsManager, UpdatePenStatusVisibilityAsManager, EnablePenExemptionsAsManager, DisablePenExemptionsAsManager, GetPenStatusEntriesAsManager, GetConfigurationAsManager, GetPenExemptionsAsManager, GetCurrentPenStatusEntry, GetPenExemptionsForHorse, CreatePenExemption, DeletePenExemption, CreatePenStatusEntriesAsManager } from './types';

// -- Queries

export function getActivePenExemptionForHorseAsManager(
  query: GetActivePenExemptionForHorseAsManager.GetActivePenExemptionForHorseAsManagerQuery
): Promise<GetActivePenExemptionForHorseAsManager.Response> {
  const url = `${apiUrl}/api/pen/get-active-pen-exemption-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPenStatusEntriesAsManager(
  query: GetPenStatusEntriesAsManager.GetPenStatusEntriesAsManagerQuery
): Promise<GetPenStatusEntriesAsManager.PenStatusEntries> {
  const url = `${apiUrl}/api/pen/get-pen-status-entries-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getConfigurationAsManager(
  query: GetConfigurationAsManager.GetConfigurationAsManagerQuery
): Promise<GetConfigurationAsManager.Configuration> {
  const url = `${apiUrl}/api/pen/get-configuration-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPenExemptionsAsManager(
  query: GetPenExemptionsAsManager.GetPenExemptionsAsManagerQuery
): Promise<GetPenExemptionsAsManager.PenExemption[]> {
  const url = `${apiUrl}/api/pen/get-pen-exemptions-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCurrentPenStatusEntry(
  query: GetCurrentPenStatusEntry.GetCurrentPenStatusEntryQuery
): Promise<GetCurrentPenStatusEntry.PenStatusResponse> {
  const url = `${apiUrl}/api/pen/get-current-pen-status-entry-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPenExemptionsForHorse(
  query: GetPenExemptionsForHorse.GetPenExemptionsForHorseQuery
): Promise<GetPenExemptionsForHorse.PenExemptions> {
  const url = `${apiUrl}/api/pen/get-pen-exemptions-for-horse-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createPenStatusEntryAsManager(command: CreatePenStatusEntryAsManager.CreatePenStatusEntryAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/create-pen-status-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createPenStatusEntriesAsManager(
  command: CreatePenStatusEntriesAsManager.CreatePenStatusEntriesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/pen/create-pen-status-entries-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updatePenStatusEntryAsManager(command: UpdatePenStatusEntryAsManager.UpdatePenStatusEntryAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/update-pen-status-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deletePenStatusEntryAsManager(command: DeletePenStatusEntryAsManager.DeletePenStatusEntryAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/delete-pen-status-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updatePenStatusVisibilityAsManager(
  command: UpdatePenStatusVisibilityAsManager.UpdatePenStatusVisibilityAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/pen/update-pen-status-visibility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function enablePenExemptionsAsManager(command: EnablePenExemptionsAsManager.EnablePenExemptionsAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/enable-pen-exemptions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function disablePenExemptionsAsManager(command: DisablePenExemptionsAsManager.DisablePenExemptionsAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/disable-pen-exemptions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createPenExemption(command: CreatePenExemption.CreatePenExemptionCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/create-pen-exemption-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deletePenExemption(command: DeletePenExemption.DeletePenExemptionCommand): Promise<void> {
  const url = `${apiUrl}/api/pen/delete-pen-exemption-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
