import { uuid } from '@/helpers';
import { VuetifySelectItem } from '@/application/types';
import { Form, FormControl, FormControls, InternalValueRules, ValuesOfFormControls } from './types';
import { requiredRule } from './rules';

export function constructForm<T extends FormControls>(
  form: Omit<Form<T>, 'isValid'>
): Form<T> {
  const formControlNames = Object.keys(form.controls);
  Object.values(form.controls).forEach((formControl) => {
    if (formControl.validateFormControlsOnInput !== undefined) {
      formControl.validateFormControlsOnInput.forEach((controlName) => {
        if (!formControlNames.includes(controlName)) {
          throw new Error(`FormControl ${controlName} used in validateFormControlsOnInput does not exist`);
        }
      });
    }
    if (formControl.forceMessagesOfFormControlsVisibleOnInput !== undefined) {
      formControl.forceMessagesOfFormControlsVisibleOnInput.forEach((controlName) => {
        if (!formControlNames.includes(controlName)) {
          throw new Error(`FormControl ${controlName} used in forceMessagesOfFormControlsVisibleOnInput does not exist`);
        }
      });
    }
  });

  return {
    ...form,
    isValid: false,
  } as Form<T>;
}

export function getFormValues<T extends FormControls>(form: Form<T>): ValuesOfFormControls<T> {
  return Object
    .entries(form.controls)
    .reduce((formValues, [name, formControl]) => {
      formValues[name] = formControl.value;
      return formValues;
    }, {} as any) as ValuesOfFormControls<T>;
}

export function createFormControlId(): string {
  return uuid();
}

export function errorMessagesForInternalRules(
  rules: InternalValueRules,
  value: any
): string[] {
  return rules
    .map((rule) => rule(value))
    .filter((message) => message !== true) as string[];
}

export function errorMessagesForFormControl(
  formControl: FormControl<any>
): string[] {
  // Add required rule if relevant and run results
  const ruleResults = formControl.isRequired
    ? [
      requiredRule()(formControl.value),
      ...(formControl.rules ?? [])
        .map((rule) => rule(formControl.value)),
    ]
    : (formControl.rules ?? [])
      .map((rule) => rule(formControl.value));

  return ruleResults
    .filter((message) => message !== true) as string[];
}

export function wasValidationSuccessful(errorMessages: string[]): boolean {
  return errorMessages.length === 0;
}

export function emptyFormFieldWatcher(): () => void {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return () => {};
}

export function labelWithRequiredIndicator(formControl: FormControl<any>): string {
  let labelString = typeof formControl.label === 'string'
    ? formControl.label
    : formControl.label();

  if (formControl.isRequired) {
    labelString += ' *';
  }

  return labelString;
}

export function isFieldShownAsContainingAnError(
  isFocused: boolean,
  isTouched: boolean,
  messages: string[]
): boolean {
  return !isFocused && isTouched && messages.length > 0;
}

export function selectItemsFromEnum<T extends string>(
  type: Record<string, string>,
  translations: Record<T, string>,
  isSortedByTranslation = false
): VuetifySelectItem<T>[] {
  return isSortedByTranslation
    ? Object
      .values(type)
      .map((value) => ({
        text: translations[value as T],
        value: value as T,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
    : Object
      .values(type)
      .map((value) => ({
        text: translations[value as T],
        value: value as T,
      }));
}

export function selectItems<T extends string>(
  items: any[],
  textKey: string,
  valueKey: string,
  hasEmptyOption: boolean,
  emptyOptionText?: string
): VuetifySelectItem<T | null>[] {
  const selectItems: VuetifySelectItem<T | null>[] = items.map((item) => ({
    text: item[textKey],
    value: item[valueKey],
  }));

  if (hasEmptyOption) {
    selectItems.push(({
      value: null,
      text: emptyOptionText ?? '-',
    }));
  }

  return selectItems;
}
