import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { PauseMonitoringForHorseAsManager, UnpauseMonitoringForHorseAsManager, EnablePushNotificationsAsManager, DisablePushNotificationsAsManager, CreateStationAsManager, UpdateStationAsManager, DeleteStationAsManager, GetFeedProtocolOverviewAsManager, GetStationUtilizationAsManager, GetFeedProtocolConfigurationAsManager, GetAlarmProtocolAsManager } from './types';

// -- Queries

export function getFeedProtocolOverviewAsManager(
  query: GetFeedProtocolOverviewAsManager.GetFeedProtocolOverviewAsManagerQuery
): Promise<GetFeedProtocolOverviewAsManager.FeedProtocolOverview> {
  const url = `${apiUrl}/api/feed-protocol/get-feed-protocol-overview-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getAlarmProtocolAsManager(
  query: GetAlarmProtocolAsManager.GetAlarmProtocolAsManagerQuery
): Promise<GetAlarmProtocolAsManager.AlarmProtocol> {
  const url = `${apiUrl}/api/feed-protocol/get-alarm-protocol-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getStationUtilizationAsManager(
  query: GetStationUtilizationAsManager.GetStationUtilizationAsManagerQuery
): Promise<GetStationUtilizationAsManager.StationUtilization> {
  const url = `${apiUrl}/api/feed-protocol/get-station-utilization-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFeedProtocolConfigurationAsManager(
  query: GetFeedProtocolConfigurationAsManager.GetFeedProtocolConfigurationAsManagerQuery
): Promise<GetFeedProtocolConfigurationAsManager.FeedProtocolConfiguration> {
  const url = `${apiUrl}/api/feed-protocol/get-feed-protocol-configuration-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function pauseMonitoringForHorseAsManager(
  command: PauseMonitoringForHorseAsManager.PauseMonitoringForHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/pause-monitoring-for-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function unpauseMonitoringForHorseAsManager(
  command: UnpauseMonitoringForHorseAsManager.UnpauseMonitoringForHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/unpause-monitoring-for-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function enablePushNotificationsAsManager(
  command: EnablePushNotificationsAsManager.EnablePushNotificationsAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/enable-push-notifications-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function disablePushNotificationsAsManager(
  command: DisablePushNotificationsAsManager.DisablePushNotificationsAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/disable-push-notifications-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createStationAsManager(command: CreateStationAsManager.CreateStationAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/create-station-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateStationAsManager(command: UpdateStationAsManager.UpdateStationAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/update-station-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteStationAsManager(command: DeleteStationAsManager.DeleteStationAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/delete-station-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
