import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import RidingLessonPlanLoadingWrapper from './components/riding-lesson-plan-loading-wrapper.vue';

export const ridingLessonPlanAsExternalUserRoutes: RouteConfig[] = [
  // Warning: Path is used in emails (see ClientURLGenerator)
  {
    name: 'riding-lesson-plan-as-external-user',
    path: '/reitunterrichtsplan/:linkOfFilterForRidingLessonPlanId',
    component: RidingLessonPlanLoadingWrapper,
    meta: {
      title: 'Reitunterrichtsplan',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
];
