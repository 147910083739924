import { attachUserProperty, attachUserPropertyToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure';
import { GetFarmsAsAdmin, CreateFarmAsAdmin, UpdateFarmAsAdmin, DeleteFarmAsAdmin, UpdateFarmLogoAsAdmin } from './types';

// -- Queries

export function getFarmsAsAdmin(
  query: GetFarmsAsAdmin.GetFarmsAsAdminQuery
): Promise<GetFarmsAsAdmin.Farms> {
  const url = `${apiUrl}/api/farm-management/get-farms-as-admin-query`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(query) });
}

// -- Command

export function createFarmAsAdmin(
  command: CreateFarmAsAdmin.CreateFarmAsAdminWorkflow
): Promise<void> {
  const url = `${apiUrl}/api/farm-management/create-farm-as-admin-workflow`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(command) });
}

export function updateFarmAsAdmin(
  command: UpdateFarmAsAdmin.UpdateFarmAsAdminCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-management/update-farm-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(command) });
}

export function deleteFarmAsAdmin(
  command: DeleteFarmAsAdmin.DeleteFarmAsAdminCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-management/delete-farm-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(command) });
}

export function updateFarmLogoAsAdmin(
  command: UpdateFarmLogoAsAdmin.UpdateFarmLogoAsAdminCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-management/update-farm-logo-as-admin-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: attachUserPropertyToCommandWithFiles(command) });
}
