import { attachUserProperty } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetAllAdminNotificationsAsAdmin, CreateAdminNotificationAsAdmin, UpdateAdminNotificationAsAdmin, DeleteAdminNotificationAsAdmin } from './types';

// -- Queries

export function getAllAdminNotificationsAsAdmin(
  query: GetAllAdminNotificationsAsAdmin.GetAllAdminNotificationsAsAdminQuery
): Promise<GetAllAdminNotificationsAsAdmin.AdminNotification[]> {
  const url = `${apiUrl}/api/admin-notifications/get-all-admin-notifications-as-admin-query`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(query) });
}

// -- Commands

export function createAdminNotificationAsAdmin(
  command: CreateAdminNotificationAsAdmin.CreateAdminNotificationAsAdminCommand
): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/create-admin-notification-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(command) });
}

export function updateAdminNotificationAsAdmin(
  command: UpdateAdminNotificationAsAdmin.UpdateAdminNotificationAsAdminCommand
): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/update-admin-notification-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(command) });
}

export function deleteAdminNotificationAsAdmin(
  command: DeleteAdminNotificationAsAdmin.DeleteAdminNotificationAsAdminCommand
): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/delete-admin-notification-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(command) });
}
