import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { GetFeedingOverviewAsManager, GetFeedTypesAsManager, GetLatestFeedingSettingUpdatesAsManager, CreateFeedTypeAsManager, DisableFeedTypeAsManager, UpdateNameOfFeedTypeAsManager, UpdateOfferOfFeedTypeAsManager, HideFeedTypeOnBoxSignAsManager, ShowFeedTypeOnBoxSignAsManager, getFeedTypesAsManager, getFeedingOverviewAsManager, getLatestFeedingSettingUpdatesAsManager, createFeedTypeAsManager, disableFeedTypeAsManager, updateNameOfFeedTypeAsManager, updateOfferOfFeedTypeAsManager, hideFeedTypeOnBoxSignAsManager, showFeedTypeOnBoxSignAsManager } from '@/feature/feeding';

interface FeedingManagementState {
  feedTypes: APIResource<GetFeedTypesAsManager.FeedType[]>;
  feedingOverview: APIResource<GetFeedingOverviewAsManager.HorseWithFeeding[]>;
  feedingSettingUpdates: APIResource<GetLatestFeedingSettingUpdatesAsManager.HorseForLatestFeedingUpdate[]>;

  createFeedTypeStatus: ActionStatus;
  disableFeedTypeStatus: ActionStatus;
  updateNameOfFeedTypeStatus: ActionStatus;
  updateOfferOfFeedTypeStatus: ActionStatus;
  hideFeedTypeOnBoxSignStatus: ActionStatus;
  showFeedTypeOnBoxSignStatus: ActionStatus;
}

function initialState(): FeedingManagementState {
  return {
    feedTypes: initialAPIResource(),
    feedingOverview: initialAPIResource(),
    feedingSettingUpdates: initialAPIResource(),

    createFeedTypeStatus: ActionStatus.None,
    disableFeedTypeStatus: ActionStatus.None,
    updateNameOfFeedTypeStatus: ActionStatus.None,
    updateOfferOfFeedTypeStatus: ActionStatus.None,
    hideFeedTypeOnBoxSignStatus: ActionStatus.None,
    showFeedTypeOnBoxSignStatus: ActionStatus.None,
  };
}

export const useFeedingManagementStore = defineStore('feedingManagement', {
  state: (): FeedingManagementState => initialState(),
  actions: {

    // -- Queries

    getFeedTypesAsManager(): Promise<void> {
      const query: GetFeedTypesAsManager.GetFeedTypesAsManagerQuery = {};
      const { feedTypes } = storeToRefs(this);
      return wrapAction(
        feedTypes,
        () => getFeedTypesAsManager(query)
      );
    },

    getFeedingOverviewAsManager(): Promise<void> {
      const query: GetFeedingOverviewAsManager.GetFeedingOverviewAsManagerQuery = {};
      const { feedingOverview } = storeToRefs(this);
      return wrapAction(
        feedingOverview,
        () => getFeedingOverviewAsManager(query)
      );
    },

    getLatestFeedingSettingUpdatesAsManager(): Promise<void> {
      const query: GetLatestFeedingSettingUpdatesAsManager.GetLatestFeedingSettingUpdatesAsManagerQuery = {};
      const { feedingSettingUpdates } = storeToRefs(this);
      return wrapAction(
        feedingSettingUpdates,
        () => getLatestFeedingSettingUpdatesAsManager(query)
      );
    },

    // -- Commands

    createFeedTypeAsManager(command: CreateFeedTypeAsManager.CreateFeedTypeAsManagerCommand): Promise<void> {
      const { createFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFeedTypeStatus,
        () => createFeedTypeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypesAsManager())
          .then(() => useAuthenticationStore().getAuthentication()) // Feature feeding might be now visible for riders
      );
    },

    disableFeedTypeAsManager(command: DisableFeedTypeAsManager.DisableFeedTypeAsManagerCommand): Promise<void> {
      const { disableFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableFeedTypeStatus,
        () => disableFeedTypeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypesAsManager())
      );
    },

    updateNameOfFeedTypeAsManager(command: UpdateNameOfFeedTypeAsManager.UpdateNameOfFeedTypeAsManagerCommand): Promise<void> {
      const { updateNameOfFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfFeedTypeStatus,
        () => updateNameOfFeedTypeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypesAsManager())
      );
    },

    updateOfferOfFeedTypeAsManager(command: UpdateOfferOfFeedTypeAsManager.UpdateOfferOfFeedTypeAsManagerCommand): Promise<void> {
      const { updateOfferOfFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOfferOfFeedTypeStatus,
        () => updateOfferOfFeedTypeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypesAsManager())
      );
    },

    hideFeedTypeOnBoxSignAsManager(command: HideFeedTypeOnBoxSignAsManager.HideFeedTypeOnBoxSignAsManagerCommand): Promise<void> {
      const { hideFeedTypeOnBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        hideFeedTypeOnBoxSignStatus,
        () => hideFeedTypeOnBoxSignAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypesAsManager())
      );
    },

    showFeedTypeOnBoxSignAsManager(command: ShowFeedTypeOnBoxSignAsManager.ShowFeedTypeOnBoxSignAsManagerCommand): Promise<void> {
      const { showFeedTypeOnBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        showFeedTypeOnBoxSignStatus,
        () => showFeedTypeOnBoxSignAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypesAsManager())
      );
    },

  },
});
