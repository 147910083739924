import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { Feature } from '@/types';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { getFarmsAsAdmin, GetFarmsAsAdmin, CreateFarmAsAdmin, UpdateFarmAsAdmin, DeleteFarmAsAdmin, UpdateFarmLogoAsAdmin, createFarmAsAdmin, updateFarmAsAdmin, deleteFarmAsAdmin, updateFarmLogoAsAdmin } from '@/feature/farm-management';

interface ManageFarmsState {
  farms: APIResource<GetFarmsAsAdmin.Farms>;
  search: string | null;
  selectedFeatures: Feature[] | null;
  sortBy: GetFarmsAsAdmin.SortBy;
  currentLimit: number;

  createFarmAsAdminStatus: ActionStatus;
  updateFarmAsAdminStatus: ActionStatus;
  deleteFarmAsAdminStatus: ActionStatus;
  updateFarmLogoAsAdminStatus: ActionStatus;
}

function initialState(): ManageFarmsState {
  return {
    farms: initialAPIResource(),
    search: null,
    selectedFeatures: null,
    sortBy: GetFarmsAsAdmin.SortBy.NAME,
    currentLimit: 50,

    createFarmAsAdminStatus: ActionStatus.None,
    updateFarmAsAdminStatus: ActionStatus.None,
    deleteFarmAsAdminStatus: ActionStatus.None,
    updateFarmLogoAsAdminStatus: ActionStatus.None,
  };
}

export const useFarmManagementStore = defineStore('manageFarms', {
  state: (): ManageFarmsState => initialState(),
  actions: {

    // -- State management

    updateSearch(search: string | null): Promise<void> {
      this.search = search;
      this.currentLimit = 50;

      return this.getFarmsAsAdmin();
    },

    updateSelectedFeatures(features: Feature[] | null): Promise<void> {
      this.selectedFeatures = features;
      this.currentLimit = 50;

      return this.getFarmsAsAdmin();
    },

    updateSortBy(sortBy: GetFarmsAsAdmin.SortBy): Promise<void> {
      this.sortBy = sortBy;

      return this.getFarmsAsAdmin();
    },

    increaseLimit(): Promise<void> {
      this.currentLimit += 50;

      return this.getFarmsAsAdmin();
    },

    resetFilters(): Promise<void> {
      this.search = null;
      this.selectedFeatures = null;
      this.sortBy = GetFarmsAsAdmin.SortBy.NAME;
      this.currentLimit = 50;

      return this.getFarmsAsAdmin();
    },

    // -- Queries

    getFarmsAsAdmin(): Promise<void> {
      const query: GetFarmsAsAdmin.GetFarmsAsAdminQuery = {
        search: this.search,
        features: this.selectedFeatures,
        limit: this.currentLimit,
        sortBy: this.sortBy,
      };

      const { farms } = storeToRefs(this);
      return wrapAction(
        farms,
        () => getFarmsAsAdmin(query)
      );
    },

    // -- Commands

    createFarmAsAdmin(command: CreateFarmAsAdmin.CreateFarmAsAdminWorkflow): Promise<void> {
      const { createFarmAsAdminStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFarmAsAdminStatus,
        () => createFarmAsAdmin(command)
          .then(() => this.getFarmsAsAdmin())
      );
    },

    updateFarmAsAdmin(command: UpdateFarmAsAdmin.UpdateFarmAsAdminCommand): Promise<void> {
      const { updateFarmAsAdminStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmAsAdminStatus,
        () => updateFarmAsAdmin(command)
          .then(() => this.getFarmsAsAdmin())
      );
    },

    deleteFarmAsAdmin(command: DeleteFarmAsAdmin.DeleteFarmAsAdminCommand): Promise<void> {
      const { deleteFarmAsAdminStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteFarmAsAdminStatus,
        () => deleteFarmAsAdmin(command)
          .then(() => this.getFarmsAsAdmin())
      );
    },

    updateFarmLogoAsAdmin(command: UpdateFarmLogoAsAdmin.UpdateFarmLogoAsAdminCommand): Promise<void> {
      const { updateFarmLogoAsAdminStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmLogoAsAdminStatus,
        () => updateFarmLogoAsAdmin(command)
          .then(() => this.getFarmsAsAdmin())
      );
    },

  },
});
