import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import PaddockPlanTabs from './components/paddock-plan-tabs.vue';
import PaddockPlanConfiguration from './components/paddock-plan-configuration.vue';
import PaddockPlan from './components/paddock-plan.vue';

const defaultMeta = {
  title: 'Koppelplan',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.PADDOCK_PLAN,
  requiresPermission: FarmManagerPermission.PADDOCK_PLAN_READ,
};

export const paddockPlanManagementRoutes: RouteConfig[] = [
  {
    path: 'koppelplan',
    component: PaddockPlanTabs,
    children: [
      {
        name: 'paddock-plan-paddock-plan',
        path: '',
        component: PaddockPlan,
        meta: defaultMeta,
      },
      {
        name: 'paddock-plan-configuration',
        path: 'konfiguration',
        component: PaddockPlanConfiguration,
        meta: defaultMeta,
      },
    ],
    meta: defaultMeta,
  },
];
