import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { CSV, Limit, SearchTerm, Year } from '@/types';
import { moment } from '@/helpers';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { getNewTimeEntriesAsManager, GetNewTimeEntriesAsManager, CreateConfigurationForUserGroupAsManager, UpdateLaborServiceConfigurationForUserGroupAsManager, DeleteConfigurationForUserGroupAsManager, MarkTimeEntryAsApprovedAsManager, MarkTimeEntryAsRejectedAsManager, UpdateAnnualNumbersOfHoursAsManager, createConfigurationForUserGroupAsManager, updateLaborServiceConfigurationForUserGroupAsManager, deleteConfigurationForUserGroupAsManager, markTimeEntryAsApprovedAsManager, markTimeEntryAsRejectedAsManager, updateAnnualNumbersOfHoursAsManager, GetConfigurationAsManager, getConfigurationAsManager, GetAvailableUserGroupsAsManager, getAvailableUserGroupsAsManager, GetSummaryAsManager, getSummaryAsManager, GetSummaryAsCSVAsManager, getSummaryAsCSVAsManager, GetHandledTimeEntriesAsManager, getHandledTimeEntriesAsManager } from '@/feature/labor-service';

interface LaborServicesManagementState {
  newTimeEntries: APIResource<GetNewTimeEntriesAsManager.TimeEntry[]>;
  handledTimeEntries: APIResource<GetHandledTimeEntriesAsManager.HandledTimeEntries>;
  configuration: APIResource<GetConfigurationAsManager.Configuration>;
  availableUserGroups: APIResource<GetAvailableUserGroupsAsManager.UserGroup[]>;
  summaryEntries: APIResource<GetSummaryAsManager.SummaryEntry[]>;

  selectedYear: Year;
  limitForHandledTimeEntries: Limit;
  searchForHandledTimeEntries: SearchTerm | null;

  getSummaryAsCSVAsManagerStatus: ActionStatus;
  createConfigurationForUserGroupAsManagerStatus: ActionStatus;
  updateLaborServiceConfigurationForUserGroupAsManagerStatus: ActionStatus;
  deleteConfigurationForUserGroupAsManagerStatus: ActionStatus;
  markTimeEntryAsApprovedStatus: ActionStatus;
  markTimeEntryAsRejectedStatus: ActionStatus;
  updateAnnualNumbersOfHoursStatus: ActionStatus;
}

function initialState(): LaborServicesManagementState {
  return {
    newTimeEntries: initialAPIResource(),
    handledTimeEntries: initialAPIResource(),
    configuration: initialAPIResource(),
    availableUserGroups: initialAPIResource(),
    summaryEntries: initialAPIResource(),

    selectedYear: moment().year(),
    limitForHandledTimeEntries: 50,
    searchForHandledTimeEntries: null,

    getSummaryAsCSVAsManagerStatus: ActionStatus.None,
    createConfigurationForUserGroupAsManagerStatus: ActionStatus.None,
    updateLaborServiceConfigurationForUserGroupAsManagerStatus: ActionStatus.None,
    deleteConfigurationForUserGroupAsManagerStatus: ActionStatus.None,
    markTimeEntryAsApprovedStatus: ActionStatus.None,
    markTimeEntryAsRejectedStatus: ActionStatus.None,
    updateAnnualNumbersOfHoursStatus: ActionStatus.None,
  };
}

export const useLaborServicesManagementStore = defineStore('manageLaborService', {
  state: (): LaborServicesManagementState => initialState(),
  actions: {

    // -- State management

    updateYear(year: number): Promise<void> {
      this.selectedYear = year;

      return Promise.resolve();
    },

    updateSearchForHandledTimeEntries(search: SearchTerm | null): Promise<void> {
      this.searchForHandledTimeEntries = search;

      return this.getHandledTimeEntriesAsManager();
    },

    increaseLimitForHandledTimeEntries(): Promise<void> {
      this.limitForHandledTimeEntries += 50;

      return this.getHandledTimeEntriesAsManager();
    },

    // -- Queries

    getNewTimeEntriesAsManager(): Promise<void> {
      const query: GetNewTimeEntriesAsManager.GetNewTimeEntriesAsManagerQuery = {};
      const { newTimeEntries } = storeToRefs(this);
      return wrapAction(
        newTimeEntries,
        () => getNewTimeEntriesAsManager(query)
      );
    },

    getHandledTimeEntriesAsManager(): Promise<void> {
      const query: GetHandledTimeEntriesAsManager.GetHandledTimeEntriesAsManagerQuery = {
        year: this.selectedYear,
        search: this.searchForHandledTimeEntries,
        limit: this.limitForHandledTimeEntries,
      };
      const { handledTimeEntries } = storeToRefs(this);
      return wrapAction(
        handledTimeEntries,
        () => getHandledTimeEntriesAsManager(query)
      );
    },

    getConfigurationAsManager(): Promise<void> {
      const query: GetConfigurationAsManager.GetConfigurationAsManagerQuery = {};
      const { configuration } = storeToRefs(this);
      return wrapAction(
        configuration,
        () => getConfigurationAsManager(query)
      );
    },

    getAvailableUserGroupsAsManager(): Promise<void> {
      const query: GetAvailableUserGroupsAsManager.GetAvailableUserGroupsAsManagerQuery = {};
      const { availableUserGroups } = storeToRefs(this);
      return wrapAction(
        availableUserGroups,
        () => getAvailableUserGroupsAsManager(query)
      );
    },

    getSummaryAsManager(): Promise<void> {
      const query: GetSummaryAsManager.GetSummaryAsManagerQuery = {
        summaryYear: this.selectedYear,
      };
      const { summaryEntries } = storeToRefs(this);
      return wrapAction(
        summaryEntries,
        () => getSummaryAsManager(query)
      );
    },

    getSummaryAsCSVAsManager(): Promise<CSV> {
      const query: GetSummaryAsCSVAsManager.GetSummaryAsCSVAsManagerQuery = {
        summaryYear: this.selectedYear,
      };
      const { getSummaryAsCSVAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSummaryAsCSVAsManagerStatus,
        () => getSummaryAsCSVAsManager(query)
      );
    },

    // -- Commands

    createConfigurationForUserGroupAsManager(
      command: CreateConfigurationForUserGroupAsManager.CreateConfigurationForUserGroupAsManagerCommand
    ): Promise<void> {
      const { createConfigurationForUserGroupAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createConfigurationForUserGroupAsManagerStatus,
        () => createConfigurationForUserGroupAsManager(command)
          .then(() => this.getConfigurationAsManager())
      );
    },

    updateLaborServiceConfigurationForUserGroupAsManager(
      command: UpdateLaborServiceConfigurationForUserGroupAsManager.UpdateLaborServiceConfigurationForUserGroupAsManagerCommand
    ): Promise<void> {
      const { updateLaborServiceConfigurationForUserGroupAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateLaborServiceConfigurationForUserGroupAsManagerStatus,
        () => updateLaborServiceConfigurationForUserGroupAsManager(command)
          .then(() => this.getConfigurationAsManager())
      );
    },

    deleteConfigurationForUserGroupAsManager(
      command: DeleteConfigurationForUserGroupAsManager.DeleteConfigurationForUserGroupAsManagerCommand
    ): Promise<void> {
      const { deleteConfigurationForUserGroupAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteConfigurationForUserGroupAsManagerStatus,
        () => deleteConfigurationForUserGroupAsManager(command)
          .then(() => this.getConfigurationAsManager())
      );
    },

    markTimeEntryAsApprovedAsManager(command: MarkTimeEntryAsApprovedAsManager.MarkTimeEntryAsApprovedAsManagerCommand): Promise<void> {
      const { markTimeEntryAsApprovedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTimeEntryAsApprovedStatus,
        () => markTimeEntryAsApprovedAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getNewTimeEntriesAsManager())
      );
    },

    markTimeEntryAsRejectedAsManager(command: MarkTimeEntryAsRejectedAsManager.MarkTimeEntryAsRejectedAsManagerCommand): Promise<void> {
      const { markTimeEntryAsRejectedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTimeEntryAsRejectedStatus,
        () => markTimeEntryAsRejectedAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getNewTimeEntriesAsManager())
      );
    },

    updateAnnualNumbersOfHoursAsManager(
      command: UpdateAnnualNumbersOfHoursAsManager.UpdateAnnualNumbersOfHoursAsManagerCommand
    ): Promise<void> {
      const { updateAnnualNumbersOfHoursStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAnnualNumbersOfHoursStatus,
        () => updateAnnualNumbersOfHoursAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getConfigurationAsManager())
      );
    },

  },
});
