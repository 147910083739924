import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV } from '@/types';
import { UpdateNameOfFacilityAsManagerCommand, Facility, DeleteFacilityAsManagerCommand, UpdateOrderOfFacilitiesOfFarmAsManagerCommand, UpdateSpacesOfFacilityAsManagerCommand, UpdateOpeningHoursOfFacilityAsManagerCommand, GetFacilityBlockersForFacilityAsManagerQuery, GetFacilityBlockersQueryResponse, CreateFacilityBlockersAsManagerCommand, DeleteFacilityBlockerAsManagerCommand, UpdateReasonOfFacilityBlockerAsManagerCommand, UpdateTimeRangeOfFacilityBlockerAsManagerCommand, CreateFacilityAsManagerCommand, UpdateVisibilityOfFacilityAsManagerCommand, DeleteFacilityBlockersAsManagerCommand, GetFacilityBlockersForFacilityAsCsvAsManagerQuery, OverlappingFacilityEntries, GetOverlappingFacilityEntriesAsManagerQuery, UpdateCalendarIntervalHeightOfFacilityAsManagerCommand } from './types';

// -- Queries

export function getFacilities(query: FarmAndUserProperties): Promise<Facility[]> {
  const url = `${apiUrl}/api/facilities/get-facilities-as-manager-query`;
  return performApiRequest<Facility[]>({ url, method: 'POST', data: query });
}

export function getFacilityBlockersForFacility(
  query: GetFacilityBlockersForFacilityAsManagerQuery & FarmAndUserProperties
): Promise<GetFacilityBlockersQueryResponse> {
  const url = `${apiUrl}/api/facilities/get-facility-blockers-for-facility-as-manager-query`;
  return performApiRequest<GetFacilityBlockersQueryResponse>({ url, method: 'POST', data: query });
}

export function getFacilityBlockersForFacilityAsCsv(
  query: GetFacilityBlockersForFacilityAsCsvAsManagerQuery & FarmAndUserProperties
): Promise<CSV> {
  const url = `${apiUrl}/api/facilities/get-facility-blockers-for-facility-as-csv-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

export function getOverlappingFacilityEntries(
  query: GetOverlappingFacilityEntriesAsManagerQuery & FarmAndUserProperties
): Promise<OverlappingFacilityEntries> {
  const url = `${apiUrl}/api/facilities/get-overlapping-facility-entries-as-manager-query`;
  return performApiRequest<OverlappingFacilityEntries>({ url, method: 'POST', data: query });
}

// -- Commands

export function createFacility(command: CreateFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/create-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateNameOfFacility(command: UpdateNameOfFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-name-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateSpacesOfFacility(command: UpdateSpacesOfFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-spaces-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOpeningHoursOfFacility(command: UpdateOpeningHoursOfFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-opening-hours-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteFacility(command: DeleteFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/delete-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOrderOfFacilitiesOfFarm(
  command: UpdateOrderOfFacilitiesOfFarmAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-order-of-facilities-of-farm-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createFacilityBlockers(command: CreateFacilityBlockersAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/create-facility-blockers-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateReasonOfFacilityBlocker(
  command: UpdateReasonOfFacilityBlockerAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-reason-of-facility-blocker-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTimeRangeOfFacilityBlocker(
  command: UpdateTimeRangeOfFacilityBlockerAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-time-range-of-facility-blocker-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteFacilityBlocker(command: DeleteFacilityBlockerAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/delete-facility-blocker-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteFacilityBlockers(command: DeleteFacilityBlockersAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/delete-facility-blockers-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateVisibilityOfFacility(command: UpdateVisibilityOfFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-visibility-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateCalendarIntervalHeightOfFacilityAsManager(
  command: UpdateCalendarIntervalHeightOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-calendar-interval-height-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
