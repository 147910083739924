import { RouteConfig, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import SettlementTabs from './components/settlement-tabs.vue';
import SettlementsForMonth from './components/settlements-for-month.vue';
import SpecialBookings from './components/special-bookings.vue';
import SettlementConfiguration from './components/settlement-configuration.vue';
import PaymentReceipts from './components/payment-receipts.vue';
import SettlementsForecast from './components/settlements-forecast.vue';

const defaultMeta: RouteMeta = {
  title: 'Abrechnung',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.SETTLEMENT,
  requiresPermission: FarmManagerPermission.SETTLEMENT_READ,
};

export const settlementManagementRoutes: RouteConfig[] = [
  {
    path: 'abrechnung',
    component: SettlementTabs,
    children: [
      {
        name: 'settlement-management',
        path: '',
        redirect: { name: 'settlement-special-bookings' },
      },
      {
        name: 'settlement-special-bookings',
        path: 'sonderbuchungen',
        component: SpecialBookings,
        meta: defaultMeta,
      },
      {
        name: 'settlement-settlements-for-month',
        path: 'abrechnung',
        component: SettlementsForMonth,
        meta: defaultMeta,
      },
      {
        name: 'settlement-payment-receipt',
        path: 'zahlungseingang',
        component: PaymentReceipts,
        meta: defaultMeta,
      },
      {
        name: 'settlement-settlement-forecast',
        path: 'prognose',
        component: SettlementsForecast,
        meta: defaultMeta,
      },
      {
        name: 'settlement-configuration',
        path: 'konfiguration',
        component: SettlementConfiguration,
        meta: defaultMeta,
      },
    ],
  },
];
