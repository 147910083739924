import { Country, Currency, FarmId, Feature, Locale, Name, RegistrationCode } from '@/types';
import { Command, CommandWithFiles, Query } from '@/application/types';

// -- Queries

export namespace GetFarmsAsAdmin {
  export interface GetFarmsAsAdminQuery extends Query {
    search: string | null;
    features: Feature[] | null;
    limit: number;
    sortBy: SortBy;
  }

  export enum SortBy {
    NAME = 'NAME',
    NUMBER_OF_USERS = 'NUMBER_OF_USERS',
  }

  export interface Farms {
    totalCount: number;
    farms: Farm[];
  }

  export interface Farm {
    farmId: FarmId;
    name: string;
    alias: string;
    currency: Currency;
    locale: Locale;
    defaultCountryForAddresses: Country;
    enabledFeatures: Feature[];
    logoHash: string;
  }
}

// -- Commands

export namespace CreateFarmAsAdmin {
  export interface CreateFarmAsAdminWorkflow extends Command {
    name: string;
    registrationCode: RegistrationCode;
    currency: Currency;
    locale: Locale;
    defaultCountryForAddresses: Country;
    enabledFeatures: Feature[];
    isDemoDataGenerationRequested: boolean;
    farmManagerUserId: string;
    farmManagerEmailAddress: string;
    farmManagerName: Name;
  }
}

export namespace UpdateFarmAsAdmin {
  export interface UpdateFarmAsAdminCommand extends Command {
    targetFarmId: FarmId;
    name: string;
    currency: Currency;
    locale: Locale;
    defaultCountryForAddresses: Country;
    enabledFeatures: Feature[];
  }
}

export namespace DeleteFarmAsAdmin {
  export interface DeleteFarmAsAdminCommand extends Command {
    targetFarmId: FarmId;
  }
}

export namespace UpdateFarmLogoAsAdmin {
  export interface UpdateFarmLogoAsAdminCommand extends CommandWithFiles {
    body: {
      targetFarmId: FarmId
    };
    files: {
      logoFile: Blob;
    }
  }
}
