import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash-es/merge';
import validator from 'validator';
import { getMobileAppVersion } from '@/application/app-device-info/device-information';
import { ActionStatus, CommandWithFiles, FileResponse } from '@/application/types';
import type { APIResource } from '@/infrastructure';
import { dataFromJSON, objectToJSON } from './helper';

function transformRequest(data: object): unknown {
  // Catch calls of transform function with unexpected payload (string|undefined) to avoid double-encoding.
  if (typeof data !== 'object') {
    return data;
  }
  return objectToJSON(data);
}

function transformRequestWithFiles(command: CommandWithFiles): unknown {
  // Catch calls of transform function with unexpected payload (string|undefined) to avoid double-encoding.
  if (typeof command !== 'object') {
    return command;
  }
  const data = new FormData();
  data.append('body', objectToJSON(command.body));
  Object.keys(command.files).forEach((key) => {
    const file = command.files[key];
    // We need to filter files with name `body` as they otherwise would overwrite the other JSON serialized content we want to send
    if (key !== 'body' && file) {
      data.append(key, file);
    }
  });

  return data;
}

function transformResponse(data: any): unknown {
  return typeof data === 'string'
    && data.length > 0
    && validator.isJSON(data)
    ? dataFromJSON(data)
    : data;
}

function defaultHeaders(): Record<string, string> {
  const mobileAppVersion = getMobileAppVersion();
  const headers = {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
  if (!mobileAppVersion) {
    return headers;
  }

  return {
    ...headers,
    'X-MOBILE-APP-VERSION': mobileAppVersion,
  };
}

function formDataHeaders(): Record<string, string> {
  const mobileAppVersion = getMobileAppVersion();
  const headers = {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'multipart/form-data',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
  if (!mobileAppVersion) {
    return headers;
  }

  return {
    ...headers,
    'X-MOBILE-APP-VERSION': mobileAppVersion,
  };
}

function generateTrialRunHeader(isTrialRun: boolean): AxiosRequestConfig {
  return (isTrialRun)
    ? { headers: { 'X-TRIAL-RUN': true } }
    : {};
}

function buildRequest(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: defaultHeaders(),
    transformRequest: [transformRequest],
    transformResponse: [transformResponse],
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

function buildRequestWithFiles(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: formDataHeaders(),
    transformRequest: [transformRequestWithFiles],
    transformResponse: [transformResponse],
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

function buildRequestForBinary(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: {
      ...defaultHeaders(),
      ...config.headers,
    },
    transformRequest,
    withCredentials: true,
    responseType: 'blob',
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

export async function performApiRequest<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequest(config, isTrialRun))
    .then((response) => response.data);
}

export async function performApiRequestWithFiles<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequestWithFiles(config, isTrialRun))
    .then((response) => response.data);
}

export async function performUnauthorizedApiRequest<T>(request: AxiosRequestConfig): Promise<T> {
  return axios.request(request)
    .then((response) => response.data);
}

export async function performApiRequestForFile(config: AxiosRequestConfig, isTrialRun = false): Promise<FileResponse> {
  return axios.request(buildRequestForBinary(config, isTrialRun))
    .then((response) => ({
      data: response.data,
      contentType: response.headers['content-type'],
    }));
}

export async function requestImageAsBlob(url: string): Promise<Blob> {
  return axios.get(url, {
    responseType: 'blob',
  })
    .then((response) => response.data);
}

export function initialAPIResource<T>(): APIResource<T> {
  return {
    value: null,
    status: ActionStatus.None,
  };
}
