import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { TokenStorage } from '@/application/authentication/token-storage';
import { CSV, Limit, Persona } from '@/types';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { UpdateAbilityOfUserToRegisterForRidingLessonsAsManager, updateAbilityOfUserToRegisterForRidingLessonsAsManager } from '@/feature/riding-lessons';
import { GetGroupsAsManager, getGroupsAsManager, GetUsersForSelectionAsManager, getUsersForSelectionAsManager, getRegistrationConfigurationAsManager, GetRegistrationConfigurationAsManager, createGroupAsManager, CreateGroupAsManager, updateGroupNameAsManager, UpdateGroupNameAsManager, updateGroupUsersAsManager, UpdateGroupUsersAsManager, updateGroupsForUserAsManager, UpdateGroupsForUserAsManager, updateRegistrationConfigurationAsManager, UpdateRegistrationConfigurationAsManager, GetUsersAsManager, getUsersAsManager, GetUserForImpersonationAsManager, GetUsersInCSVFormatAsManager, getUserForImpersonationAsManager, getUsersInCSVFormatAsManager, EnableUserAsManager, DisableUserAsManager, RequestImpersonationAsManager, UpdateUserPasswordAsManager, InviteUserAsManager, UpdateVisibilityOfHorsesViewAsManager, RemoveUserFromFarmAsManager, UpdateUserRoleAsManager, UpdateEnabledPermissionsOfUserAsManager, enableUserAsManager, disableUserAsManager, removeUserFromFarmAsManager, requestImpersonationAsManager, updateUserPasswordAsManager, inviteUserAsManager, updateUserRoleAsManager, updateEnabledPermissionsOfUserAsManager, updateVisibilityOfHorsesViewOfUserAsManager } from '@/feature/user-management';

interface UserManagementState {
  users: APIResource<GetUsersAsManager.Users>;
  limitForUsers: Limit;
  groups: APIResource<GetGroupsAsManager.Group[]>;
  registrationConfiguration: APIResource<GetRegistrationConfigurationAsManager.RegistrationConfiguration>;
  search: string | null;
  selectedPersona: Persona | null;
  usersForSelection: APIResource<GetUsersForSelectionAsManager.User[]>;

  createGroupAsManagerStatus: ActionStatus;
  updateGroupNameAsManagerStatus: ActionStatus;
  updateGroupUsersAsManagerStatus: ActionStatus;
  updateGroupsForUserAsManagerStatus: ActionStatus;
  updateRegistrationConfigurationAsManagerStatus: ActionStatus;
  updateUserPasswordAsManagerStatus: ActionStatus;
  inviteUserAsManagerStatus: ActionStatus;
  getUserForImpersonationAsManagerStatus: ActionStatus;
  removeUserFromFarmAsManagerStatus: ActionStatus;
  enableUserAsManagerStatus: ActionStatus;
  disableUserAsManagerStatus: ActionStatus;
  requestImpersonationAsManagerStatus: ActionStatus;
  getUsersInCSVFormatAsManagerStatus: ActionStatus;
  updateUserRoleAsManagerStatus: ActionStatus;
  updateEnabledPermissionsOfUserAsManagerStatus: ActionStatus;
  updateVisibilityOfHorsesViewOfUserAsManagerStatus: ActionStatus;
  updateAbilityOfUserToRegisterForRidingLessonsAsManagerStatus: ActionStatus;
}

function initialState(): UserManagementState {
  return {
    users: initialAPIResource(),
    limitForUsers: 50,
    groups: initialAPIResource(),
    registrationConfiguration: initialAPIResource(),
    search: null,
    selectedPersona: null,
    usersForSelection: initialAPIResource(),

    createGroupAsManagerStatus: ActionStatus.None,
    updateGroupNameAsManagerStatus: ActionStatus.None,
    updateGroupUsersAsManagerStatus: ActionStatus.None,
    updateGroupsForUserAsManagerStatus: ActionStatus.None,
    removeUserFromFarmAsManagerStatus: ActionStatus.None,
    updateRegistrationConfigurationAsManagerStatus: ActionStatus.None,
    updateUserPasswordAsManagerStatus: ActionStatus.None,
    inviteUserAsManagerStatus: ActionStatus.None,
    getUserForImpersonationAsManagerStatus: ActionStatus.None,
    enableUserAsManagerStatus: ActionStatus.None,
    disableUserAsManagerStatus: ActionStatus.None,
    requestImpersonationAsManagerStatus: ActionStatus.None,
    getUsersInCSVFormatAsManagerStatus: ActionStatus.None,
    updateUserRoleAsManagerStatus: ActionStatus.None,
    updateEnabledPermissionsOfUserAsManagerStatus: ActionStatus.None,
    updateVisibilityOfHorsesViewOfUserAsManagerStatus: ActionStatus.None,
    updateAbilityOfUserToRegisterForRidingLessonsAsManagerStatus: ActionStatus.None,
  };
}

export const useUserManagementStore = defineStore('userManagement', {
  state: (): UserManagementState => initialState(),
  actions: {

    // -- State management

    updateSearch(search: string | null): Promise<void> {
      this.search = search;

      return this.getUsersAsManager();
    },

    updateSelectedPersona(persona: Persona | null): Promise<void> {
      this.selectedPersona = persona;

      return this.getUsersAsManager();
    },

    increaseLimitForUsers(): Promise<void> {
      this.limitForUsers += 50;

      return this.getUsersAsManager();
    },

    // -- Queries

    getUsersAsManager(): Promise<void> {
      const query: GetUsersAsManager.GetUsersAsManagerQuery = {
        search: this.search,
        persona: this.selectedPersona,
        limit: this.limitForUsers,
      };
      const { users } = storeToRefs(this);
      return wrapAction(
        users,
        () => getUsersAsManager(query)
      );
    },

    getGroupsAsManager(): Promise<void> {
      const query: GetGroupsAsManager.GetGroupsAsManagerQuery = {};
      const { groups } = storeToRefs(this);
      return wrapAction(
        groups,
        () => getGroupsAsManager(query)
      );
    },

    getRegistrationConfigurationAsManager(): Promise<void> {
      const query: GetRegistrationConfigurationAsManager.GetRegistrationConfigurationAsManagerQuery = {};
      const { registrationConfiguration } = storeToRefs(this);
      return wrapAction(
        registrationConfiguration,
        () => getRegistrationConfigurationAsManager(query)
      );
    },

    getUserForImpersonationAsManager(query: GetUserForImpersonationAsManager.GetUserForImpersonationAsManagerQuery): Promise<void> {
      const { getUserForImpersonationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserForImpersonationAsManagerStatus,
        () => getUserForImpersonationAsManager(query)
          .then(() => TokenStorage.setImpersonateUserFlag())
      );
    },

    getUsersInCSVFormatAsManager(): Promise<CSV> {
      const query: GetUsersInCSVFormatAsManager.GetUsersInCSVFormatAsManagerQuery = {
        search: this.search,
        persona: this.selectedPersona,
      };
      const { getUsersInCSVFormatAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersInCSVFormatAsManagerStatus,
        () => getUsersInCSVFormatAsManager(query)
      );
    },

    getUsersForSelectionAsManager(): Promise<void> {
      const query: GetUsersForSelectionAsManager.GetUsersForSelectionAsManagerQuery = {};
      const { usersForSelection } = storeToRefs(this);
      return wrapAction(
        usersForSelection,
        () => getUsersForSelectionAsManager(query)
      );
    },

    // -- Commands

    enableUserAsManager(command: EnableUserAsManager.EnableUserAsManagerCommand): Promise<void> {
      const { enableUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableUserAsManagerStatus,
        () => enableUserAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    disableUserAsManager(command: DisableUserAsManager.DisableUserAsManagerCommand): Promise<void> {
      const { disableUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableUserAsManagerStatus,
        () => disableUserAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    removeUserFromFarmAsManager(command: RemoveUserFromFarmAsManager.RemoveUserFromFarmAsManagerWorkflow): Promise<void> {
      const { removeUserFromFarmAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeUserFromFarmAsManagerStatus,
        () => removeUserFromFarmAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    requestImpersonationAsManager(command: RequestImpersonationAsManager.RequestImpersonationAsManagerCommand): Promise<void> {
      const { requestImpersonationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestImpersonationAsManagerStatus,
        () => requestImpersonationAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    createGroupAsManager(command: CreateGroupAsManager.CreateGroupAsManagerCommand): Promise<void> {
      const { createGroupAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createGroupAsManagerStatus,
        () => createGroupAsManager(command)
          .then(() => this.getGroupsAsManager())
      );
    },

    updateGroupNameAsManager(command: UpdateGroupNameAsManager.UpdateGroupNameAsManagerCommand): Promise<void> {
      const { updateGroupNameAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGroupNameAsManagerStatus,
        () => updateGroupNameAsManager(command)
          .then(() => this.getGroupsAsManager())
      );
    },

    updateGroupUsersAsManager(command: UpdateGroupUsersAsManager.UpdateGroupUsersAsManagerCommand): Promise<void> {
      const { updateGroupUsersAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGroupUsersAsManagerStatus,
        () => updateGroupUsersAsManager(command)
          .then(() => this.getGroupsAsManager())
      );
    },

    updateGroupsForUserAsManager(command: UpdateGroupsForUserAsManager.UpdateGroupsForUserAsManagerCommand): Promise<void> {
      const { updateGroupsForUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGroupsForUserAsManagerStatus,
        () => updateGroupsForUserAsManager(command)
          .then(() => this.getGroupsAsManager())
          .then(() => this.getUsersAsManager())
      );
    },

    updateRegistrationConfigurationAsManager(
      command: UpdateRegistrationConfigurationAsManager.UpdateRegistrationConfigurationAsManagerCommand
    ): Promise<void> {
      const { updateRegistrationConfigurationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateRegistrationConfigurationAsManagerStatus,
        () => updateRegistrationConfigurationAsManager(command)
          .then(() => this.getRegistrationConfigurationAsManager())
      );
    },

    updateUserPasswordAsManager(command: UpdateUserPasswordAsManager.UpdateUserPasswordAsManagerCommand): Promise<void> {
      const { updateUserPasswordAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserPasswordAsManagerStatus,
        () => updateUserPasswordAsManager(command)
      );
    },

    inviteUserAsManager(command: InviteUserAsManager.InviteUserAsManagerCommand): Promise<void> {
      const { inviteUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUserAsManagerStatus,
        () => inviteUserAsManager(command)
      );
    },

    updateUserRoleAsManager(command: UpdateUserRoleAsManager.UpdateUserRoleAsManagerWorkflow): Promise<void> {
      const { updateUserRoleAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserRoleAsManagerStatus,
        () => updateUserRoleAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    updateEnabledPermissionsOfUserAsManager(
      command: UpdateEnabledPermissionsOfUserAsManager.UpdateEnabledPermissionsOfUserAsManagerWorkflow
    ): Promise<void> {
      const { updateEnabledPermissionsOfUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEnabledPermissionsOfUserAsManagerStatus,
        () => updateEnabledPermissionsOfUserAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    updateVisibilityOfHorsesViewOfUserAsManager(
      command: UpdateVisibilityOfHorsesViewAsManager.UpdateVisibilityOfHorsesViewAsManagerCommand
    ): Promise<void> {
      const { updateVisibilityOfHorsesViewOfUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateVisibilityOfHorsesViewOfUserAsManagerStatus,
        () => updateVisibilityOfHorsesViewOfUserAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

    updateAbilityOfUserToRegisterForRidingLessonsAsManager(
      command: UpdateAbilityOfUserToRegisterForRidingLessonsAsManager.UpdateAbilityOfUserToRegisterForRidingLessonsAsManagerCommand
    ): Promise<void> {
      const { updateAbilityOfUserToRegisterForRidingLessonsAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAbilityOfUserToRegisterForRidingLessonsAsManagerStatus,
        () => updateAbilityOfUserToRegisterForRidingLessonsAsManager(command)
          .then(() => this.getUsersAsManager())
      );
    },

  },
});
