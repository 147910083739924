import { defineStore, storeToRefs } from 'pinia';
import { wrapAction } from '@/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { GetFarmAnalyticsAsManager, getFarmAnalyticsAsManager } from '@/feature/analytics';

interface AnalyticsState {
  analytics: APIResource<GetFarmAnalyticsAsManager.AnalyticsData>;
}

function initialState(): AnalyticsState {
  return {
    analytics: initialAPIResource(),
  };
}

export const useAnalyticsStore = defineStore('analytics', {
  state: (): AnalyticsState => initialState(),
  actions: {

    // -- Queries

    getFarmAnalyticsAsManager(): Promise<void> {
      const { analytics } = storeToRefs(this);
      return wrapAction(
        analytics,
        () => getFarmAnalyticsAsManager({})
      );
    },

  },
});
