import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { CSV } from '@/types';
import { CreateTimeEntry, GetNewTimeEntriesAsManager, CreateConfigurationForUserGroupAsManager, UpdateLaborServiceConfigurationForUserGroupAsManager, DeleteConfigurationForUserGroupAsManager, MarkTimeEntryAsApprovedAsManager, MarkTimeEntryAsRejectedAsManager, UpdateAnnualNumbersOfHoursAsManager, GetConfigurationAsManager, GetAvailableUserGroupsAsManager, GetSummaryAsManager, GetSummaryAsCSVAsManager, GetSummaryForUser, GetHandledTimeEntriesAsManager } from './types';

// -- Queries

export function getNewTimeEntriesAsManager(
  query: GetNewTimeEntriesAsManager.GetNewTimeEntriesAsManagerQuery
): Promise<GetNewTimeEntriesAsManager.TimeEntry[]> {
  const url = `${apiUrl}/api/labor-service/get-new-time-entries-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHandledTimeEntriesAsManager(
  query: GetHandledTimeEntriesAsManager.GetHandledTimeEntriesAsManagerQuery
): Promise<GetHandledTimeEntriesAsManager.HandledTimeEntries> {
  const url = `${apiUrl}/api/labor-service/get-handled-time-entries-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getConfigurationAsManager(
  query: GetConfigurationAsManager.GetConfigurationAsManagerQuery
): Promise<GetConfigurationAsManager.Configuration> {
  const url = `${apiUrl}/api/labor-service/get-configuration-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getAvailableUserGroupsAsManager(
  query: GetAvailableUserGroupsAsManager.GetAvailableUserGroupsAsManagerQuery
): Promise<GetAvailableUserGroupsAsManager.UserGroup[]> {
  const url = `${apiUrl}/api/labor-service/get-available-user-groups-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSummaryAsManager(
  query: GetSummaryAsManager.GetSummaryAsManagerQuery
): Promise<GetSummaryAsManager.SummaryEntry[]> {
  const url = `${apiUrl}/api/labor-service/get-summary-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSummaryAsCSVAsManager(
  query: GetSummaryAsCSVAsManager.GetSummaryAsCSVAsManagerQuery
): Promise<CSV> {
  const url = `${apiUrl}/api/labor-service/get-summary-as-csv-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSummaryForUser(
  query: GetSummaryForUser.GetSummaryForUserQuery
): Promise<GetSummaryForUser.Summary> {
  const url = `${apiUrl}/api/labor-service/get-summary-for-user-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createTimeEntry(
  command: CreateTimeEntry.CreateTimeEntryCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/create-time-entry-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createConfigurationForUserGroupAsManager(
  command: CreateConfigurationForUserGroupAsManager.CreateConfigurationForUserGroupAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/create-configuration-for-user-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateLaborServiceConfigurationForUserGroupAsManager(
  command: UpdateLaborServiceConfigurationForUserGroupAsManager.UpdateLaborServiceConfigurationForUserGroupAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/update-labor-service-configuration-for-user-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteConfigurationForUserGroupAsManager(
  command: DeleteConfigurationForUserGroupAsManager.DeleteConfigurationForUserGroupAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/delete-configuration-for-user-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function markTimeEntryAsApprovedAsManager(
  command: MarkTimeEntryAsApprovedAsManager.MarkTimeEntryAsApprovedAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/mark-time-entry-as-approved-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function markTimeEntryAsRejectedAsManager(
  command: MarkTimeEntryAsRejectedAsManager.MarkTimeEntryAsRejectedAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/mark-time-entry-as-rejected-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateAnnualNumbersOfHoursAsManager(
  command: UpdateAnnualNumbersOfHoursAsManager.UpdateAnnualNumbersOfHoursAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/labor-service/update-annual-number-of-hours-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
