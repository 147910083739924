import { attachFarmAndUserProperties, attachUserProperty } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { CSV } from '@/types';
import { GetGroupsAsManager, GetRegistrationConfigurationAsManager, GetUsersForSelectionAsManager, CreateGroupAsManager, UpdateGroupNameAsManager, UpdateGroupUsersAsManager, UpdateGroupsForUserAsManager, UpdateRegistrationConfigurationAsManager, GetGlobalUsersAsAdmin, GetUsersAsManager, GetUserForImpersonationAsManager, GetUsersInCSVFormatAsManager, EnableUserAsManager, DisableUserAsManager, RemoveUserFromFarmAsManager, RequestImpersonationAsManager, UpdateUserPasswordAsManager, InviteUserAsManager, UpdateVisibilityOfHorsesViewAsManager, UpdateUserRoleAsManager, UpdateEnabledPermissionsOfUserAsManager } from './types';

// -- Queries

export function getGroupsAsManager(
  query: GetGroupsAsManager.GetGroupsAsManagerQuery
): Promise<GetGroupsAsManager.Group[]> {
  const url = `${apiUrl}/api/user-management/get-groups-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getUsersForSelectionAsManager(
  query: GetUsersForSelectionAsManager.GetUsersForSelectionAsManagerQuery
): Promise<GetUsersForSelectionAsManager.User[]> {
  const url = `${apiUrl}/api/user-management/get-users-for-selection-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getRegistrationConfigurationAsManager(
  query: GetRegistrationConfigurationAsManager.GetRegistrationConfigurationAsManagerQuery
): Promise<GetRegistrationConfigurationAsManager.RegistrationConfiguration> {
  const url = `${apiUrl}/api/user-management/get-registration-configuration-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getGlobalUsersAsAdmin(
  query: GetGlobalUsersAsAdmin.GetGlobalUsersAsAdminQuery
): Promise<GetGlobalUsersAsAdmin.Response> {
  const url = `${apiUrl}/api/user-management/get-global-users-as-admin-query`;
  return performApiRequest({ url, method: 'POST', data: attachUserProperty(query) });
}

export function getUsersAsManager(query: GetUsersAsManager.GetUsersAsManagerQuery): Promise<GetUsersAsManager.Users> {
  const url = `${apiUrl}/api/user-management/get-users-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getUserForImpersonationAsManager(
  query: GetUserForImpersonationAsManager.GetUserForImpersonationAsManagerQuery
): Promise<void> {
  const url = `${apiUrl}/api/user-management/get-user-for-impersonation-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getUsersInCSVFormatAsManager(query: GetUsersInCSVFormatAsManager.GetUsersInCSVFormatAsManagerQuery): Promise<CSV> {
  const url = `${apiUrl}/api/user-management/get-users-in-csv-format-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createGroupAsManager(
  command: CreateGroupAsManager.CreateGroupAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/user-management/create-group-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateGroupNameAsManager(
  command: UpdateGroupNameAsManager.UpdateGroupNameAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-group-name-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateGroupUsersAsManager(
  command: UpdateGroupUsersAsManager.UpdateGroupUsersAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-group-users-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateGroupsForUserAsManager(
  command: UpdateGroupsForUserAsManager.UpdateGroupsForUserAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-groups-for-user-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateRegistrationConfigurationAsManager(
  command: UpdateRegistrationConfigurationAsManager.UpdateRegistrationConfigurationAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-registration-configuration-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function enableUserAsManager(command: EnableUserAsManager.EnableUserAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/enable-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function disableUserAsManager(command: DisableUserAsManager.DisableUserAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/disable-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function removeUserFromFarmAsManager(command: RemoveUserFromFarmAsManager.RemoveUserFromFarmAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/user-management/remove-user-from-farm-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function requestImpersonationAsManager(command: RequestImpersonationAsManager.RequestImpersonationAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/request-impersonation-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateUserPasswordAsManager(command: UpdateUserPasswordAsManager.UpdateUserPasswordAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-user-password-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function inviteUserAsManager(command: InviteUserAsManager.InviteUserAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/user-management/invite-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateUserRoleAsManager(command: UpdateUserRoleAsManager.UpdateUserRoleAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-user-role-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateEnabledPermissionsOfUserAsManager(
  command: UpdateEnabledPermissionsOfUserAsManager.UpdateEnabledPermissionsOfUserAsManagerWorkflow
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-enabled-permissions-of-user-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateVisibilityOfHorsesViewOfUserAsManager(
  command: UpdateVisibilityOfHorsesViewAsManager.UpdateVisibilityOfHorsesViewAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/user-management/update-visibility-of-horses-view-of-user-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
