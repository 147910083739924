import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetMedicalRecordsForHorseAsManager, CreateVaccinationAsManager, CreateHorseWeightEntryAsManager, CreateHorseDrugEntryAsManager, CreateHorseFarrierAppointmentAsManager, CreateHorseWormingTreatmentAsManager, CreateHorseWormingTreatmentForMultipleHorsesAsManager, GetDataForVaccinationCreationAsManager, GetHorsesAsManager, GetWormingTreatmentsAsManager, DeleteHorseWormingTreatmentAsManager, GetHorseDrugsAsManager, DeleteHorseDrugEntryAsManager, GetHorseFarrierAppointmentsAsManager, DeleteHorseFarrierAppointmentAsManager, GetHorseWeightEntriesAsManager, DeleteHorseWeightEntryAsManager, GetVaccinationsAsManager, DeleteVaccinationAsManager, CreateVaccinationsAsManager } from './types';

// -- Queries

export function getMedicalRecordsForHorseAsManager(
  query: GetMedicalRecordsForHorseAsManager.GetMedicalRecordsForHorseAsManagerQuery
): Promise<GetMedicalRecordsForHorseAsManager.MedicalRecords> {
  const url = `${apiUrl}/api/medical-records/get-medical-records-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getDataForVaccinationCreation(
  query: GetDataForVaccinationCreationAsManager.GetDataForVaccinationCreationAsManagerQuery
): Promise<GetDataForVaccinationCreationAsManager.DataForVaccinationCreation> {
  const url = `${apiUrl}/api/medical-records/get-data-for-vaccination-creation-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorsesAsManager(query: GetHorsesAsManager.GetHorsesAsManagerQuery): Promise<GetHorsesAsManager.Horse[]> {
  const url = `${apiUrl}/api/medical-records/get-horses-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getWormingTreatmentsAsManager(
  query: GetWormingTreatmentsAsManager.GetWormingTreatmentsAsManagerQuery
): Promise<GetWormingTreatmentsAsManager.HorseWithWormingTreatments[]> {
  const url = `${apiUrl}/api/medical-records/get-worming-treatments-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseDrugsAsManager(
  query: GetHorseDrugsAsManager.GetHorseDrugsAsManagerQuery
): Promise<GetHorseDrugsAsManager.HorseWithHorseDrugs[]> {
  const url = `${apiUrl}/api/medical-records/get-horse-drugs-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseFarrierAppointmentsAsManager(
  query: GetHorseFarrierAppointmentsAsManager.GetHorseFarrierAppointmentsAsManagerQuery
): Promise<GetHorseFarrierAppointmentsAsManager.HorseWithFarrierAppointments[]> {
  const url = `${apiUrl}/api/medical-records/get-horse-farrier-appointments-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseWeightEntriesAsManager(
  query: GetHorseWeightEntriesAsManager.GetHorseWeightEntriesAsManagerQuery
): Promise<GetHorseWeightEntriesAsManager.HorseWithWeightEntries[]> {
  const url = `${apiUrl}/api/medical-records/get-horse-weight-entries-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getVaccinationsAsManager(
  query: GetVaccinationsAsManager.GetVaccinationsAsManagerQuery
): Promise<GetVaccinationsAsManager.Illness[]> {
  const url = `${apiUrl}/api/medical-records/get-vaccinations-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createVaccinationAsManager(command: CreateVaccinationAsManager.CreateVaccinationAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-vaccination-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorseWeightEntryAsManager(
  command: CreateHorseWeightEntryAsManager.CreateHorseWeightEntryAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-weight-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorseDrugEntryAsManager(command: CreateHorseDrugEntryAsManager.CreateHorseDrugEntryAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-drug-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorseFarrierAppointmentAsManager(
  command: CreateHorseFarrierAppointmentAsManager.CreateHorseFarrierAppointmentAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-farrier-appointment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorseWormingTreatmentAsManager(
  command: CreateHorseWormingTreatmentAsManager.CreateHorseWormingTreatmentAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-worming-treatment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorseWormingTreatmentForMultipleHorsesAsManager(
  command: CreateHorseWormingTreatmentForMultipleHorsesAsManager.CreateHorseWormingTreatmentForMultipleHorsesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-worming-treatment-for-multiple-horses-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteHorseWormingTreatmentAsManager(
  command: DeleteHorseWormingTreatmentAsManager.DeleteHorseWormingTreatmentAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-worming-treatment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteHorseDrugEntryAsManager(
  command: DeleteHorseDrugEntryAsManager.DeleteHorseDrugEntryAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-drug-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteHorseFarrierAppointmentAsManager(
  command: DeleteHorseFarrierAppointmentAsManager.DeleteHorseFarrierAppointmentAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-farrier-appointment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteHorseWeightEntryAsManager(
  command: DeleteHorseWeightEntryAsManager.DeleteHorseWeightEntryAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-horse-weight-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteVaccinationAsManager(
  command: DeleteVaccinationAsManager.DeleteVaccinationAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/delete-vaccination-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createVaccinationsAsManager(
  command: CreateVaccinationsAsManager.CreateVaccinationsAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-vaccinations-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
