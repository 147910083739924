import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { AnswerSurvey, CreateSurveyAsManager, DeleteSurveyAnswerAsManager, DeleteSurveyAsManager, GetSurveyDetailsAsManager, GetSurveyForDuplicationAsManager, GetSurveys, GetSurveysAsManager, GetUnansweredSurveysShownOnAppStart, GetUserGroupsAsManager } from './types';

// -- Queries

export function getSurveysAsManager(
  query: GetSurveysAsManager.GetSurveysAsManagerQuery
): Promise<GetSurveysAsManager.Survey[]> {
  const url = `${apiUrl}/api/surveys/get-surveys-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSurveyDetailsAsManager(
  query: GetSurveyDetailsAsManager.GetSurveyDetailsAsManagerQuery
): Promise<GetSurveyDetailsAsManager.Survey> {
  const url = `${apiUrl}/api/surveys/get-survey-details-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSurveyForDuplicationAsManager(
  query: GetSurveyForDuplicationAsManager.GetSurveyForDuplicationAsManagerQuery
): Promise<GetSurveyForDuplicationAsManager.Survey> {
  const url = `${apiUrl}/api/surveys/get-survey-for-duplication-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getUserGroupsAsManager(
  query: GetUserGroupsAsManager.GetUserGroupsAsManagerQuery
): Promise<GetUserGroupsAsManager.UserGroup[]> {
  const url = `${apiUrl}/api/surveys/get-user-groups-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getSurveys(query: GetSurveys.GetSurveysQuery): Promise<GetSurveys.Survey[]> {
  const url = `${apiUrl}/api/surveys/get-surveys-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getUnansweredSurveysShownOnAppStart(
  query: GetUnansweredSurveysShownOnAppStart.GetUnansweredSurveysShownOnAppStartQuery
): Promise<GetUnansweredSurveysShownOnAppStart.Survey[]> {
  const url = `${apiUrl}/api/surveys/get-unanswered-surveys-shown-on-app-start-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createSurveyAsManager(command: CreateSurveyAsManager.CreateSurveyAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/surveys/create-survey-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteSurveyAsManager(command: DeleteSurveyAsManager.DeleteSurveyAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/surveys/delete-survey-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteSurveyAnswerAsManager(command: DeleteSurveyAnswerAsManager.DeleteSurveyAnswerAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/surveys/delete-survey-answer-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function answerSurvey(command: AnswerSurvey.AnswerSurveyCommand): Promise<void> {
  const url = `${apiUrl}/api/surveys/answer-survey-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
