import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CSV, FacilityBlockerId, FacilityId, Date } from '@/types';
import { deleteFacility, updateNameOfFacility, getFacilities, updateOrderOfFacilitiesOfFarm, updateSpacesOfFacility, updateOpeningHoursOfFacility, getFacilityBlockersForFacility, createFacilityBlockers, deleteFacilityBlocker, updateReasonOfFacilityBlocker, updateTimeRangeOfFacilityBlocker, createFacility, updateVisibilityOfFacility, deleteFacilityBlockers, getFacilityBlockersForFacilityAsCsv, getOverlappingFacilityEntries, updateCalendarIntervalHeightOfFacilityAsManager } from './service';
import { UpdateNameOfFacilityAsManagerCommand, DeleteFacilityAsManagerCommand, Facility, UpdateOrderOfFacilitiesOfFarmAsManagerCommand, UpdateSpacesOfFacilityAsManagerCommand, UpdateOpeningHoursOfFacilityAsManagerCommand, FacilityBlocker, GetFacilityBlockersForFacilityAsManagerQuery, CreateFacilityBlockersAsManagerCommand, DeleteFacilityBlockerAsManagerCommand, UpdateReasonOfFacilityBlockerAsManagerCommand, UpdateTimeRangeOfFacilityBlockerAsManagerCommand, CreateFacilityAsManagerCommand, UpdateVisibilityOfFacilityAsManagerCommand, DeleteFacilityBlockersAsManagerCommand, GetFacilityBlockersForFacilityAsCsvAsManagerQuery, OverlappingFacilityEntries, GetOverlappingFacilityEntriesAsManagerQuery, UpdateCalendarIntervalHeightOfFacilityAsManagerCommand } from './types';

interface FacilityManagementState {
  currentFacilityId: FacilityId | null;
  facilities: Facility[];
  facilityBlockers: FacilityBlocker[];
  searchForFacilityBlockers: string | null;
  selectedDateForFacilityBlockers: Date | null;
  limitForFacilityBlockers: number;
  totalCountForFacilityBlockers: number;
  idsOfSelectedFacilityBlockers: FacilityBlockerId[];
  overlappingFacilityEntries: OverlappingFacilityEntries | null;

  getFacilitiesStatus: ActionStatus;
  getFacilityBlockersForFacilityStatus: ActionStatus;
  getFacilityBlockersForFacilityAsCsvStatus: ActionStatus;
  createFacilityStatus: ActionStatus;
  updateNameOfFacilityStatus: ActionStatus;
  updateSpacesOfFacilityStatus: ActionStatus;
  updateOpeningHoursOfFacilityStatus: ActionStatus;
  deleteFacilityStatus: ActionStatus;
  updateOrderOfFacilitiesOfFarmStatus: ActionStatus;
  createFacilityBlockersStatus: ActionStatus;
  updateFacilityBlockerReasonStatus: ActionStatus;
  updateFacilityBlockerTimeRangeStatus: ActionStatus;
  deleteFacilityBlockerStatus: ActionStatus;
  deleteFacilityBlockersStatus: ActionStatus;
  updateVisibilityOfFacilityStatus: ActionStatus;
  getOverlappingFacilityEntriesStatus: ActionStatus;
  updateCalendarIntervalHeightOfFacilityAsManagerStatus: ActionStatus;
}

function initialState(): FacilityManagementState {
  return {
    currentFacilityId: null,
    facilities: [],
    facilityBlockers: [],
    searchForFacilityBlockers: null,
    selectedDateForFacilityBlockers: null,
    limitForFacilityBlockers: 100,
    totalCountForFacilityBlockers: 0,
    idsOfSelectedFacilityBlockers: [],
    overlappingFacilityEntries: null,

    getFacilitiesStatus: ActionStatus.None,
    getFacilityBlockersForFacilityStatus: ActionStatus.None,
    getFacilityBlockersForFacilityAsCsvStatus: ActionStatus.None,
    createFacilityStatus: ActionStatus.None,
    updateNameOfFacilityStatus: ActionStatus.None,
    updateSpacesOfFacilityStatus: ActionStatus.None,
    updateOpeningHoursOfFacilityStatus: ActionStatus.None,
    deleteFacilityStatus: ActionStatus.None,
    updateOrderOfFacilitiesOfFarmStatus: ActionStatus.None,
    createFacilityBlockersStatus: ActionStatus.None,
    updateFacilityBlockerReasonStatus: ActionStatus.None,
    updateFacilityBlockerTimeRangeStatus: ActionStatus.None,
    deleteFacilityBlockerStatus: ActionStatus.None,
    deleteFacilityBlockersStatus: ActionStatus.None,
    updateVisibilityOfFacilityStatus: ActionStatus.None,
    getOverlappingFacilityEntriesStatus: ActionStatus.None,
    updateCalendarIntervalHeightOfFacilityAsManagerStatus: ActionStatus.None,
  };
}

export const useFacilityManagementStore = defineStore('facilityManagement', {
  state: (): FacilityManagementState => initialState(),
  getters: {
    currentFacility: (state: FacilityManagementState): Facility | null =>
      state.facilities.find((facility) => facility.facilityId === state.currentFacilityId) ?? null,
    hasOverlappingFacilityBlockers: (state: FacilityManagementState): boolean =>
      !!state.overlappingFacilityEntries
        && state.overlappingFacilityEntries.facilityBlockers.length > 0,
    hasOverlappingRidingLessons: (state: FacilityManagementState): boolean =>
      !!state.overlappingFacilityEntries
      && state.overlappingFacilityEntries.ridingLessons.length > 0,
    hasFacilityReservationsToDelete: (state: FacilityManagementState): boolean =>
      !!state.overlappingFacilityEntries
      && state.overlappingFacilityEntries.facilityReservationsToDelete.length > 0,

    isGetFacilitiesProcessing: (state: FacilityManagementState): boolean =>
      state.getFacilitiesStatus === ActionStatus.InProgress,
    isGetFacilityBlockersForFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.getFacilityBlockersForFacilityStatus === ActionStatus.InProgress,
    isGetFacilityBlockersForFacilityAsCsvProcessing: (state: FacilityManagementState): boolean =>
      state.getFacilityBlockersForFacilityAsCsvStatus === ActionStatus.InProgress,
    isCreateFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.createFacilityStatus === ActionStatus.InProgress,
    isUpdateNameOfFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.updateNameOfFacilityStatus === ActionStatus.InProgress,
    isUpdateSpacesOfFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.updateSpacesOfFacilityStatus === ActionStatus.InProgress,
    isUpdateOpeningHoursOfFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.updateOpeningHoursOfFacilityStatus === ActionStatus.InProgress,
    isDeleteFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.deleteFacilityStatus === ActionStatus.InProgress,
    isUpdateOrderOfFacilitiesOfFarmProcessing: (state: FacilityManagementState): boolean =>
      state.updateOrderOfFacilitiesOfFarmStatus === ActionStatus.InProgress,
    isCreateFacilityBlockersProcessing: (state: FacilityManagementState): boolean =>
      state.createFacilityBlockersStatus === ActionStatus.InProgress,
    isUpdateFacilityBlockerReasonProcessing: (state: FacilityManagementState): boolean =>
      state.updateFacilityBlockerReasonStatus === ActionStatus.InProgress,
    isUpdateFacilityBlockerTimeRangeProcessing: (state: FacilityManagementState): boolean =>
      state.updateFacilityBlockerTimeRangeStatus === ActionStatus.InProgress,
    isDeleteFacilityBlockerProcessing: (state: FacilityManagementState): boolean =>
      state.deleteFacilityBlockerStatus === ActionStatus.InProgress,
    isDeleteFacilityBlockersProcessing: (state: FacilityManagementState): boolean =>
      state.deleteFacilityBlockersStatus === ActionStatus.InProgress,
    isUpdateVisibilityOfFacilityProcessing: (state: FacilityManagementState): boolean =>
      state.updateVisibilityOfFacilityStatus === ActionStatus.InProgress,
    isGetOverlappingFacilityEntriesProcessing: (state: FacilityManagementState): boolean =>
      state.getOverlappingFacilityEntriesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentFacility(facilityId: FacilityId): Promise<void> {
      this.currentFacilityId = facilityId;

      return this.getFacilities();
    },

    updateSelectedDateForFacilityBlockers(selectedDate: Date | null): Promise<void> {
      this.selectedDateForFacilityBlockers = selectedDate;
      this.idsOfSelectedFacilityBlockers = [];

      return this.getFacilityBlockersForFacility();
    },

    updateSearchForFacilityBlocker(search: string | null): Promise<void> {
      this.searchForFacilityBlockers = search;
      this.idsOfSelectedFacilityBlockers = [];

      return this.getFacilityBlockersForFacility();
    },

    updateIdsOfSelectedFacilityBlockers(idsOfSelectedFacilityBlockers: FacilityBlockerId[]): Promise<void> {
      this.idsOfSelectedFacilityBlockers = idsOfSelectedFacilityBlockers;

      return Promise.resolve();
    },

    increaseFacilityBlockersLimit(): Promise<void> {
      this.limitForFacilityBlockers += 100;

      return this.getFacilityBlockersForFacility();
    },

    resetFacilityBlockers(): void {
      this.facilityBlockers = [];
      this.limitForFacilityBlockers = 100;
      this.totalCountForFacilityBlockers = 0;
      this.searchForFacilityBlockers = null;
      this.selectedDateForFacilityBlockers = null;
      this.idsOfSelectedFacilityBlockers = [];
    },

    // -- Queries

    getFacilities(): Promise<void> {
      const { getFacilitiesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFacilitiesStatus,
        () => getFacilities(attachFarmAndUserProperties({}))
          .then(async (facilities) => {
            this.facilities = facilities;
          })
      );
    },

    getFacilityBlockersForFacility(): Promise<void> {
      const query: GetFacilityBlockersForFacilityAsManagerQuery = {
        facilityId: this.currentFacilityId!,
        search: this.searchForFacilityBlockers,
        date: this.selectedDateForFacilityBlockers,
        limit: this.limitForFacilityBlockers,
        offset: 0,
      };
      const { getFacilityBlockersForFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFacilityBlockersForFacilityStatus,
        () => getFacilityBlockersForFacility(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.facilityBlockers = response.facilityBlockers;
            this.totalCountForFacilityBlockers = response.totalCount;
          })
      );
    },

    getFacilityBlockersForFacilityAsCsv(): Promise<CSV> {
      const query: GetFacilityBlockersForFacilityAsCsvAsManagerQuery = {
        facilityId: this.currentFacilityId!,
        search: this.searchForFacilityBlockers,
        date: this.selectedDateForFacilityBlockers,
        limit: this.limitForFacilityBlockers,
        offset: 0,
      };
      const { getFacilityBlockersForFacilityAsCsvStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFacilityBlockersForFacilityAsCsvStatus,
        () => getFacilityBlockersForFacilityAsCsv(attachFarmAndUserProperties(query))
      );
    },

    getOverlappingFacilityEntries(query: GetOverlappingFacilityEntriesAsManagerQuery): Promise<void> {
      const { getOverlappingFacilityEntriesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOverlappingFacilityEntriesStatus,
        () => getOverlappingFacilityEntries(attachFarmAndUserProperties(query))
          .then(async (overlappingFacilityEntries) => {
            this.overlappingFacilityEntries = overlappingFacilityEntries;
          })
      );
    },

    // -- Commands

    createFacility(command: CreateFacilityAsManagerCommand): Promise<void> {
      const { createFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFacilityStatus,
        () => createFacility(attachFarmAndUserProperties(command))
          .then(() => useAuthenticationStore().getAuthentication())
          .then(() => this.getFacilities())
      );
    },

    updateNameOfFacility(command: UpdateNameOfFacilityAsManagerCommand): Promise<void> {
      const { updateNameOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfFacilityStatus,
        () => updateNameOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateSpacesOfFacility(command: UpdateSpacesOfFacilityAsManagerCommand): Promise<void> {
      const { updateSpacesOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateSpacesOfFacilityStatus,
        () => updateSpacesOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
      );
    },

    updateOpeningHoursOfFacility(command: UpdateOpeningHoursOfFacilityAsManagerCommand): Promise<void> {
      const { updateOpeningHoursOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOpeningHoursOfFacilityStatus,
        () => updateOpeningHoursOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
      );
    },

    deleteFacility(command: DeleteFacilityAsManagerCommand): Promise<void> {
      const { deleteFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteFacilityStatus,
        () => deleteFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateOrderOfFacilitiesOfFarm(command: UpdateOrderOfFacilitiesOfFarmAsManagerCommand): Promise<void> {
      const { updateOrderOfFacilitiesOfFarmStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfFacilitiesOfFarmStatus,
        () => updateOrderOfFacilitiesOfFarm(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
      );
    },

    createFacilityBlockers(command: CreateFacilityBlockersAsManagerCommand): Promise<void> {
      const { createFacilityBlockersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFacilityBlockersStatus,
        () => createFacilityBlockers(attachFarmAndUserProperties(command))
          .then(() => this.getFacilityBlockersForFacility())
      );
    },

    updateFacilityBlockerReason(command: UpdateReasonOfFacilityBlockerAsManagerCommand): Promise<void> {
      const { updateFacilityBlockerReasonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFacilityBlockerReasonStatus,
        () => updateReasonOfFacilityBlocker(attachFarmAndUserProperties(command))
          .then(() => this.getFacilityBlockersForFacility())
      );
    },

    updateFacilityBlockerTimeRange(command: UpdateTimeRangeOfFacilityBlockerAsManagerCommand): Promise<void> {
      const { updateFacilityBlockerTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFacilityBlockerTimeRangeStatus,
        () => updateTimeRangeOfFacilityBlocker(attachFarmAndUserProperties(command))
          .then(() => this.getFacilityBlockersForFacility())
      );
    },

    deleteFacilityBlocker(command: DeleteFacilityBlockerAsManagerCommand): Promise<void> {
      const { deleteFacilityBlockerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteFacilityBlockerStatus,
        () => deleteFacilityBlocker(attachFarmAndUserProperties(command))
          .then(() => this.getFacilityBlockersForFacility())
      );
    },

    deleteFacilityBlockers(command: DeleteFacilityBlockersAsManagerCommand): Promise<void> {
      const { deleteFacilityBlockersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteFacilityBlockersStatus,
        () => deleteFacilityBlockers(attachFarmAndUserProperties(command))
          .then(() => {
            this.idsOfSelectedFacilityBlockers = [];
          })
          .then(() => this.getFacilityBlockersForFacility())
      );
    },

    updateVisibilityOfFacility(command: UpdateVisibilityOfFacilityAsManagerCommand): Promise<void> {
      const { updateVisibilityOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateVisibilityOfFacilityStatus,
        () => updateVisibilityOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateCalendarIntervalHeightOfFacilityAsManager(command: UpdateCalendarIntervalHeightOfFacilityAsManagerCommand): Promise<void> {
      const { updateCalendarIntervalHeightOfFacilityAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCalendarIntervalHeightOfFacilityAsManagerStatus,
        () => updateCalendarIntervalHeightOfFacilityAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getFacilities())
      );
    },

  },
});
