import { render, staticRenderFns } from "./send-link-of-filter-for-plan-dialog.vue?vue&type=template&id=631c071e&scoped=true&"
import script from "./send-link-of-filter-for-plan-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./send-link-of-filter-for-plan-dialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./send-link-of-filter-for-plan-dialog.vue?vue&type=style&index=0&id=631c071e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631c071e",
  null
  
)

export default component.exports