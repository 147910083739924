import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { HorseId, PaddockPlanTimeRangeId } from '@/types';
import { GetPaddockPlanAsManager, CreatePaddockAsManager, UpdatePaddockAsManager, DeletePaddockAsManager, CreateTimeRangeAsManager, UpdateTimeRangeAsManager, DeleteTimeRangeAsManager, AddHorseToTimeRangeAsManager, RemoveHorseFromTimeRangeAsManager, getPaddockPlanAsManager, createPaddockAsManager, updatePaddockAsManager, deletePaddockAsManager, createTimeRangeAsManager, updateTimeRangeAsManager, deleteTimeRangeAsManager, addHorseToTimeRangeAsManager, removeHorseFromTimeRangeAsManager, GetPaddocksAsManager, getPaddocksAsManager, GetTimeRangesAsManager, getTimeRangesAsManager, UpdateOrderOfPaddocksOfFarmAsManager, updateOrderOfPaddocksOfFarmAsManager } from '@/feature/paddock-plan';
import { APIResource, initialAPIResource } from '@/infrastructure';

interface PaddockPlanManagementState {
  paddockPlan: GetPaddockPlanAsManager.PaddockPlan | null;
  idOfSelectedHorse: HorseId | null;
  idOfPaddockPlanTimeRangeInProgress: PaddockPlanTimeRangeId | null;
  paddocks: APIResource<GetPaddocksAsManager.Paddock[]>;
  timeRanges: APIResource<GetTimeRangesAsManager.TimeRange[]>;

  getPaddockPlanStatus: ActionStatus;
  createPaddockStatus: ActionStatus;
  updatePaddockStatus: ActionStatus;
  deletePaddockStatus: ActionStatus;
  createTimeRangeStatus: ActionStatus;
  updateTimeRangeStatus: ActionStatus;
  deleteTimeRangeStatus: ActionStatus;
  addHorseToTimeRangeStatus: ActionStatus;
  removeHorseFromTimeRangeStatus: ActionStatus;
  updateOrderOfPaddocksOfFarmAsManagerStatus: ActionStatus;
}

function initialState(): PaddockPlanManagementState {
  return {
    paddockPlan: null,
    idOfSelectedHorse: null,
    idOfPaddockPlanTimeRangeInProgress: null,
    paddocks: initialAPIResource(),
    timeRanges: initialAPIResource(),

    getPaddockPlanStatus: ActionStatus.None,
    createPaddockStatus: ActionStatus.None,
    updatePaddockStatus: ActionStatus.None,
    deletePaddockStatus: ActionStatus.None,
    createTimeRangeStatus: ActionStatus.None,
    updateTimeRangeStatus: ActionStatus.None,
    deleteTimeRangeStatus: ActionStatus.None,
    addHorseToTimeRangeStatus: ActionStatus.None,
    removeHorseFromTimeRangeStatus: ActionStatus.None,
    updateOrderOfPaddocksOfFarmAsManagerStatus: ActionStatus.None,
  };
}

export const usePaddockPlanManagementStore = defineStore('paddockPlanManagement', {
  state: (): PaddockPlanManagementState => initialState(),
  getters: {
    isPaddockPlanTimeRangeProcessing: (state: PaddockPlanManagementState): (paddockPlanTimeRangeId: PaddockPlanTimeRangeId) => boolean =>
      (paddockPlanTimeRangeId) => state.idOfPaddockPlanTimeRangeInProgress === paddockPlanTimeRangeId,
  },
  actions: {

    // -- State management

    selectHorse(horseId: HorseId): Promise<void> {
      this.idOfSelectedHorse = horseId;

      return Promise.resolve();
    },

    revertHorseSelection(): Promise<void> {
      this.idOfSelectedHorse = null;

      return Promise.resolve();
    },

    // -- Queries

    getPaddockPlanAsManager(): Promise<void> {
      const { getPaddockPlanStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPaddockPlanStatus,
        () => getPaddockPlanAsManager(attachFarmAndUserProperties({}))
          .then(async (paddockPlan) => {
            this.paddockPlan = paddockPlan;
          })
      );
    },

    getPaddocksAsManager(): Promise<void> {
      const query: GetPaddocksAsManager.GetPaddocksAsManagerQuery = {};
      const { paddocks } = storeToRefs(this);
      return wrapAction(
        paddocks,
        () => getPaddocksAsManager(query)
      );
    },

    getTimeRangesAsManager(): Promise<void> {
      const query: GetTimeRangesAsManager.GetTimeRangesAsManagerQuery = {};
      const { timeRanges } = storeToRefs(this);
      return wrapAction(
        timeRanges,
        () => getTimeRangesAsManager(query)
      );
    },

    // -- Commands

    createPaddockAsManager(command: CreatePaddockAsManager.CreatePaddockAsManagerCommand): Promise<void> {
      const { createPaddockStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPaddockStatus,
        () => createPaddockAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getPaddocksAsManager())
      );
    },

    updatePaddockAsManager(command: UpdatePaddockAsManager.UpdatePaddockAsManagerCommand): Promise<void> {
      const { updatePaddockStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePaddockStatus,
        () => updatePaddockAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getPaddocksAsManager())
      );
    },

    deletePaddockAsManager(command: DeletePaddockAsManager.DeletePaddockAsManagerCommand): Promise<void> {
      const { deletePaddockStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deletePaddockStatus,
        () => deletePaddockAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getPaddocksAsManager())
      );
    },

    createTimeRangeAsManager(command: CreateTimeRangeAsManager.CreateTimeRangeAsManagerCommand): Promise<void> {
      const { createTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTimeRangeStatus,
        () => createTimeRangeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getTimeRangesAsManager())
      );
    },

    updateTimeRangeAsManager(command: UpdateTimeRangeAsManager.UpdateTimeRangeAsManagerCommand): Promise<void> {
      const { updateTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTimeRangeStatus,
        () => updateTimeRangeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getTimeRangesAsManager())
      );
    },

    deleteTimeRangeAsManager(command: DeleteTimeRangeAsManager.DeleteTimeRangeAsManagerCommand): Promise<void> {
      const { deleteTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteTimeRangeStatus,
        () => deleteTimeRangeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getTimeRangesAsManager())
      );
    },

    addHorseToTimeRangeAsManager(command: AddHorseToTimeRangeAsManager.AddHorseToTimeRangeAsManagerCommand): Promise<void> {
      this.idOfPaddockPlanTimeRangeInProgress = command.paddockPlanTimeRangeId;
      const { addHorseToTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addHorseToTimeRangeStatus,
        () => addHorseToTimeRangeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlanAsManager())
          .finally(() => {
            this.idOfSelectedHorse = null;
            this.idOfPaddockPlanTimeRangeInProgress = null;
          })
      );
    },

    removeHorseFromTimeRangeAsManager(command: RemoveHorseFromTimeRangeAsManager.RemoveHorseFromTimeRangeAsManagerCommand): Promise<void> {
      const { removeHorseFromTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeHorseFromTimeRangeStatus,
        () => removeHorseFromTimeRangeAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlanAsManager())
      );
    },

    updateOrderOfPaddocksOfFarmAsManager(
      command: UpdateOrderOfPaddocksOfFarmAsManager.UpdateOrderOfPaddocksOfFarmAsManagerCommand
    ): Promise<void> {
      const { updateOrderOfPaddocksOfFarmAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfPaddocksOfFarmAsManagerStatus,
        () => updateOrderOfPaddocksOfFarmAsManager(attachFarmAndUserProperties(command))
          .then(() => this.getPaddocksAsManager())
      );
    },

  },
});
