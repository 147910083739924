import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { CreatePaddockAsManager, UpdatePaddockAsManager, DeletePaddockAsManager, CreateTimeRangeAsManager, UpdateTimeRangeAsManager, DeleteTimeRangeAsManager, AddHorseToTimeRangeAsManager, RemoveHorseFromTimeRangeAsManager, GetPaddockPlanAsManager, GetPaddocksAsManager, GetTimeRangesAsManager, UpdateOrderOfPaddocksOfFarmAsManager, GetPaddockAssignmentsForHorseAsManager } from './types';

// -- Queries

export function getPaddockPlanAsManager(
  query: GetPaddockPlanAsManager.GetPaddockPlanAsManagerQuery
): Promise<GetPaddockPlanAsManager.PaddockPlan> {
  const url = `${apiUrl}/api/paddock-plan/get-paddock-plan-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPaddocksAsManager(
  query: GetPaddocksAsManager.GetPaddocksAsManagerQuery
): Promise<GetPaddocksAsManager.Paddock[]> {
  const url = `${apiUrl}/api/paddock-plan/get-paddocks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getTimeRangesAsManager(
  query: GetTimeRangesAsManager.GetTimeRangesAsManagerQuery
): Promise<GetTimeRangesAsManager.TimeRange[]> {
  const url = `${apiUrl}/api/paddock-plan/get-time-ranges-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getPaddockAssignmentsForHorseAsManager(
  query: GetPaddockAssignmentsForHorseAsManager.GetPaddockAssignmentsForHorseAsManagerQuery
): Promise<GetPaddockAssignmentsForHorseAsManager.PaddockAssignment[]> {
  const url = `${apiUrl}/api/paddock-plan/get-paddock-assignments-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createPaddockAsManager(command: CreatePaddockAsManager.CreatePaddockAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/create-paddock-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updatePaddockAsManager(command: UpdatePaddockAsManager.UpdatePaddockAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/update-paddock-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deletePaddockAsManager(command: DeletePaddockAsManager.DeletePaddockAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/delete-paddock-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createTimeRangeAsManager(command: CreateTimeRangeAsManager.CreateTimeRangeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/create-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateTimeRangeAsManager(command: UpdateTimeRangeAsManager.UpdateTimeRangeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/update-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteTimeRangeAsManager(command: DeleteTimeRangeAsManager.DeleteTimeRangeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/delete-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function addHorseToTimeRangeAsManager(command: AddHorseToTimeRangeAsManager.AddHorseToTimeRangeAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/assign-horse-to-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function removeHorseFromTimeRangeAsManager(
  command: RemoveHorseFromTimeRangeAsManager.RemoveHorseFromTimeRangeAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/remove-horse-from-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateOrderOfPaddocksOfFarmAsManager(
  command: UpdateOrderOfPaddocksOfFarmAsManager.UpdateOrderOfPaddocksOfFarmAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/update-order-of-paddocks-of-farm-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
