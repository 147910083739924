import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import NewsTabs from './components/news-tabs.vue';
import NewsList from './components/news-list.vue';
import NewsConfiguration from './components/news-configuration.vue';

export const newsManagementRoutes: RouteConfig[] = [
  {
    path: 'neuigkeiten',
    component: NewsTabs,
    children: [
      {
        name: 'news-management',
        path: '',
        component: NewsList,
        meta: {
          title: 'Neuigkeiten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.NEWS_READ,
        },
      },
      {
        name: 'news-configuration',
        path: 'konfiguration',
        component: NewsConfiguration,
        meta: {
          title: 'Neuigkeiten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.NEWS_READ,
        },
      },
    ],
    meta: {
      title: 'Neuigkeiten',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresPermission: FarmManagerPermission.NEWS_READ,
    },
  },
];
