import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { Limit, SearchTerm } from '@/types';
import { GetFeedProtocolConfigurationAsManager, GetFeedProtocolOverviewAsManager, GetStationUtilizationAsManager, PauseMonitoringForHorseAsManager, UnpauseMonitoringForHorseAsManager, CreateStationAsManager, UpdateStationAsManager, DeleteStationAsManager, EnablePushNotificationsAsManager, DisablePushNotificationsAsManager, pauseMonitoringForHorseAsManager, unpauseMonitoringForHorseAsManager, enablePushNotificationsAsManager, disablePushNotificationsAsManager, createStationAsManager, updateStationAsManager, deleteStationAsManager, getFeedProtocolOverviewAsManager, getStationUtilizationAsManager, getFeedProtocolConfigurationAsManager, GetAlarmProtocolAsManager, getAlarmProtocolAsManager } from '@/feature/feed-protocol';
import { updateEquineNumberAsManager, UpdateEquineNumberAsManager } from '@/feature/horse-management';

interface FeedProtocolManagementState {
  feedProtocolOverview: APIResource<GetFeedProtocolOverviewAsManager.FeedProtocolOverview>;
  alarmProtocol: APIResource<GetAlarmProtocolAsManager.AlarmProtocol>;
  searchTermForAlarmProtocol: string | null;
  limitForAlarmProtocol: Limit;
  stationUtilization: APIResource<GetStationUtilizationAsManager.StationUtilization>;
  feedProtocolConfiguration: APIResource<GetFeedProtocolConfigurationAsManager.FeedProtocolConfiguration>;

  pauseMonitoringForHorseAsManagerStatus: ActionStatus;
  unpauseMonitoringForHorseAsManagerStatus: ActionStatus;
  enablePushNotificationsAsManagerStatus: ActionStatus;
  disablePushNotificationsAsManagerStatus: ActionStatus;
  createStationAsManagerStatus: ActionStatus;
  updateStationAsManagerStatus: ActionStatus;
  deleteStationAsManagerStatus: ActionStatus;
  updateEquineNumberAsManagerStatus: ActionStatus;
}

function initialState(): FeedProtocolManagementState {
  return {
    feedProtocolOverview: initialAPIResource(),
    alarmProtocol: initialAPIResource(),
    searchTermForAlarmProtocol: null,
    limitForAlarmProtocol: 50,
    stationUtilization: initialAPIResource(),
    feedProtocolConfiguration: initialAPIResource(),

    pauseMonitoringForHorseAsManagerStatus: ActionStatus.None,
    unpauseMonitoringForHorseAsManagerStatus: ActionStatus.None,
    enablePushNotificationsAsManagerStatus: ActionStatus.None,
    disablePushNotificationsAsManagerStatus: ActionStatus.None,
    createStationAsManagerStatus: ActionStatus.None,
    updateStationAsManagerStatus: ActionStatus.None,
    deleteStationAsManagerStatus: ActionStatus.None,
    updateEquineNumberAsManagerStatus: ActionStatus.None,
  };
}

export const useFeedProtocolManagementStore = defineStore('feedProtocolManagement', {
  state: (): FeedProtocolManagementState => initialState(),
  actions: {

    // -- State management

    updateSearchTermForAlarmProtocol(search: SearchTerm | null): Promise<void> {
      this.searchTermForAlarmProtocol = search;
      this.limitForAlarmProtocol = 50;

      return this.getAlarmProtocolAsManager();
    },

    increaseLimitForAlarmProtocol(): Promise<void> {
      this.limitForAlarmProtocol += 50;

      return this.getAlarmProtocolAsManager();
    },

    // -- Queries

    getFeedProtocolOverviewAsManager(): Promise<void> {
      const query: GetFeedProtocolOverviewAsManager.GetFeedProtocolOverviewAsManagerQuery = {};
      const { feedProtocolOverview } = storeToRefs(this);
      return wrapAction(
        feedProtocolOverview,
        () => getFeedProtocolOverviewAsManager(query)
      );
    },

    getAlarmProtocolAsManager(): Promise<void> {
      const query: GetAlarmProtocolAsManager.GetAlarmProtocolAsManagerQuery = {
        search: this.searchTermForAlarmProtocol,
        limit: this.limitForAlarmProtocol,
      };
      const { alarmProtocol } = storeToRefs(this);
      return wrapAction(
        alarmProtocol,
        () => getAlarmProtocolAsManager(query)
      );
    },

    getStationUtilizationAsManager(): Promise<void> {
      const query: GetStationUtilizationAsManager.GetStationUtilizationAsManagerQuery = {};
      const { stationUtilization } = storeToRefs(this);
      return wrapAction(
        stationUtilization,
        () => getStationUtilizationAsManager(query)
      );
    },

    getFeedProtocolConfigurationAsManager(): Promise<void> {
      const query: GetFeedProtocolConfigurationAsManager.GetFeedProtocolConfigurationAsManagerQuery = {};
      const { feedProtocolConfiguration } = storeToRefs(this);
      return wrapAction(
        feedProtocolConfiguration,
        () => getFeedProtocolConfigurationAsManager(query)
      );
    },

    // -- Commands

    pauseMonitoringForHorseAsManager(command: PauseMonitoringForHorseAsManager.PauseMonitoringForHorseAsManagerCommand): Promise<void> {
      const { pauseMonitoringForHorseAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        pauseMonitoringForHorseAsManagerStatus,
        () => pauseMonitoringForHorseAsManager(command)
          .then(() => this.getFeedProtocolOverviewAsManager())
      );
    },

    unpauseMonitoringForHorseAsManager(
      command: UnpauseMonitoringForHorseAsManager.UnpauseMonitoringForHorseAsManagerCommand
    ): Promise<void> {
      const { unpauseMonitoringForHorseAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpauseMonitoringForHorseAsManagerStatus,
        () => unpauseMonitoringForHorseAsManager(command)
          .then(() => this.getFeedProtocolOverviewAsManager())
      );
    },

    enablePushNotificationsAsManager(command: EnablePushNotificationsAsManager.EnablePushNotificationsAsManagerCommand): Promise<void> {
      const { enablePushNotificationsAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enablePushNotificationsAsManagerStatus,
        () => enablePushNotificationsAsManager(command)
          .then(() => this.getFeedProtocolConfigurationAsManager())
      );
    },

    disablePushNotificationsAsManager(command: DisablePushNotificationsAsManager.DisablePushNotificationsAsManagerCommand): Promise<void> {
      const { disablePushNotificationsAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disablePushNotificationsAsManagerStatus,
        () => disablePushNotificationsAsManager(command)
          .then(() => this.getFeedProtocolConfigurationAsManager())
      );
    },

    createStationAsManager(command: CreateStationAsManager.CreateStationAsManagerCommand): Promise<void> {
      const { createStationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createStationAsManagerStatus,
        () => createStationAsManager(command)
          .then(() => this.getFeedProtocolConfigurationAsManager())
      );
    },

    updateStationAsManager(command: UpdateStationAsManager.UpdateStationAsManagerCommand): Promise<void> {
      const { updateStationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateStationAsManagerStatus,
        () => updateStationAsManager(command)
          .then(() => this.getFeedProtocolConfigurationAsManager())
      );
    },

    deleteStationAsManager(command: DeleteStationAsManager.DeleteStationAsManagerCommand): Promise<void> {
      const { deleteStationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteStationAsManagerStatus,
        () => deleteStationAsManager(command)
          .then(() => this.getFeedProtocolConfigurationAsManager())
      );
    },

    updateEquineNumberAsManager(command: UpdateEquineNumberAsManager.UpdateEquineNumberAsManagerCommand): Promise<void> {
      const { updateEquineNumberAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEquineNumberAsManagerStatus,
        () => updateEquineNumberAsManager(command)
          .then(() => this.getFeedProtocolOverviewAsManager())
      );
    },

  },
});
