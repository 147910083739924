import { defineStore, storeToRefs } from 'pinia';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { Date, Limit } from '@/types';
import { ActionStatus } from '@/application/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { CreatePenStatusEntryAsManager, UpdatePenStatusEntryAsManager, DeletePenStatusEntryAsManager, UpdatePenStatusVisibilityAsManager, EnablePenExemptionsAsManager, DisablePenExemptionsAsManager, createPenStatusEntryAsManager, updatePenStatusEntryAsManager, deletePenStatusEntryAsManager, updatePenStatusVisibilityAsManager, enablePenExemptionsAsManager, disablePenExemptionsAsManager, GetPenStatusEntriesAsManager, GetConfigurationAsManager, GetPenExemptionsAsManager, getPenStatusEntriesAsManager, getConfigurationAsManager, getPenExemptionsAsManager, CreatePenStatusEntriesAsManager, createPenStatusEntriesAsManager } from '@/feature/pen';

interface PenStatusManagementState {
  penStatusEntries: APIResource<GetPenStatusEntriesAsManager.PenStatusEntries>;
  limitForPenStatusEntries: Limit;
  configuration: APIResource<GetConfigurationAsManager.Configuration>;
  dateForPenExemptions: Date | null;
  penExemptions: APIResource<GetPenExemptionsAsManager.PenExemption[]>;

  createPenStatusEntryAsManagerStatus: ActionStatus;
  createPenStatusEntriesAsManagerStatus: ActionStatus;
  updatePenStatusEntryAsManagerStatus: ActionStatus;
  deletePenStatusEntryAsManagerStatus: ActionStatus;
  updatePenStatusVisibilityAsManagerStatus: ActionStatus;
  enablePenExemptionsAsManagerStatus: ActionStatus;
  disablePenExemptionsAsManagerStatus: ActionStatus;
}

function initialState(): PenStatusManagementState {
  return {
    penStatusEntries: initialAPIResource(),
    limitForPenStatusEntries: 50,
    configuration: initialAPIResource(),
    dateForPenExemptions: null,
    penExemptions: initialAPIResource(),

    createPenStatusEntryAsManagerStatus: ActionStatus.None,
    createPenStatusEntriesAsManagerStatus: ActionStatus.None,
    updatePenStatusEntryAsManagerStatus: ActionStatus.None,
    deletePenStatusEntryAsManagerStatus: ActionStatus.None,
    updatePenStatusVisibilityAsManagerStatus: ActionStatus.None,
    enablePenExemptionsAsManagerStatus: ActionStatus.None,
    disablePenExemptionsAsManagerStatus: ActionStatus.None,
  };
}

export const usePenManagementStore = defineStore('paddockManagement', {
  state: (): PenStatusManagementState => initialState(),
  actions: {

    // -- State management

    updateDateForPenExemptions(date: Date): Promise<void> {
      this.dateForPenExemptions = date;

      return this.getPenExemptionsAsManager();
    },

    increaseLimitForPenStatusEntries(): Promise<void> {
      this.limitForPenStatusEntries += 50;

      return this.getPenStatusEntriesAsManager();
    },

    // -- Queries

    getPenStatusEntriesAsManager(): Promise<void> {
      const query: GetPenStatusEntriesAsManager.GetPenStatusEntriesAsManagerQuery = {
        limit: this.limitForPenStatusEntries,
      };
      const { penStatusEntries } = storeToRefs(this);
      return wrapAction(
        penStatusEntries,
        () => getPenStatusEntriesAsManager(query)
      );
    },

    getConfigurationAsManager(): Promise<void> {
      const query: GetConfigurationAsManager.GetConfigurationAsManagerQuery = {};
      const { configuration } = storeToRefs(this);
      return wrapAction(
        configuration,
        () => getConfigurationAsManager(query)
      );
    },

    getPenExemptionsAsManager(): Promise<void> {
      const query: GetPenExemptionsAsManager.GetPenExemptionsAsManagerQuery = {
        date: this.dateForPenExemptions!,
      };
      const { penExemptions } = storeToRefs(this);
      return wrapAction(
        penExemptions,
        () => getPenExemptionsAsManager(query)
      );
    },

    // -- Commands

    createPenStatusEntryAsManager(command: CreatePenStatusEntryAsManager.CreatePenStatusEntryAsManagerCommand): Promise<void> {
      const { createPenStatusEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPenStatusEntryAsManagerStatus,
        () => createPenStatusEntryAsManager(command)
          .then(() => this.getPenStatusEntriesAsManager())
      );
    },

    createPenStatusEntriesAsManager(command: CreatePenStatusEntriesAsManager.CreatePenStatusEntriesAsManagerCommand): Promise<void> {
      const { createPenStatusEntriesAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPenStatusEntriesAsManagerStatus,
        () => createPenStatusEntriesAsManager(command)
          .then(() => this.getPenStatusEntriesAsManager())
      );
    },

    updatePenStatusEntryAsManager(command: UpdatePenStatusEntryAsManager.UpdatePenStatusEntryAsManagerCommand): Promise<void> {
      const { updatePenStatusEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePenStatusEntryAsManagerStatus,
        () => updatePenStatusEntryAsManager(command)
          .then(() => this.getPenStatusEntriesAsManager())
      );
    },

    deletePenStatusEntryAsManager(command: DeletePenStatusEntryAsManager.DeletePenStatusEntryAsManagerCommand): Promise<void> {
      const { deletePenStatusEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deletePenStatusEntryAsManagerStatus,
        () => deletePenStatusEntryAsManager(command)
          .then(() => this.getPenStatusEntriesAsManager())
      );
    },

    updatePenStatusVisibilityAsManager(
      command: UpdatePenStatusVisibilityAsManager.UpdatePenStatusVisibilityAsManagerCommand
    ): Promise<void> {
      const { updatePenStatusVisibilityAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePenStatusVisibilityAsManagerStatus,
        () => updatePenStatusVisibilityAsManager(command)
          .then(() => this.getConfigurationAsManager())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    enablePenExemptionsAsManager(command: EnablePenExemptionsAsManager.EnablePenExemptionsAsManagerCommand): Promise<void> {
      const { enablePenExemptionsAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enablePenExemptionsAsManagerStatus,
        () => enablePenExemptionsAsManager(command)
          .then(() => this.getConfigurationAsManager())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    disablePenExemptionsAsManager(): Promise<void> {
      const command: DisablePenExemptionsAsManager.DisablePenExemptionsAsManagerCommand = {};
      const { disablePenExemptionsAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disablePenExemptionsAsManagerStatus,
        () => disablePenExemptionsAsManager(command)
          .then(() => this.getConfigurationAsManager())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
