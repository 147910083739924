import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import MedicalRecordTabs from './components/medical-record-tabs.vue';
import AppointmentsAndHorseWeight from './components/appointments-and-horse-weight.vue';
import Vaccinations from './components/vaccinations.vue';

const defaultMeta = {
  title: 'Gesundheitsakten',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.MEDICAL_RECORDS_READ,
};

export const medicalRecordsManagementRoutes: RouteConfig[] = [
  {
    path: 'gesundheitsakten',
    component: MedicalRecordTabs,
    children: [
      {
        name: 'medical-records-management-appointments-and-horse-weight',
        path: '',
        component: AppointmentsAndHorseWeight,
        meta: defaultMeta,
      },
      {
        name: 'medical-records-management-vaccinations',
        path: 'impfungen',
        component: Vaccinations,
        meta: defaultMeta,
      },
    ],
  },
];
