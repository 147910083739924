import { defineStore, storeToRefs } from 'pinia';
import { wrapAction } from '@/store';
import { LinkOfFilterForRidingLessonPlanId } from '@/types';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { GetRidingLessonPlanAsExternalUser, getRidingLessonPlanAsExternalUser } from '@/feature/riding-lessons';

interface RidingLessonPlanAsExternalUserState {
  currentLinkOfFilterForRidingLessonPlanId: LinkOfFilterForRidingLessonPlanId | null,
  ridingLessonPlan: APIResource<GetRidingLessonPlanAsExternalUser.RidingLesson[]>;
}

function initialState(): RidingLessonPlanAsExternalUserState {
  return {
    currentLinkOfFilterForRidingLessonPlanId: null,
    ridingLessonPlan: initialAPIResource(),
  };
}

export const useRidingLessonPlanAsExternalUserStore = defineStore('ridingLessonPlanAsExternalUser', {
  state: (): RidingLessonPlanAsExternalUserState => initialState(),
  actions: {

    // -- State management

    updateCurrentLinkOfFilterForRidingLessonPlan(linkOfFilterForRidingLessonPlanId: LinkOfFilterForRidingLessonPlanId): Promise<void> {
      this.currentLinkOfFilterForRidingLessonPlanId = linkOfFilterForRidingLessonPlanId;

      return this.getRidingLessonPlan();
    },

    resetCurrentLinkOfFilterForRidingLessonPlan(): Promise<void> {
      this.currentLinkOfFilterForRidingLessonPlanId = null;
      this.ridingLessonPlan = initialAPIResource();

      return this.getRidingLessonPlan();
    },

    // -- Queries

    getRidingLessonPlan(): Promise<void> {
      const query: GetRidingLessonPlanAsExternalUser.GetRidingLessonPlanAsExternalUserQuery = {
        linkOfFilterForRidingLessonPlanId: this.currentLinkOfFilterForRidingLessonPlanId!,
      };
      const { ridingLessonPlan } = storeToRefs(this);
      return wrapAction(
        ridingLessonPlan,
        () => getRidingLessonPlanAsExternalUser(query)
      );
    },

  },
});
