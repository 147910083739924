import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { UserId } from '@/types';
import { wrapActionWithProgress } from '@/store';
import { AssignTaskToUserAsManager, MarkTaskAsCompletedAsManager, MarkTaskAsRejectedAsManager, CreateAssignedTaskAsManager, UpdateExecutionDateOfTaskAsManager, assignTaskToUserAsManager, markTaskAsCompletedAsManager, markTaskAsRejectedAsManager, createAssignedTaskAsManager, updateExecutionDateOfTaskAsManager, GetUsersWithTasksAsManager, GetFarmManagersForAssignmentAsManager, GetNewTasksAsManager, GetAssignedTasksAsManager, GetCompletedTasksAsManager, GetRejectedTasksAsManager, GetWithdrawnTasksAsManager, GetAssignedTasksForTodayAsManager, GetOverdueTasksAsManager, GetUsersForAssignedTasksAsManager, getUsersWithTasks, getFarmManagersForAssignment, getNewTasks, getAssignedTasks, getCompletedTasks, getRejectedTasks, getWithdrawnTasks, getAssignedTasksForToday, getOverdueTasks, getUsersForAssignedTasks } from '@/feature/rider-tasks';

interface TaskManagementState {
  usersWithTasks: GetUsersWithTasksAsManager.User[];
  farmManagers: GetFarmManagersForAssignmentAsManager.User[];
  usersForAssignedTasks: GetUsersForAssignedTasksAsManager.User[];

  filterTasksByUser: UserId | null;

  newTasks: GetNewTasksAsManager.Task[];
  newTasksLimit: number;
  newTasksTotalCount: number | null;

  assignedTasks: GetAssignedTasksAsManager.Task[];
  assignedTasksLimit: number;
  assignedTasksTotalCount: number | null;

  completedTasks: GetCompletedTasksAsManager.Task[];
  completedTasksLimit: number;
  completedTasksTotalCount: number | null;

  rejectedTasks: GetRejectedTasksAsManager.Task[];
  rejectedTasksLimit: number;
  rejectedTasksTotalCount: number | null;

  withdrawnTasks: GetWithdrawnTasksAsManager.Task[];
  withdrawnTasksLimit: number;
  withdrawnTasksTotalCount: number | null;

  assignedTasksForToday: GetAssignedTasksForTodayAsManager.Task[];

  overdueTasks: GetOverdueTasksAsManager.Task[];

  getUsersWithTasksAsManagerStatus: ActionStatus;
  assignTaskToUserAsManagerStatus: ActionStatus;
  markTaskAsCompletedAsManagerStatus: ActionStatus;
  markTaskAsRejectedAsManagerStatus: ActionStatus;
  getNewTasksAsManagerStatus: ActionStatus;
  getCompletedTasksAsManagerStatus: ActionStatus;
  getRejectedTasksAsManagerStatus: ActionStatus;
  getWithdrawnTasksAsManagerStatus: ActionStatus;
  getAssignedTasksForTodayAsManagerStatus: ActionStatus;
  getFarmManagersForAssignmentAsManagerStatus: ActionStatus;
  getAssignedTasksAsManagerStatus: ActionStatus;
  createAssignedTaskAsManagerStatus: ActionStatus;
  getOverdueTasksAsManagerStatus: ActionStatus;
  updateExecutionDateOfTaskAsManagerStatus: ActionStatus;
  getUsersForAssignedTasksAsManagerStatus: ActionStatus;
}

function initialState(): TaskManagementState {
  return {
    usersWithTasks: [],
    farmManagers: [],
    usersForAssignedTasks: [],

    filterTasksByUser: null,

    newTasks: [],
    newTasksLimit: 50,
    newTasksTotalCount: null,

    assignedTasks: [],
    assignedTasksLimit: 50,
    assignedTasksTotalCount: null,

    completedTasks: [],
    completedTasksLimit: 50,
    completedTasksTotalCount: null,

    rejectedTasks: [],
    rejectedTasksLimit: 50,
    rejectedTasksTotalCount: null,

    withdrawnTasks: [],
    withdrawnTasksLimit: 50,
    withdrawnTasksTotalCount: null,

    assignedTasksForToday: [],

    overdueTasks: [],

    getUsersWithTasksAsManagerStatus: ActionStatus.None,
    assignTaskToUserAsManagerStatus: ActionStatus.None,
    markTaskAsCompletedAsManagerStatus: ActionStatus.None,
    markTaskAsRejectedAsManagerStatus: ActionStatus.None,
    getNewTasksAsManagerStatus: ActionStatus.None,
    getCompletedTasksAsManagerStatus: ActionStatus.None,
    getRejectedTasksAsManagerStatus: ActionStatus.None,
    getWithdrawnTasksAsManagerStatus: ActionStatus.None,
    getAssignedTasksForTodayAsManagerStatus: ActionStatus.None,
    getFarmManagersForAssignmentAsManagerStatus: ActionStatus.None,
    getAssignedTasksAsManagerStatus: ActionStatus.None,
    createAssignedTaskAsManagerStatus: ActionStatus.None,
    getOverdueTasksAsManagerStatus: ActionStatus.None,
    updateExecutionDateOfTaskAsManagerStatus: ActionStatus.None,
    getUsersForAssignedTasksAsManagerStatus: ActionStatus.None,
  };
}

export const useRiderTaskManagementStore = defineStore('manageRiderTasks', {
  state: (): TaskManagementState => initialState(),
  actions: {

    // -- State management

    increaseNewTasksLimit(): Promise<void> {
      this.newTasksLimit += 50;

      return this.getNewTasks();
    },

    updateTasksFilteredByUser(userId: UserId | null): Promise<void> {
      this.filterTasksByUser = userId;

      return Promise.resolve();
    },

    increaseCompletedTasksLimit(): Promise<void> {
      this.completedTasksLimit += 50;

      return this.getCompletedTasks();
    },

    increaseRejectedTasksLimit(): Promise<void> {
      this.rejectedTasksLimit += 50;

      return this.getRejectedTasks();
    },

    increaseWithdrawnTasksLimit(): Promise<void> {
      this.withdrawnTasksLimit += 50;

      return this.getWithdrawnTasks();
    },

    increaseAssignedTasksLimit(): Promise<void> {
      this.assignedTasksLimit += 50;

      return this.getAssignedTasks();
    },

    // -- Queries

    getUsersWithTasks(): Promise<void> {
      const query: GetUsersWithTasksAsManager.GetUsersWithTasksAsManagerQuery = {};
      const { getUsersWithTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersWithTasksAsManagerStatus,
        () => getUsersWithTasks(query)
          .then(async (users) => {
            this.usersWithTasks = users;
          })
      );
    },

    getFarmManagersForAssignment(): Promise<void> {
      const query: GetFarmManagersForAssignmentAsManager.GetFarmManagersForAssigmentAsManagerQuery = {};
      const { getFarmManagersForAssignmentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmManagersForAssignmentAsManagerStatus,
        () => getFarmManagersForAssignment(query)
          .then(async (farmManagers) => {
            this.farmManagers = farmManagers;
          })
      );
    },

    getNewTasks(): Promise<void> {
      const query: GetNewTasksAsManager.GetNewTasksAsManagerQuery = {
        filteredByUser: this.filterTasksByUser,
        limit: this.newTasksLimit,
      };
      const { getNewTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNewTasksAsManagerStatus,
        () => getNewTasks(query)
          .then(async (response) => {
            this.newTasks = response.tasks;
            this.newTasksTotalCount = response.totalCount;
          })
      );
    },

    getAssignedTasks(): Promise<void> {
      const query: GetAssignedTasksAsManager.GetAssignedTasksAsManagerQuery = {
        filteredByUser: this.filterTasksByUser,
        limit: this.assignedTasksLimit,
      };
      const { getAssignedTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAssignedTasksAsManagerStatus,
        () => getAssignedTasks(query)
          .then(async (response) => {
            this.assignedTasks = response.tasks;
            this.assignedTasksTotalCount = response.totalCount;
          })
      );
    },

    getCompletedTasks(): Promise<void> {
      const query: GetCompletedTasksAsManager.GetCompletedTasksAsManagerQuery = {
        filteredByUser: this.filterTasksByUser,
        limit: this.completedTasksLimit,
      };
      const { getCompletedTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCompletedTasksAsManagerStatus,
        () => getCompletedTasks(query)
          .then(async (response) => {
            this.completedTasks = response.tasks;
            this.completedTasksTotalCount = response.totalCount;
          })
      );
    },

    getRejectedTasks(): Promise<void> {
      const query: GetRejectedTasksAsManager.GetRejectedTasksAsManagerQuery = {
        filteredByUser: this.filterTasksByUser,
        limit: this.rejectedTasksLimit,
      };
      const { getRejectedTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRejectedTasksAsManagerStatus,
        () => getRejectedTasks(query)
          .then(async (response) => {
            this.rejectedTasks = response.tasks;
            this.rejectedTasksTotalCount = response.totalCount;
          })
      );
    },

    getWithdrawnTasks(): Promise<void> {
      const query: GetWithdrawnTasksAsManager.GetWithdrawnTasksAsManagerQuery = {
        filteredByUser: this.filterTasksByUser,
        limit: this.withdrawnTasksLimit,
      };
      const { getWithdrawnTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getWithdrawnTasksAsManagerStatus,
        () => getWithdrawnTasks(query)
          .then(async (response) => {
            this.withdrawnTasks = response.tasks;
            this.withdrawnTasksTotalCount = response.totalCount;
          })
      );
    },

    getAssignedTasksForToday(): Promise<void> {
      const query: GetAssignedTasksForTodayAsManager.GetAssignedTasksForTodayAsManagerQuery = {};
      const { getAssignedTasksForTodayAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAssignedTasksForTodayAsManagerStatus,
        () => getAssignedTasksForToday(query)
          .then(async (tasks) => {
            this.assignedTasksForToday = tasks;
          })
      );
    },

    getOverdueTasks(): Promise<void> {
      const query: GetOverdueTasksAsManager.GetOverdueTasksAsManagerQuery = {};
      const { getOverdueTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOverdueTasksAsManagerStatus,
        () => getOverdueTasks(query)
          .then(async (tasks) => {
            this.overdueTasks = tasks;
          })
      );
    },

    getUsersForAssignedTasks(): Promise<void> {
      const query: GetUsersForAssignedTasksAsManager.GetUsersForAssignedTasksAsManagerQuery = {};
      const { getUsersForAssignedTasksAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersForAssignedTasksAsManagerStatus,
        () => getUsersForAssignedTasks(query)
          .then(async (usersForAssignedTasks) => {
            this.usersForAssignedTasks = usersForAssignedTasks;
          })
      );
    },

    // -- Commands

    assignTaskToUserAsManager(command: AssignTaskToUserAsManager.AssignTaskToUserAsManagerCommand): Promise<void> {
      const { assignTaskToUserAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        assignTaskToUserAsManagerStatus,
        () => assignTaskToUserAsManager(command)
      );
    },

    markTaskAsCompletedAsManager(command: MarkTaskAsCompletedAsManager.MarkTaskAsCompletedAsManagerCommand): Promise<void> {
      const { markTaskAsCompletedAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTaskAsCompletedAsManagerStatus,
        () => markTaskAsCompletedAsManager(command)
      );
    },

    markTaskAsRejectedAsManager(command: MarkTaskAsRejectedAsManager.MarkTaskAsRejectedAsManagerCommand): Promise<void> {
      const { markTaskAsRejectedAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTaskAsRejectedAsManagerStatus,
        () => markTaskAsRejectedAsManager(command)
          .then(() => this.getNewTasks())
      );
    },

    createAssignedTaskAsManager(command: CreateAssignedTaskAsManager.CreateAssignedTaskAsManagerCommand): Promise<void> {
      const { createAssignedTaskAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createAssignedTaskAsManagerStatus,
        () => createAssignedTaskAsManager(command)
          .then(() => this.getAssignedTasks())
      );
    },

    updateExecutionDateOfTaskAsManager(
      command: UpdateExecutionDateOfTaskAsManager.UpdateExecutionDateOfTaskAsManagerCommand
    ): Promise<void> {
      const { updateExecutionDateOfTaskAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateExecutionDateOfTaskAsManagerStatus,
        () => updateExecutionDateOfTaskAsManager(command)
          .then(() => this.getAssignedTasks())
      );
    },

  },
});
