import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { attachUserProperty } from '@/helpers/default-parameter-helper';
import { TokenStorage } from '@/application/authentication/token-storage';
import { FarmId, UserRole } from '@/types';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { getGlobalUsersAsAdmin, GetGlobalUsersAsAdmin } from '@/feature/user-management';
import { getFarms, getUserForImpersonationAsAdmin, updateEnabledPermissionsOfUser, updateUserPassword, updateUserRole } from './service';
import { GetUserForImpersonationAsAdminQuery, UpdateEnabledPermissionsOfUserAsAdminWorkflow, UpdateUserPasswordAsAdminCommand, UpdateUserRoleAsAdminWorkflow } from './types';

interface GlobalUserManagementState {
  currentFilteredByFarm: FarmId | null;
  currentFilteredByRole: UserRole | null;
  currentSearchTerm: string | null;
  currentLimit: number;

  users: APIResource<GetGlobalUsersAsAdmin.Response>;

  farms: GetGlobalUsersAsAdmin.Farm[];

  getUserForImpersonationAsAdminStatus: ActionStatus;
  updateUserPasswordStatus: ActionStatus;
  updateUserRoleStatus: ActionStatus;
  updateEnabledPermissionsOfUserStatus: ActionStatus;
  getFarmsStatus: ActionStatus;
}

function initialState(): GlobalUserManagementState {
  return {
    currentFilteredByFarm: null,
    currentFilteredByRole: null,
    currentSearchTerm: null,
    currentLimit: 50,

    users: initialAPIResource(),

    farms: [],

    getUserForImpersonationAsAdminStatus: ActionStatus.None,
    updateUserPasswordStatus: ActionStatus.None,
    updateUserRoleStatus: ActionStatus.None,
    updateEnabledPermissionsOfUserStatus: ActionStatus.None,
    getFarmsStatus: ActionStatus.None,
  };
}

export const useGlobalUserManagementStore = defineStore('globalUserManagement', {
  state: (): GlobalUserManagementState => initialState(),
  getters: {
    isGetUserForImpersonationAsAdminProcessing: (state: GlobalUserManagementState): boolean =>
      state.getUserForImpersonationAsAdminStatus === ActionStatus.InProgress,
    isUpdateUserPasswordProcessing: (state: GlobalUserManagementState): boolean =>
      state.updateUserPasswordStatus === ActionStatus.InProgress,
    isUpdateUserRoleProcessing: (state: GlobalUserManagementState): boolean =>
      state.updateUserRoleStatus === ActionStatus.InProgress,
    isUpdateEnabledPermissionsOfUserProcessing: (state: GlobalUserManagementState): boolean =>
      state.updateEnabledPermissionsOfUserStatus === ActionStatus.InProgress,
    isGetFarmsProcessing: (state: GlobalUserManagementState): boolean =>
      state.getFarmsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    increaseLimit(): Promise<void> {
      this.currentLimit += 50;

      return this.getGlobalUsersAsAdmin();
    },

    updateSearchTerm(searchTerm: string | null): Promise<void> {
      this.currentSearchTerm = searchTerm;
      this.currentLimit = 50;

      return this.getGlobalUsersAsAdmin();
    },

    updateFarmFilter(filteredByFarm: FarmId | null): Promise<void> {
      this.currentFilteredByFarm = filteredByFarm;
      this.currentLimit = 50;

      return this.getGlobalUsersAsAdmin();
    },

    updateRoleFilter(filteredByRole: UserRole | null): Promise<void> {
      this.currentFilteredByRole = filteredByRole;
      this.currentLimit = 50;

      return this.getGlobalUsersAsAdmin();
    },

    resetFilters(): Promise<void> {
      this.currentFilteredByFarm = null;
      this.currentFilteredByRole = null;
      this.currentSearchTerm = null;
      this.currentLimit = 50;

      return Promise.resolve();
    },

    // -- Queries

    getGlobalUsersAsAdmin(): Promise<void> {
      const query: GetGlobalUsersAsAdmin.GetGlobalUsersAsAdminQuery = {
        searchTerm: this.currentSearchTerm,
        filteredByFarm: this.currentFilteredByFarm,
        filteredByRole: this.currentFilteredByRole,
        limit: this.currentLimit,
      };
      const { users } = storeToRefs(this);
      return wrapAction(
        users,
        () => getGlobalUsersAsAdmin(attachUserProperty(query))
      );
    },

    getFarms(): Promise<void> {
      const { getFarmsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmsStatus,
        () => getFarms(attachUserProperty({}))
          .then(async (farms) => {
            this.farms = farms;
          })
      );
    },

    getUserForImpersonationAsAdmin(query: GetUserForImpersonationAsAdminQuery): Promise<void> {
      const { getUserForImpersonationAsAdminStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserForImpersonationAsAdminStatus,
        () => getUserForImpersonationAsAdmin(attachUserProperty(query))
          .then(() => TokenStorage.setImpersonateUserFlag())
      );
    },

    // -- Commands

    updateUserPassword(command: UpdateUserPasswordAsAdminCommand): Promise<void> {
      const { updateUserPasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserPasswordStatus,
        () => updateUserPassword(attachUserProperty(command))
      );
    },

    updateUserRole(command: UpdateUserRoleAsAdminWorkflow): Promise<void> {
      const { updateUserRoleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserRoleStatus,
        () => updateUserRole(attachUserProperty(command))
          .then(() => this.getGlobalUsersAsAdmin())
      );
    },

    updateEnabledPermissionsOfUser(command: UpdateEnabledPermissionsOfUserAsAdminWorkflow): Promise<void> {
      const { updateEnabledPermissionsOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEnabledPermissionsOfUserStatus,
        () => updateEnabledPermissionsOfUser(attachUserProperty(command))
          .then(() => this.getGlobalUsersAsAdmin())
      );
    },

  },
});
