import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import ManageUserTabs from './components/manage-users-tabs.vue';
import UserList from './components/user-list.vue';
import UserConfiguration from './components/user-configuration.vue';

const defaultMeta = {
  title: 'Benutzer',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.USERS_READ,
};

export const userManagementRoutes: RouteConfig[] = [
  {
    path: 'benutzer',
    component: ManageUserTabs,
    children: [
      {
        // Warning - Used in push notifications
        name: 'user-management-manage-users-user-table',
        path: '',
        component: UserList,
        meta: defaultMeta,
      },
      {
        name: 'user-management-manage-users-configuration',
        path: 'konfiguration',
        component: UserConfiguration,
        meta: defaultMeta,
      },
    ],
  },
];
