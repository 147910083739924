import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { HorseId, VaccinationStatus } from '@/types';
import { APIResource, initialAPIResource } from '@/infrastructure';
import { CreateVaccinationAsManager, CreateHorseWeightEntryAsManager, CreateHorseDrugEntryAsManager, CreateHorseFarrierAppointmentAsManager, CreateHorseWormingTreatmentAsManager, CreateHorseWormingTreatmentForMultipleHorsesAsManager, createVaccinationAsManager, createHorseWeightEntryAsManager, createHorseDrugEntryAsManager, createHorseFarrierAppointmentAsManager, createHorseWormingTreatmentAsManager, createHorseWormingTreatmentForMultipleHorsesAsManager, getDataForVaccinationCreation, getHorsesAsManager, GetHorsesAsManager, getWormingTreatmentsAsManager, GetWormingTreatmentsAsManager, DeleteHorseWormingTreatmentAsManager, deleteHorseWormingTreatmentAsManager, GetHorseDrugsAsManager, getHorseDrugsAsManager, DeleteHorseDrugEntryAsManager, deleteHorseDrugEntryAsManager, GetHorseFarrierAppointmentsAsManager, getHorseFarrierAppointmentsAsManager, DeleteHorseFarrierAppointmentAsManager, deleteHorseFarrierAppointmentAsManager, DeleteHorseWeightEntryAsManager, deleteHorseWeightEntryAsManager, GetHorseWeightEntriesAsManager, getHorseWeightEntriesAsManager, GetVaccinationsAsManager, getVaccinationsAsManager, GetDataForVaccinationCreationAsManager, DeleteVaccinationAsManager, deleteVaccinationAsManager, CreateVaccinationsAsManager, createVaccinationsAsManager } from '@/feature/medical-records';

interface HealthRecordsManagementState {
  horses: APIResource<GetHorsesAsManager.Horse[]>;
  wormingTreatments: APIResource<GetWormingTreatmentsAsManager.HorseWithWormingTreatments[]>;
  horseDrugs: APIResource<GetHorseDrugsAsManager.HorseWithHorseDrugs[]>;
  horseFarrierAppointments: APIResource<GetHorseFarrierAppointmentsAsManager.HorseWithFarrierAppointments[]>;
  horseWeightEntries: APIResource<GetHorseWeightEntriesAsManager.HorseWithWeightEntries[]>;
  vaccinations: APIResource<GetVaccinationsAsManager.Illness[]>;
  dataForVaccinationCreation: APIResource<GetDataForVaccinationCreationAsManager.DataForVaccinationCreation>;

  filteredForHorse: HorseId | null;
  filteredForVaccinationStatus: VaccinationStatus | null;

  getDataForVaccinationCreationStatus: ActionStatus;
  createVaccinationAsManagerStatus: ActionStatus;
  createHorseWeightEntryAsManagerStatus: ActionStatus;
  createHorseDrugEntryAsManagerStatus: ActionStatus;
  createHorseFarrierAppointmentAsManagerStatus: ActionStatus;
  createHorseWormingTreatmentAsManagerStatus: ActionStatus;
  createHorseWormingTreatmentForMultipleHorsesAsManagerStatus: ActionStatus;
  deleteHorseWormingTreatmentAsManagerStatus: ActionStatus;
  deleteHorseDrugEntryAsManagerStatus: ActionStatus;
  deleteHorseFarrierAppointmentAsManagerStatus: ActionStatus;
  deleteHorseWeightEntryAsManagerStatus: ActionStatus;
  deleteVaccinationAsManagerStatus: ActionStatus;
  createVaccinationsAsManagerStatus: ActionStatus;
}

function initialState(): HealthRecordsManagementState {
  return {
    horses: initialAPIResource(),
    wormingTreatments: initialAPIResource(),
    horseDrugs: initialAPIResource(),
    horseFarrierAppointments: initialAPIResource(),
    horseWeightEntries: initialAPIResource(),
    vaccinations: initialAPIResource(),
    dataForVaccinationCreation: initialAPIResource(),

    filteredForHorse: null,
    filteredForVaccinationStatus: null,

    getDataForVaccinationCreationStatus: ActionStatus.None,
    createVaccinationAsManagerStatus: ActionStatus.None,
    createHorseWeightEntryAsManagerStatus: ActionStatus.None,
    createHorseDrugEntryAsManagerStatus: ActionStatus.None,
    createHorseFarrierAppointmentAsManagerStatus: ActionStatus.None,
    createHorseWormingTreatmentAsManagerStatus: ActionStatus.None,
    createHorseWormingTreatmentForMultipleHorsesAsManagerStatus: ActionStatus.None,
    deleteHorseWormingTreatmentAsManagerStatus: ActionStatus.None,
    deleteHorseDrugEntryAsManagerStatus: ActionStatus.None,
    deleteHorseFarrierAppointmentAsManagerStatus: ActionStatus.None,
    deleteHorseWeightEntryAsManagerStatus: ActionStatus.None,
    deleteVaccinationAsManagerStatus: ActionStatus.None,
    createVaccinationsAsManagerStatus: ActionStatus.None,
  };
}

export const useMedicalRecordsManagementStore = defineStore('manageMedicalRecords', {
  state: (): HealthRecordsManagementState => initialState(),
  actions: {

    // -- State management

    updateHorseToFilter(horseId: HorseId | null): Promise<void> {
      this.filteredForHorse = horseId;

      return Promise.resolve();
    },

    updateVaccinationStatusToFilter(vaccinationStatus: VaccinationStatus | null): Promise<void> {
      this.filteredForVaccinationStatus = vaccinationStatus;

      return Promise.resolve();
    },

    // -- Queries

    getDataForVaccinationCreationAsManager(): Promise<void> {
      const query: GetDataForVaccinationCreationAsManager.GetDataForVaccinationCreationAsManagerQuery = {};
      const { dataForVaccinationCreation } = storeToRefs(this);
      return wrapAction(
        dataForVaccinationCreation,
        () => getDataForVaccinationCreation(query)
      );
    },

    getHorsesAsManager(): Promise<void> {
      const query: GetHorsesAsManager.GetHorsesAsManagerQuery = {};
      const { horses } = storeToRefs(this);
      return wrapAction(
        horses,
        () => getHorsesAsManager(query)
      );
    },

    getWormingTreatmentsAsManager(): Promise<void> {
      const query: GetWormingTreatmentsAsManager.GetWormingTreatmentsAsManagerQuery = {
        horseId: this.filteredForHorse,
      };
      const { wormingTreatments } = storeToRefs(this);
      return wrapAction(
        wormingTreatments,
        () => getWormingTreatmentsAsManager(query)
      );
    },

    getHorseDrugsAsManager(): Promise<void> {
      const query: GetHorseDrugsAsManager.GetHorseDrugsAsManagerQuery = {
        horseId: this.filteredForHorse,
      };
      const { horseDrugs } = storeToRefs(this);
      return wrapAction(
        horseDrugs,
        () => getHorseDrugsAsManager(query)
      );
    },

    getHorseFarrierAppointmentsAsManager(): Promise<void> {
      const query: GetHorseFarrierAppointmentsAsManager.GetHorseFarrierAppointmentsAsManagerQuery = {
        horseId: this.filteredForHorse,
      };
      const { horseFarrierAppointments } = storeToRefs(this);
      return wrapAction(
        horseFarrierAppointments,
        () => getHorseFarrierAppointmentsAsManager(query)
      );
    },

    getHorseWeightEntriesAsManager(): Promise<void> {
      const query: GetHorseWeightEntriesAsManager.GetHorseWeightEntriesAsManagerQuery = {
        horseId: this.filteredForHorse,
      };
      const { horseWeightEntries } = storeToRefs(this);
      return wrapAction(
        horseWeightEntries,
        () => getHorseWeightEntriesAsManager(query)
      );
    },

    getVaccinationsAsManager(): Promise<void> {
      const query: GetVaccinationsAsManager.GetVaccinationsAsManagerQuery = {
        horseId: this.filteredForHorse,
        vaccinationStatus: this.filteredForVaccinationStatus,
      };
      const { vaccinations } = storeToRefs(this);
      return wrapAction(
        vaccinations,
        () => getVaccinationsAsManager(query)
      );
    },

    // -- Commands

    createVaccinationAsManager(command: CreateVaccinationAsManager.CreateVaccinationAsManagerCommand): Promise<void> {
      const { createVaccinationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createVaccinationAsManagerStatus,
        () => createVaccinationAsManager(command)
          .then(() => this.getVaccinationsAsManager())
      );
    },

    createHorseWeightEntryAsManager(command: CreateHorseWeightEntryAsManager.CreateHorseWeightEntryAsManagerCommand): Promise<void> {
      const { createHorseWeightEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWeightEntryAsManagerStatus,
        () => createHorseWeightEntryAsManager(command)
          .then(() => this.getHorseWeightEntriesAsManager())
      );
    },

    createHorseDrugEntryAsManager(command: CreateHorseDrugEntryAsManager.CreateHorseDrugEntryAsManagerCommand): Promise<void> {
      const { createHorseDrugEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseDrugEntryAsManagerStatus,
        () => createHorseDrugEntryAsManager(command)
          .then(() => this.getHorseDrugsAsManager())
      );
    },

    createHorseFarrierAppointmentAsManager(
      command: CreateHorseFarrierAppointmentAsManager.CreateHorseFarrierAppointmentAsManagerCommand
    ): Promise<void> {
      const { createHorseFarrierAppointmentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseFarrierAppointmentAsManagerStatus,
        () => createHorseFarrierAppointmentAsManager(command)
          .then(() => this.getHorseFarrierAppointmentsAsManager())
      );
    },

    createHorseWormingTreatmentAsManager(
      command: CreateHorseWormingTreatmentAsManager.CreateHorseWormingTreatmentAsManagerCommand
    ): Promise<void> {
      const { createHorseWormingTreatmentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWormingTreatmentAsManagerStatus,
        () => createHorseWormingTreatmentAsManager(command)
          .then(() => this.getWormingTreatmentsAsManager())
      );
    },

    createHorseWormingTreatmentForMultipleHorsesAsManager(
      command: CreateHorseWormingTreatmentForMultipleHorsesAsManager.CreateHorseWormingTreatmentForMultipleHorsesAsManagerCommand
    ): Promise<void> {
      const { createHorseWormingTreatmentForMultipleHorsesAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWormingTreatmentForMultipleHorsesAsManagerStatus,
        () => createHorseWormingTreatmentForMultipleHorsesAsManager(command)
          .then(() => this.getWormingTreatmentsAsManager())
      );
    },

    deleteHorseWormingTreatmentAsManager(
      command: DeleteHorseWormingTreatmentAsManager.DeleteHorseWormingTreatmentAsManagerCommand
    ): Promise<void> {
      const { deleteHorseWormingTreatmentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseWormingTreatmentAsManagerStatus,
        () => deleteHorseWormingTreatmentAsManager(command)
          .then(() => this.getWormingTreatmentsAsManager())
      );
    },

    deleteHorseDrugEntryAsManager(
      command: DeleteHorseDrugEntryAsManager.DeleteHorseDrugEntryAsManagerCommand
    ): Promise<void> {
      const { deleteHorseDrugEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseDrugEntryAsManagerStatus,
        () => deleteHorseDrugEntryAsManager(command)
          .then(() => this.getHorseDrugsAsManager())
      );
    },

    deleteHorseFarrierAppointmentAsManager(
      command: DeleteHorseFarrierAppointmentAsManager.DeleteHorseFarrierAppointmentAsManagerCommand
    ): Promise<void> {
      const { deleteHorseFarrierAppointmentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseFarrierAppointmentAsManagerStatus,
        () => deleteHorseFarrierAppointmentAsManager(command)
          .then(() => this.getHorseFarrierAppointmentsAsManager())
      );
    },

    deleteHorseWeightEntryAsManager(
      command: DeleteHorseWeightEntryAsManager.DeleteHorseWeightEntryAsManagerCommand
    ): Promise<void> {
      const { deleteHorseWeightEntryAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseWeightEntryAsManagerStatus,
        () => deleteHorseWeightEntryAsManager(command)
          .then(() => this.getHorseWeightEntriesAsManager())
      );
    },

    deleteVaccinationAsManager(
      command: DeleteVaccinationAsManager.DeleteVaccinationAsManagerCommand
    ): Promise<void> {
      const { deleteVaccinationAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteVaccinationAsManagerStatus,
        () => deleteVaccinationAsManager(command)
          .then(() => this.getVaccinationsAsManager())
      );
    },

    createVaccinationsAsManager(
      command: CreateVaccinationsAsManager.CreateVaccinationsAsManagerCommand
    ): Promise<void> {
      const { createVaccinationsAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createVaccinationsAsManagerStatus,
        () => createVaccinationsAsManager(command)
          .then(() => this.getVaccinationsAsManager())
      );
    },

  },
});
