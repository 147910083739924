import { render, staticRenderFns } from "./copy-link-dialog.vue?vue&type=template&id=0f13a35b&scoped=true&"
import script from "./copy-link-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./copy-link-dialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./copy-link-dialog.vue?vue&type=style&index=0&id=0f13a35b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f13a35b",
  null
  
)

export default component.exports