import { Color, EquineNumber, FeedProtocolAlarmEntryId, FeedProtocolMonitoringPausedUntil, FeedProtocolStationId, HorseId, Limit, Moment, SearchTerm } from '@/types';
import { Command, Query } from '@/application/types';

// -- Queries

export namespace GetFeedProtocolOverviewAsManager {
  export interface GetFeedProtocolOverviewAsManagerQuery extends Query {}

  export type FeedProtocolOverview = {
    latestSignals: LatestSignalForHorse[];
    horsesWithoutSignals: HorseWithoutSignals[];
    horsesWithoutEquineNumber: HorseWithoutEquineNumber[];
  }

  export type LatestSignalForHorse = {
    horseId: HorseId;
    horseName: string;
    stationName: string;
    horseLeftStationLastestAt: Moment;
    signalStatus: SignalStatus;
  }

  export enum SignalStatus {
    OK = 'OK',
    MONITORING_PAUSED = 'MONITORING_PAUSED',
    MONITORING_PAUSED_UNTIL_NEXT_FEEDING = 'MONITORING_PAUSED_UNTIL_NEXT_FEEDING',
    OVERDUE = 'OVERDUE',
  }

  export type HorseWithoutSignals = {
    horseId: HorseId;
    horseName: string;
    equineNumber: string;
  }

  export type HorseWithoutEquineNumber = {
    horseId: HorseId;
    horseName: string;
    ownerName: string;
  }
}

export namespace GetStationUtilizationAsManager {
  export interface GetStationUtilizationAsManagerQuery extends Query {}

  export type StationUtilization = {
    stations: StationForStationUtilization[];
    stationUtilizationMap: StationUtilizationMap;
    horses: HorseStationUtilization[];
  }

  export type StationForStationUtilization = {
    feedProtocolStationId: FeedProtocolStationId;
    name: string;
    color: Color;
  }

  export type HorseStationUtilization = {
    horseId: HorseId;
    horseName: string;
    stationUtilizationMap: StationUtilizationMap;
  }

  export type StationUtilizationMap = Record<string, number>;
}

export namespace GetFeedProtocolConfigurationAsManager {
  export interface GetFeedProtocolConfigurationAsManagerQuery extends Query {}

  export type FeedProtocolConfiguration = {
    stations: Station[];
    arePushNotificationsEnabled: boolean;
  }

  export type Station = {
    feedProtocolStationId: FeedProtocolStationId;
    number: number;
    name: string;
    color: Color;
  }
}

export namespace GetAlarmProtocolAsManager {
  export interface GetAlarmProtocolAsManagerQuery extends Query {
    search: SearchTerm | null;
    limit: Limit;
  }

  export type AlarmProtocol = {
    totalCount: number;
    entries: AlarmEntry[];
  }

  export type AlarmEntry = {
    feedProtocolAlarmEntryId: FeedProtocolAlarmEntryId;
    horseName: string | null;
    equineNumber: EquineNumber | null;
    stationName: string | null;
    stationNumber: number | null;
    message: string;
    createdAt: Moment;
  }
}
// -- Commands

export namespace PauseMonitoringForHorseAsManager {
  export interface PauseMonitoringForHorseAsManagerCommand extends Command {
    horseId: HorseId;
    pausedUntil: FeedProtocolMonitoringPausedUntil;
  }
}

export namespace UnpauseMonitoringForHorseAsManager {
  export interface UnpauseMonitoringForHorseAsManagerCommand extends Command {
    horseId: HorseId;
  }
}

export namespace EnablePushNotificationsAsManager {
  export interface EnablePushNotificationsAsManagerCommand extends Command {}
}

export namespace DisablePushNotificationsAsManager {
  export interface DisablePushNotificationsAsManagerCommand extends Command {}
}

export namespace CreateStationAsManager {
  export interface CreateStationAsManagerCommand extends Command {
    number: number;
    name: string;
    color: Color;
  }
}

export namespace UpdateStationAsManager {
  export interface UpdateStationAsManagerCommand extends Command {
    feedProtocolStationId: FeedProtocolStationId;
    number: number;
    name: string;
    color: Color;
  }
}

export namespace DeleteStationAsManager {
  export interface DeleteStationAsManagerCommand extends Command {
    feedProtocolStationId: FeedProtocolStationId;
  }
}
