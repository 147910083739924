import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { AssignTaskToUserAsManager, MarkTaskAsCompletedAsManager, MarkTaskAsRejectedAsManager, CreateAssignedTaskAsManager, UpdateExecutionDateOfTaskAsManager, GetUsersWithTasksAsManager, GetFarmManagersForAssignmentAsManager, GetAssignedTasksForTodayAsManager, GetOverdueTasksAsManager, GetUsersForAssignedTasksAsManager, GetNewTasksAsManager, GetAssignedTasksAsManager, GetCompletedTasksAsManager, GetRejectedTasksAsManager, GetWithdrawnTasksAsManager } from './types';

// -- Queries

export function getUsersWithTasks(
  query: GetUsersWithTasksAsManager.GetUsersWithTasksAsManagerQuery
): Promise<GetUsersWithTasksAsManager.User[]> {
  const url = `${apiUrl}/api/rider-tasks/get-users-with-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFarmManagersForAssignment(
  query: GetFarmManagersForAssignmentAsManager.GetFarmManagersForAssigmentAsManagerQuery
): Promise<GetFarmManagersForAssignmentAsManager.User[]> {
  const url = `${apiUrl}/api/rider-tasks/get-farm-managers-for-assignment-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getNewTasks(query: GetNewTasksAsManager.GetNewTasksAsManagerQuery): Promise<GetNewTasksAsManager.NewTasks> {
  const url = `${apiUrl}/api/rider-tasks/get-new-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getAssignedTasks(
  query: GetAssignedTasksAsManager.GetAssignedTasksAsManagerQuery
): Promise<GetAssignedTasksAsManager.AssignedTasks> {
  const url = `${apiUrl}/api/rider-tasks/get-assigned-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCompletedTasks(
  query: GetCompletedTasksAsManager.GetCompletedTasksAsManagerQuery
): Promise<GetCompletedTasksAsManager.CompletedTasks> {
  const url = `${apiUrl}/api/rider-tasks/get-completed-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getRejectedTasks(
  query: GetRejectedTasksAsManager.GetRejectedTasksAsManagerQuery
): Promise<GetRejectedTasksAsManager.RejectedTasks> {
  const url = `${apiUrl}/api/rider-tasks/get-rejected-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getWithdrawnTasks(
  query: GetWithdrawnTasksAsManager.GetWithdrawnTasksAsManagerQuery
): Promise<GetWithdrawnTasksAsManager.WithdrawnTasks> {
  const url = `${apiUrl}/api/rider-tasks/get-withdrawn-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getAssignedTasksForToday(
  query: GetAssignedTasksForTodayAsManager.GetAssignedTasksForTodayAsManagerQuery
): Promise<GetAssignedTasksForTodayAsManager.Task[]> {
  const url = `${apiUrl}/api/rider-tasks/get-assigned-tasks-for-today-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getOverdueTasks(
  query: GetOverdueTasksAsManager.GetOverdueTasksAsManagerQuery
): Promise<GetOverdueTasksAsManager.Task[]> {
  const url = `${apiUrl}/api/rider-tasks/get-overdue-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getUsersForAssignedTasks(
  query: GetUsersForAssignedTasksAsManager.GetUsersForAssignedTasksAsManagerQuery
): Promise<GetUsersForAssignedTasksAsManager.User[]> {
  const url = `${apiUrl}/api/rider-tasks/get-users-for-assigned-tasks-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function assignTaskToUserAsManager(command: AssignTaskToUserAsManager.AssignTaskToUserAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/assign-task-to-user-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function markTaskAsCompletedAsManager(command: MarkTaskAsCompletedAsManager.MarkTaskAsCompletedAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/mark-task-as-completed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function markTaskAsRejectedAsManager(command: MarkTaskAsRejectedAsManager.MarkTaskAsRejectedAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/mark-task-as-rejected-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createAssignedTaskAsManager(command: CreateAssignedTaskAsManager.CreateAssignedTaskAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/create-assigned-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateExecutionDateOfTaskAsManager(
  command: UpdateExecutionDateOfTaskAsManager.UpdateExecutionDateOfTaskAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/update-execution-date-of-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
