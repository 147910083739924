import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetFarmServicesInMonthForHorseAsManager, GetFarmServiceOverviewFilterDataAsManager, GetFarmServiceUsagesForDayAsManager, GetFarmServiceUsagesForMonthAsManager, GetFarmServicesAsManager, UpdateHiddenFarmServicesAsManager, CreateFarmServiceAsManager, UpdateFarmServicePresentationAsManager, UpdateFarmServicePriceAsManager, UpdateFarmServicePaymentMethodAsManager, UpdateFarmServiceBookingStartAsManager, UpdateFarmServiceRestrictionsAsManager, EnableFarmServiceAsManager, DisableFarmServiceAsManager, ArchiveFarmServiceAsManager } from './types';

// -- Queries

export function getFarmServicesInMonthForHorseAsManager(
  query: GetFarmServicesInMonthForHorseAsManager.GetFarmServicesInMonthForHorseAsManagerQuery
): Promise<GetFarmServicesInMonthForHorseAsManager.FarmServiceBooking[]> {
  const url = `${apiUrl}/api/farm-services/get-farm-services-in-month-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFarmServiceOverviewFilterDataAsManager(
  query: GetFarmServiceOverviewFilterDataAsManager.GetFarmServiceOverviewFilterDataAsManagerQuery
): Promise<GetFarmServiceOverviewFilterDataAsManager.FarmServiceOverviewFilterData> {
  const url = `${apiUrl}/api/farm-services/get-farm-service-overview-filter-data-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFarmServiceUsagesForDayAsManager(
  query: GetFarmServiceUsagesForDayAsManager.GetFarmServiceUsagesForDayAsManagerQuery
): Promise<GetFarmServiceUsagesForDayAsManager.FarmServiceUsages> {
  const url = `${apiUrl}/api/farm-services/get-farm-service-usages-for-day-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFarmServiceUsagesForMonthAsManager(
  query: GetFarmServiceUsagesForMonthAsManager.GetFarmServiceUsagesForMonthAsManagerQuery
): Promise<GetFarmServiceUsagesForMonthAsManager.FarmServiceUsages> {
  const url = `${apiUrl}/api/farm-services/get-farm-service-usages-for-month-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getFarmServicesAsManager(
  query: GetFarmServicesAsManager.GetFarmServicesAsManagerQuery
): Promise<GetFarmServicesAsManager.FarmService[]> {
  const url = `${apiUrl}/api/farm-services/get-farm-services-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function updateHiddenFarmServicesAsManager(
  command: UpdateHiddenFarmServicesAsManager.UpdateHiddenFarmServicesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-hidden-farm-services-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createFarmServiceAsManager(command: CreateFarmServiceAsManager.CreateFarmServiceAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/farm-services/create-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateFarmServicePresentationAsManager(
  command: UpdateFarmServicePresentationAsManager.UpdateFarmServicePresentationAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-presentation-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateFarmServicePriceAsManager(
  command: UpdateFarmServicePriceAsManager.UpdateFarmServicePriceAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-price-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateFarmServicePaymentMethodAsManager(
  command: UpdateFarmServicePaymentMethodAsManager.UpdateFarmServicePaymentMethodAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateFarmServiceBookingStartAsManager(
  command: UpdateFarmServiceBookingStartAsManager.UpdateFarmServiceBookingStartAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-booking-start-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateFarmServiceRestrictionsAsManager(
  command: UpdateFarmServiceRestrictionsAsManager.UpdateFarmServiceRestrictionsAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-services/update-farm-service-restrictions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function enableFarmServiceAsManager(command: EnableFarmServiceAsManager.EnableFarmServiceAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/farm-services/enable-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function disableFarmServiceAsManager(command: DisableFarmServiceAsManager.DisableFarmServiceAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/farm-services/disable-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function archiveFarmServiceAsManager(command: ArchiveFarmServiceAsManager.ArchiveFarmServiceAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/farm-services/archive-farm-service-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
