import { useAuthenticationStore } from '@/application/authentication/store';
import { useAdminNotificationManagementStore } from '@/private/admin/admin-notifications/store';
import { useFacilityReservationsManagementStore } from '@/private/management/facility-reservations/store';
import { useBookingCalendarStore } from '@/private/rider/booking-calendars/store';
import { useAdminNotificationBannerStore } from '@/private/common/admin-notification-banner/store';
import { usePinboardStore } from '@/private/rider/pinboard/store';
import { useGlobalUserManagementStore } from '@/private/admin/global-users/store';
import { useRiderTaskManagementStore } from '@/private/management/rider-tasks/store';
import { useRiderTasksStore } from '@/private/rider/rider-tasks/store';
import { useFarmDocumentsManagementStore } from '@/private/management/farm-documents/store';
import { useFarmDocumentsStore } from '@/private/rider/farm-documents/store';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { useUserRegistrationStore } from '@/public/user-registration/store';
import { useAnalyticsStore } from '@/private/management/analytics/store';
import { usePaddockPlanManagementStore } from '@/private/management/paddock-plan/store';
import { useEventManagementStore } from '@/private/management/events/store';
import { useHorseManagementStore } from '@/private/management/horses/store';
import { useVaccineManagementStore } from '@/private/admin/vaccines/store';
import { useLaborServiceStore } from '@/private/rider/labor-service/store';
import { useFarmManagementStore } from '@/private/admin/farms/store';
import { useSurveyManagementStore } from '@/private/management/surveys/store';
import { useUserManagementStore } from '@/private/management/users/store';
import { useProfileStore } from '@/private/rider/profile/store';
import { usePenManagementStore } from '@/private/management/pen/store';
import { useFarmServiceStore } from '@/private/management/farm-services/store';
import { useShowFarmChangesForDayStore } from '@/private/management/farm-changes/store';
import { useFeedProtocolManagementStore } from '@/private/management/feed-protocol/store';
import { useLaborServicesManagementStore } from '@/private/management/labor-services/store';
import { useNewsManagementStore } from '@/private/management/news/store';
import { useBoxPlanManagementStore } from '@/private/management/box-plan/store';
import { useSettlementStore } from '@/private/management/settlement/store';
import { useMedicalRecordsManagementStore } from '@/private/management/medical-records/store';
import { useFarmTaskManagementStore } from '@/private/management/farm-tasks/store';
import { useInvoicingStore } from '@/private/management/invoicing/store';
import { useDashboardStore } from '@/private/rider/dashboard/store';

// App state is not reset on purpose

export function clearAuthenticationStates(): void {
  useAuthenticationStore().resetExcludingWasInitialAuthenticationAttempted();
}

export function clearDomainStates(): void {
  useAdminNotificationManagementStore().$reset();
  useFacilityReservationsManagementStore().$reset();
  useBookingCalendarStore().$reset();
  useAdminNotificationBannerStore().$reset();
  usePinboardStore().$reset();
  useGlobalUserManagementStore().$reset();
  useRiderTaskManagementStore().$reset();
  useFarmTaskManagementStore().$reset();
  useRiderTasksStore().$reset();
  useFarmDocumentsManagementStore().$reset();
  useFarmDocumentsStore().$reset();
  useUserRegistrationStore().$reset();
  useMyStableStore().$reset();
  useAnalyticsStore().$reset();
  usePaddockPlanManagementStore().$reset();
  useEventManagementStore().$reset();
  useHorseManagementStore().$reset();
  useVaccineManagementStore().$reset();
  useLaborServiceStore().$reset();
  useFarmManagementStore().$reset();
  useSurveyManagementStore().$reset();
  useUserManagementStore().$reset();
  useProfileStore().$reset();
  usePenManagementStore().$reset();
  useFarmServiceStore().$reset();
  useShowFarmChangesForDayStore().$reset();
  useFeedProtocolManagementStore().$reset();
  useLaborServicesManagementStore().$reset();
  useNewsManagementStore().$reset();
  useBoxPlanManagementStore().$reset();
  useSettlementStore().$reset();
  useMedicalRecordsManagementStore().$reset();
  useInvoicingStore().$reset();
  useDashboardStore().$reset();
}
