import { CalendarIntervalHeight, Date, DateTimeFrame, EmailAddress, FacilityId, HorseId, LinkOfFilterForRidingLessonPlanId, Moment, RidingLessonColor, RidingLessonDuration, RidingLessonId, RidingLessonIdList, RidingLessonRegistrationId, RidingLessonRegistrationType, RidingLessonSeriesId, RidingLessonTypeId, Strain, Time, TimeFrame, UserId, UserIdentification, Weekday } from '@/types';
import { Command, Query } from '@/application/types';

// -- Queries

export namespace GetConfigurationAsManager {
  export interface GetConfigurationAsManagerQuery extends Query {}

  export type Response = {
    facilities: Facility[];
    farmManagers: FarmManager[];
    horses: Horse[];
    configuration: Configuration | null;
  }

  export type Configuration = {
    isRidingLessonPlanVisible: boolean;
    calendarIntervalHeight: CalendarIntervalHeight;
    areNewUsersAllowedToRegisterForRidingLessons: boolean;
    idsOfHorsesAvailableForAssignment: HorseId[];
    areHorsesFilteredByRidingLessonType: boolean;
    isRidingInstructorShownOnPlan: boolean;
    isWaitingListAvailable: boolean;
    waitingListNotice: string | null;
    idsOfHorsesAvailableForAssignmentForRidingLessonTypeMap: Record<RidingLessonTypeId, HorseId[]>;
  }

  export type Facility = {
    facilityId: FacilityId;
    name: string;
    spaces: number;
    openingHours: TimeFrame;
    ridingLessonConfiguration: RidingLessonConfigurationForFacility | null;
    ridingLessonTypes: RidingLessonType[];
  }

  export type RidingLessonConfigurationForFacility = {
    isRegistrationForOneselfAllowed: boolean;
    isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
    isCancellationOfRegistrationAllowed: boolean;
    explanationForCommentFieldForRegistration: string | null;
  }

  export type RidingLessonType = {
    ridingLessonTypeId: RidingLessonTypeId;
    name: string;
    requiredSpaces: number;
    strainOnHorse: Strain;
    color: RidingLessonColor;
    defaultDuration: RidingLessonDuration;
    registrationInAdvanceInDays: number;
    idsOfHorsesAvailableForAssignment: HorseId[];
    isEnabled: boolean;
  }

  export type FarmManager = {
    userId: UserId;
    name: string;
  }

  export type Horse = {
    horseId: HorseId;
    name: string;
  }
}

export namespace GetRidingLessonsForSeriesRegistrationAsManager {
  export interface GetRidingLessonsForSeriesRegistrationAsManagerQuery extends Query {
    ridingLessonSeriesId: RidingLessonSeriesId;
  }

  export type RidingLessonForSeriesRegistration = {
    ridingLessonId: RidingLessonId;
    name: string;
  }
}

export namespace GetRidingLessonsAsManager {
  export interface GetRidingLessonsAsManagerQuery extends Query {
    date: Date;
  }

  export type RidingLesson = {
    ridingLessonId: RidingLessonId;
    facility: FacilityIdentification;
    ridingLessonType: RidingLessonTypeIdentification;
    ridingLessonSeries: RidingLessonSeries | null;
    from: Moment;
    to: Moment;
    duration: number;
    lastRegistrationAt: Moment;
    ridingInstructorId: UserId | null;
    maxAmountOfParticipants: number | null;
    horses: Horse[];
    registrations: RidingLessonRegistration[];
    facilityOverlappingWithOtherRidingLessons: RidingLessonId[];
    ridingInstructorOverlappingWithOtherRidingLessons: RidingLessonId[];
    horsesOverlappingWithOtherRidingLessonsMap: Record<HorseId, RidingLessonId> | null;
    horsesWithDuplicateAssignments: HorseId[];
  }

  export type FacilityIdentification = {
    facilityId: FacilityId;
    name: string;
  }

  export type RidingLessonTypeIdentification = {
    ridingLessonTypeId: RidingLessonTypeId;
    name: string;
  }

  export type RidingLessonSeries = {
    ridingLessonSeriesId: RidingLessonSeriesId;
    weekday: Weekday;
    timeFrame: TimeFrame;
    daysBeforeStartForLastRegistrationAt: number;
    timeForLastRegistrationAt: Time;
    lastRidingLessonAt: Moment;
    ridingInstructorId: UserId | null;
    maxAmountOfParticipants: number | null;
  }

  export type RidingLessonRegistration = {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    user: UserIdentification;
    type: RidingLessonRegistrationType;
    horse: Horse | null;
    nameOfAnotherPerson: string | null;
    comment: string | null;
    registeredAt: Moment;
  }

  export type Horse = {
    horseId: HorseId;
    name: string;
  }
}

export namespace GetRidingLessonsForSelectionAsManager {
  export interface GetRidingLessonsForSelectionAsManagerQuery extends Query {
    date: Date;
  }

  export type RidingLessonIdentification = {
    ridingLessonId: RidingLessonId;
    name: string;
  }
}

export namespace GetHorsesForAssignmentWithUtilizationAsManager {
  export interface GetHorsesForAssignmentWithUtilizationAsManagerQuery extends Query {
    date: Date;
    utilizationRange: UtilizationRange;
  }

  export enum UtilizationRange {
    ONE_DAY = 'ONE_DAY',
    THREE_DAYS = 'THREE_DAYS',
  }

  export type HorseWithUtilization = {
    horseId: HorseId;
    name: string;
    days: RidingLessonActivity[][];
  }

  export type RidingLessonActivity = {
    duration: RidingLessonDuration;
    strainOnHorse: Strain;
  }
}

export namespace GetHorseUtilizationAsManager {
  export interface GetHorseUtilizationAsManagerQuery extends Query {
    dateTimeFrame: DateTimeFrame;
  }

  export type HorseUtilization = {
    horses: HorseWithUtilization[];
    days: Date[];
  }

  export type HorseWithUtilization = {
    horseId: HorseId;
    name: string;
    days: RidingLessonActivity[][];
  }

  export type RidingLessonActivity = {
    duration: RidingLessonDuration;
    strainOnHorse: Strain;
  }
}

export namespace GetUsersForRidingLessonRegistrationAsManager {
  export interface GetUsersForRidingLessonRegistrationAsManagerQuery extends Query {}

  export type UserForRidingLessonRegistration = {
    userId: UserId;
    name: string;
  }
}

export namespace GetFilterDataForPlanAsManager {
  export interface GetFilterDataForPlanAsManagerQuery extends Query {}

  export type FilterData = {
    users: User[];
    horses: Horse[];
  }

  export type User = {
    userId: UserId;
    name: string;
  }

  export type Horse = {
    horseId: HorseId;
    name: string;
  }
}

export namespace GetPlanAsManager {
  export interface GetPlanAsManagerQuery extends Query {
    dateTimeFrame: DateTimeFrame;
    filteredForUser: HorseId | null;
    filteredForHorse: UserId | null;
    areAllRegistrationsVisible: boolean;
  }

  export type Plan = {
    ridingLessons: RidingLesson[];
    ridingLessonRegistrations: RidingLessonRegistration[];
  }

  export type RidingLesson = {
    ridingLessonId: RidingLessonId;
    from: Moment;
    to: Moment;
    nameOfRidingLessonType: string;
    namesOfHorses: string[];
  }

  export type RidingLessonRegistration = {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    from: Moment;
    to: Moment;
    nameOfRidingLessonType: string;
    nameOfHorse: string | null;
    nameOfPerson: string;
    comment: string | null;
  }
}

export namespace GetLinkOfFilterForPlanAsManager {
  export interface GetLinkOfFilterForPlanAsManagerQuery extends Query {
    dateTimeFrame: DateTimeFrame;
    filteredForUser: HorseId | null;
    filteredForHorse: UserId | null;
    areAllRegistrationsVisible: boolean;
  }

  export type Response = {
    linkOfFilterForRidingLessonPlanId: LinkOfFilterForRidingLessonPlanId | null;
    link: string | null;
  }
}

export namespace GetRidingLessonPlanAsExternalUser {
  export interface GetRidingLessonPlanAsExternalUserQuery extends Query {
    linkOfFilterForRidingLessonPlanId: LinkOfFilterForRidingLessonPlanId;
  }

  export type RidingLesson = {
    ridingLessonId: RidingLessonId;
    from: Moment;
    to: Moment;
    nameOfRidingLessonType: string;
    namesOfHorses: string[];
    ridingLessonRegistrations: RidingLessonRegistration[];
  }

  export type RidingLessonRegistration = {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    nameOfPerson: string;
    nameOfHorse: string | null;
  }
}

// -- Commands

export namespace RegisterPersonForRidingLessonAsManager {
  export interface RegisterPersonForRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    type: RidingLessonRegistrationType;
    idOfTargetUser: UserId | null;
    nameOfAnotherPerson: string | null;
    idOfUserToControlRegistration: UserId | null;
    comment: string | null;
  }
}

export namespace RegisterPersonForRidingLessonSeriesAsManager {
  export interface RegisterPersonForRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    idsOfRidingLessons: RidingLessonIdList;
    type: RidingLessonRegistrationType;
    idOfTargetUser: UserId | null;
    nameOfAnotherPerson: string | null;
    idOfUserToControlRegistration: UserId | null;
    comment: string | null;
  }
}

export namespace CreateRidingLessonTypeAsManager {
  export interface CreateRidingLessonTypeAsManagerCommand extends Command {
    facilityId: FacilityId;
    name: string;
    color: RidingLessonColor;
    strainOnHorse: Strain;
    defaultDuration: RidingLessonDuration;
    requiredSpaces: number;
    registrationInAdvanceInDays: number;
    idsOfHorsesAvailableForAssignment: HorseId[] | null;
  }
}

export namespace UpdateNameOfRidingLessonTypeAsManager {
  export interface UpdateNameOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    name: string;
  }
}

export namespace UpdateRequiredSpacesOfRidingLessonTypeAsManager {
  export interface UpdateRequiredSpacesOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    requiredSpaces: number;
  }
}

export namespace UpdateDefaultDurationOfRidingLessonTypeAsManager {
  export interface UpdateDefaultDurationOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    defaultDuration: RidingLessonDuration;
  }
}

export namespace UpdateStrainOnHorseOfRidingLessonTypeAsManager {
  export interface UpdateStrainOnHorseOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    strainOnHorse: Strain;
  }
}

export namespace EnableRidingLessonTypeAsManager {
  export interface EnableRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
  }
}

export namespace DisableRidingLessonTypeAsManager {
  export interface DisableRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
  }
}

export namespace UpdateOrderOfRidingLessonTypesOfFacilityAsManager {
  export interface UpdateOrderOfRidingLessonTypesOfFacilityAsManagerCommand extends Command {
    facilityId: FacilityId;
    sortedIdsOfRidingLessonTypes: RidingLessonTypeId[];
  }
}

export namespace UpdateRegistrationInAdvanceOfRidingLessonTypeAsManager {
  export interface UpdateRegistrationInAdvanceOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    registrationInAdvanceInDays: number;
  }
}

export namespace UpdateHorsesForAssignmentOfRidingLessonTypeAsManager {
  export interface UpdateHorsesForAssignmentOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    idsOfHorsesAvailableForAssignment: HorseId[];
  }
}

export namespace UpdateRidingLessonRegistrationRestrictionsForFacilityAsManager {
  export interface UpdateRidingLessonRegistrationRestrictionsForFacilityAsManagerCommand extends Command {
    facilityId: FacilityId;
    isRegistrationForOneselfAllowed: boolean;
    isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
    isCancellationOfRegistrationAllowed: boolean;
    explanationForCommentFieldForRegistration: string | null;
  }
}

export namespace SetUpRidingLessonsForFacilityAsManager {
  export interface SetUpRidingLessonsForFacilityAsManagerCommand extends Command {
    facilityId: FacilityId;
    isRegistrationForOneselfAllowed: boolean;
    isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
    isCancellationOfRegistrationAllowed: boolean;
    explanationForCommentFieldForRegistration: string | null;
  }
}

export namespace CreateRidingLessonAsManager {
  export interface CreateRidingLessonAsManagerCommand extends Command {
    facilityId: FacilityId;
    ridingLessonTypeId: RidingLessonTypeId;
    from: Moment;
    to: Moment;
    duration: number;
    lastRegistrationAt: Moment;
    ridingInstructorId: UserId | null;
    maxAmountOfParticipants: number | null;
  }
}

export namespace CreateRidingLessonSeriesAsManager {
  export interface CreateRidingLessonSeriesAsManagerCommand extends Command {
    facilityId: FacilityId;
    ridingLessonTypeId: RidingLessonTypeId;
    weekday: Weekday;
    from: Moment;
    to: Moment;
    duration: number;
    lastRegistrationAt: Moment;
    lastRidingLessonAt: Moment;
    ridingInstructorId: UserId | null;
    maxAmountOfParticipants: number | null;
  }
}

export namespace DeleteRidingLessonAsManager {
  export interface DeleteRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
  }
}

export namespace DeleteRidingLessonRegistrationAsManager {
  export interface DeleteRidingLessonRegistrationAsManagerCommand extends Command {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
  }
}

export namespace CreateRidingLessonConfigurationAsManager {
  export interface CreateRidingLessonConfigurationAsManagerCommand extends Command {
    isRidingLessonPlanVisible: boolean;
    calendarIntervalHeight: CalendarIntervalHeight;
    areNewUsersAllowedToRegisterForRidingLessons: boolean;
    idsOfHorsesAvailableForAssignment: HorseId[];
    areHorsesFilteredByRidingLessonType: boolean;
    isRidingInstructorShownOnPlan: boolean;
    isWaitingListAvailable: boolean;
    waitingListNotice: string | null;
  }
}

export namespace UpdateRidingLessonConfigurationAsManager {
  export interface UpdateRidingLessonConfigurationAsManagerCommand extends Command {
    isRidingLessonPlanVisible: boolean;
    calendarIntervalHeight: CalendarIntervalHeight;
    areNewUsersAllowedToRegisterForRidingLessons: boolean;
    idsOfHorsesAvailableForAssignment: HorseId[];
    areHorsesFilteredByRidingLessonType: boolean;
    isRidingInstructorShownOnPlan: boolean;
    isWaitingListAvailable: boolean;
    waitingListNotice: string | null;
  }
}

export namespace AssignHorseToRidingLessonAsManager {
  export interface AssignHorseToRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    horseId: HorseId;
  }
}

export namespace AssignHorseToRidingLessonRegistrationAsManager {
  export interface AssignHorseToRidingLessonRegistrationAsManagerCommand extends Command {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    horseId: HorseId;
  }
}

export namespace RemoveHorseFromRidingLessonAsManager {
  export interface RemoveHorseFromRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    horseId: HorseId;
  }
}

export namespace RemoveHorseFromRidingLessonRegistrationAsManager {
  export interface RemoveHorseFromRidingLessonRegistrationAsManagerCommand extends Command {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
  }
}

export namespace UpdateRidingInstructorOfRidingLessonAsManager {
  export interface UpdateRidingInstructorOfRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    ridingInstructorId: UserId | null;
  }
}

export namespace UpdateMaxAmountOfParticipantsOfRidingLessonAsManager {
  export interface UpdateMaxAmountOfParticipantsOfRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    maxAmountOfParticipants: number | null;
  }
}

export namespace UpdateTimeFrameOfRidingLessonAsManager {
  export interface UpdateTimeFrameOfRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    from: Moment;
    to: Moment;
    duration: number;
    lastRegistrationAt: Moment;
  }
}

export namespace UpdateNameOfAnotherPersonOfRidingLessonRegistrationAsManager {
  export interface UpdateNameOfAnotherPersonOfRidingLessonRegistrationAsManagerCommand extends Command {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    nameOfAnotherPerson: string;
  }
}

export namespace UpdateCommentOfRidingLessonRegistrationAsManager {
  export interface UpdateCommentOfRidingLessonRegistrationAsManagerCommand extends Command {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    comment: string | null;
  }
}

export namespace MoveRidingLessonRegistrationToDifferentRidingLessonAsManager {
  export interface MoveRidingLessonRegistrationToDifferentRidingLessonAsManagerCommand extends Command {
    ridingLessonRegistrationId: RidingLessonRegistrationId;
    ridingLessonId: RidingLessonId;
  }
}

export namespace UpdateFacilityAndRidingLessonTypeOfRidingLessonAsManager {
  export interface UpdateFacilityAndRidingLessonTypeOfRidingLessonAsManagerCommand extends Command {
    ridingLessonId: RidingLessonId;
    facilityId: FacilityId;
    ridingLessonTypeId: RidingLessonTypeId;
  }
}

export namespace ExtendRidingLessonSeriesAsManager {
  export interface ExtendRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    ridingLessonId: RidingLessonId;
    lastRidingLessonAt: Moment;
  }
}

export namespace ShortenRidingLessonSeriesAsManager {
  export interface ShortenRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    ridingLessonId: RidingLessonId;
    lastRidingLessonAt: Moment;
  }
}

export namespace UpdateRidingInstructorOfRidingLessonSeriesAsManager {
  export interface UpdateRidingInstructorOfRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    ridingLessonId: RidingLessonId;
    ridingInstructorId: UserId | null;
  }
}

export namespace UpdateMaxAmountOfParticipantsOfRidingLessonSeriesAsManager {
  export interface UpdateMaxAmountOfParticipantsOfRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    ridingLessonId: RidingLessonId;
    maxAmountOfParticipants: number | null;
  }
}

export namespace UpdateLastRegistrationAtOfRidingLessonSeriesAsManager {
  export interface UpdateLastRegistrationAtOfRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    ridingLessonId: RidingLessonId;
    lastRegistrationAt: Moment;
  }
}

export namespace UpdateWeekdayAndTimeFrameOfRidingLessonSeriesAsManager {
  export interface UpdateWeekdayAndTimeFrameOfRidingLessonSeriesAsManagerCommand extends Command {
    ridingLessonSeriesId: RidingLessonSeriesId;
    ridingLessonId: RidingLessonId;
    weekday: Weekday;
    timeFrame: TimeFrame;
  }
}

export namespace CreateLinkOfFilterForPlanAsManager {
  export interface CreateLinkOfFilterForPlanAsManagerCommand extends Command {
    dateTimeFrame: DateTimeFrame;
    filteredForUser: HorseId | null;
    filteredForHorse: UserId | null;
    areAllRegistrationsVisible: boolean;
  }
}

export namespace SendLinkOfFilterForPlanViaEmailAsManager {
  export interface SendLinkOfFilterForPlanViaEmailAsManagerCommand extends Command {
    linkOfFilterForRidingLessonPlanId: LinkOfFilterForRidingLessonPlanId;
    emailAddress: EmailAddress;
    message: string;
  }
}

export namespace UpdateAbilityOfUserToRegisterForRidingLessonsAsManager {
  export interface UpdateAbilityOfUserToRegisterForRidingLessonsAsManagerCommand extends Command {
    idOfTargetUser: UserId;
    isAbleToRegisterForRidingLessons: boolean;
  }
}

export namespace UpdateColorOfRidingLessonTypeAsManager {
  export interface UpdateColorOfRidingLessonTypeAsManagerCommand extends Command {
    ridingLessonTypeId: RidingLessonTypeId;
    color: RidingLessonColor;
  }
}

export namespace UpdateCalendarIntervalHeightOfRidingLessonPlanAsManager {
  export interface UpdateCalendarIntervalHeightOfRidingLessonPlanAsManagerCommand extends Command {
    calendarIntervalHeight: CalendarIntervalHeight;
  }
}
