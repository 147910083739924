import Vue from 'vue';
import VueRouter from 'vue-router';
import { Position, PositionResult } from 'vue-router/types/router';
import { appVersionGuard } from '@/application/app/app-version-guard';
import { accessGuard } from '@/application/authentication/access-guard';
import { routes } from './routes';

Vue.use(VueRouter);

export const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, _, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 70 },
      } as PositionResult;
    }

    return {
      x: 0,
      y: 0,
    } as Position;
  },
});

router.beforeEach(accessGuard);
router.afterEach(appVersionGuard);
