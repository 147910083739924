import { isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { FeatureVisibleForRiders, HorseId } from '@/types';
import { Link } from '@/components/design-system';

export function navigationItems(
  horseId: HorseId,
  isOwnHorse: boolean
): Link[] {
  const items: Link[] = [];

  items.push({
    title: 'Trainingstagebuch',
    to: { name: 'training-diary', params: { horseId } },
  });

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.FARM_SERVICES)) {
    items.push({
      title: 'Hofdienste und Hofartikel',
      to: { name: 'horse-bookings-farm-services', params: { horseId } },
    });
  }

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.PEN_EXEMPTIONS)) {
    items.push({
      title: 'Koppelausnahmen',
      to: { name: 'horse-pen-exemptions', params: { horseId } },
    });
  }

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.GERBLHOF_FEEDING)) {
    items.push({
      title: 'Fütterung',
      to: { name: 'gerblhof-feeding', params: { horseId } },
    });
  }

  if (isFeatureVisibleForRiders(FeatureVisibleForRiders.FEEDING)) {
    items.push({
      title: 'Fütterung',
      to: { name: 'feeding', params: { horseId } },
    });
  }

  items.push({
    title: 'Gesundheitsakte',
    to: { name: 'horse-medical-record', params: { horseId } },
  });

  if (isOwnHorse) {
    items.push({
      title: 'Reitbeteiligungen',
      to: { name: 'horse-shares', params: { horseId } },
    });
  }

  items.push({
    title: 'Details',
    to: { name: 'horse-details', params: { horseId } },
  });

  return items;
}
