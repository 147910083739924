import { render, staticRenderFns } from "./feeding-settings-form-control.vue?vue&type=template&id=8ed913d6&"
import script from "./feeding-settings-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./feeding-settings-form-control.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports